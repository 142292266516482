import { UploadFile } from 'antd';
import { computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { FileModel } from '@/entities/file';
import { Nullable } from '@/shared/types/values';

import { BaseMessageServer } from '../../types';

type JsonParams = {
  server: BaseMessageServer;
  rootStore: IRootStore;
};

export class BaseMessageModel {
  readonly id: string;
  readonly text: Nullable<string>;
  readonly files: Nullable<Array<UploadFile>>;
  readonly createdDate: Date;

  readonly userId: number;

  private readonly _rootStore: IRootStore;

  constructor(params: {
    id: string;
    text: Nullable<string>;
    files: Nullable<Array<UploadFile>>;
    createdDate: Date;
    userId: number;
    rootStore: IRootStore;
  }) {
    this.id = params.id;
    this.text = params.text;
    this.files = params.files;
    this.userId = params.userId;
    this.createdDate = params.createdDate;

    this._rootStore = params.rootStore;

    makeObservable(this, {
      ownMessage: computed,
    });
  }

  get ownMessage(): boolean {
    return this._rootStore.userStore.userModel?.id === this.userId;
  }

  static fromJsonParams({ server, rootStore }: JsonParams): BaseMessageModel {
    return new BaseMessageModel({
      id: server.id,
      text: server.text,
      files: server.attachments?.length ? FileModel.fileListFromJson(server.attachments) : null,
      createdDate: new Date(server.created_at),
      userId: server.user_id,
      rootStore,
    });
  }
}
