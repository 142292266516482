import { FundFilled, MessageFilled } from '@ant-design/icons';
import { ItemType, MenuItemGroupType } from 'antd/es/menu/hooks/useItems';
import * as React from 'react';

import { roleRouteKeyMap } from '@/app/routes/config';
import { UserType } from '@/entities/user';
import { RouteKey, routerUrls } from '@/shared/config/routes';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';
import { TransComp } from '@/shared/ui';
import {
  ContactsFilledIcon,
  FileTextFilledIcon,
  LanguageFilledIcon,
  LeaveFilledIcon,
  ProfileIcon,
  TeamFilledIcon,
} from '@/shared/ui/icons';

import { LanguageSelectorCallback } from './types';
import { LanguageSelector } from './ui/LanguageSelector';
import { NotificationsBell } from './ui/NotificationsBell';

const T_OPTIONS = { ns: 'menu' } as const;

const COLOR_ICON_SELECTED = '#ffffff';
const COLOR_ICON_DEFAULT = '#000000';

const getIconColor = (selectedKey: string, key: string) =>
  selectedKey === key ? COLOR_ICON_SELECTED : COLOR_ICON_DEFAULT;

export const menuUrlsMap: Record<string, { key: RouteKey | 'logout'; url: string; label: TranslationString }> = {
  clients: {
    key: 'clients',
    url: routerUrls.clients.create(),
    label: (t) => t('clients', T_OPTIONS),
  },
  agents: {
    key: 'agents',
    url: routerUrls.agents.create(),
    label: (t) => t('agents', T_OPTIONS),
  },
  trades: {
    key: 'trades',
    url: routerUrls.trades.create(),
    label: (t) => t('trades', T_OPTIONS),
  },
  documents: {
    key: 'documents',
    url: routerUrls.documents.create(),
    label: (t) => t('documents', T_OPTIONS),
  },
  logout: {
    key: 'logout',
    url: routerUrls.login.create(),
    label: (t) => t('logout', T_OPTIONS),
  },
  profile: {
    key: 'profile',
    url: routerUrls.profile.create(),
    label: (t) => t('profile', T_OPTIONS),
  },
  notifications: {
    key: 'notifications',
    url: routerUrls.notifications.create(),
    label: (t) => t('notifications', T_OPTIONS),
  },
  chats: {
    key: 'chats',
    url: routerUrls.chats.create(),
    label: (t) => t('chats', T_OPTIONS),
  },
  bids: {
    key: 'bids',
    url: routerUrls.bids.create(),
    label: (t) => t('bids', { ns: 'menu' }),
  },
};

type Params = {
  userType: Nullable<UserType>;
  selectedKey: string;
  isMenuOpened: boolean;
  languageSelectorCallbackRef: React.MutableRefObject<LanguageSelectorCallback | null>;
  handleMenuItemClick: (params: { key: string }) => void;
};

export const getMenuItems = ({
  userType,
  selectedKey,
  isMenuOpened,
  languageSelectorCallbackRef,
  handleMenuItemClick,
}: Params): Array<ItemType> => {
  if (!userType) {
    return [];
  }

  const routeKeys = roleRouteKeyMap[userType];

  const baseItems = [
    {
      key: menuUrlsMap.profile.key,
      label: <TransComp>{menuUrlsMap.profile.label}</TransComp>,
      icon: <ProfileIcon color={getIconColor(selectedKey, menuUrlsMap.profile.key)} />,
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.profile.key }),
    },
    {
      key: menuUrlsMap.notifications.key,
      label: <TransComp>{menuUrlsMap.notifications.label}</TransComp>,
      icon: <NotificationsBell color={getIconColor(selectedKey, menuUrlsMap.notifications.key)} />,
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.notifications.key }),
    },
    {
      key: menuUrlsMap.clients.key,
      label: <TransComp>{menuUrlsMap.clients.label}</TransComp>,
      icon: <TeamFilledIcon color={getIconColor(selectedKey, menuUrlsMap.clients.key)} />,
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.clients.key }),
    },
    {
      key: menuUrlsMap.agents.key,
      label: <TransComp>{menuUrlsMap.agents.label}</TransComp>,
      icon: <ContactsFilledIcon color={getIconColor(selectedKey, menuUrlsMap.agents.key)} />,
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.agents.key }),
    },
    {
      key: menuUrlsMap.trades.key,
      label: <TransComp>{menuUrlsMap.trades.label}</TransComp>,
      icon: <FileTextFilledIcon color={getIconColor(selectedKey, menuUrlsMap.trades.key)} />,
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.trades.key }),
    },
    {
      key: menuUrlsMap.documents.key,
      label: <TransComp>{menuUrlsMap.documents.label}</TransComp>,
      icon: <FileTextFilledIcon color={getIconColor(selectedKey, menuUrlsMap.documents.key)} />,
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.documents.key }),
    },
    {
      key: menuUrlsMap.chats.key,
      label: <TransComp>{menuUrlsMap.chats.label}</TransComp>,
      icon: <MessageFilled color={getIconColor(selectedKey, menuUrlsMap.chats.key)} />,
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.chats.key }),
    },
    {
      key: menuUrlsMap.bids.key,
      label: <TransComp>{menuUrlsMap.bids.label}</TransComp>,
      icon: <FundFilled color={getIconColor(selectedKey, menuUrlsMap.bids.key)} />,
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.bids.key }),
    },
  ];

  const generalMenuGroup: MenuItemGroupType = {
    type: 'group',
    children: [
      {
        key: 'language',
        label: <LanguageSelector isMenuOpened={isMenuOpened} callbackRef={languageSelectorCallbackRef} />,
        icon: <LanguageFilledIcon />,
        onClick: () => languageSelectorCallbackRef.current?.toggleLanguageSelector(),
      },
      {
        key: menuUrlsMap.logout.key,
        label: <TransComp>{menuUrlsMap.logout.label}</TransComp>,
        icon: <LeaveFilledIcon color={COLOR_ICON_DEFAULT} size={14} />,
        onClick: () => handleMenuItemClick({ key: menuUrlsMap.logout.key }),
      },
    ],
  };

  return [...baseItems.filter(({ key }) => key !== 'logout' && routeKeys.includes(key)), generalMenuGroup];
};
