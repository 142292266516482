import { DatePicker, DatePickerProps } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { renderTranslationString } from '@/shared/utils/renderTranslation';

import { CustomFormItem } from '../CustomFormItem';

type FormDatePickerProps = {
  className?: string;
  model: DatePickerModel;
  disabled?: boolean;
  picker?: 'week' | 'date' | 'month' | 'quarter' | 'year';
  disabledDate?: DatePickerProps['disabledDate'];
};

const FormDatePicker: React.FC<FormDatePickerProps> = ({ className, model, disabled, picker, disabledDate }) => {
  const { t } = useTypedTranslation();
  const placeholder = renderTranslationString(model.placeholder, t);

  return (
    <CustomFormItem className={className} ref={model.ref} model={model}>
      <DatePicker
        style={{ width: '100%' }}
        {...model.props}
        picker={picker}
        placeholder={placeholder}
        disabledDate={disabledDate ?? model.props.disabledDate}
        disabled={model.props.disabled || disabled}
      />
    </CustomFormItem>
  );
};

export default observer(FormDatePicker);
