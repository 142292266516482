import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { throttle } from '@kts-front/utils';
import { action, computed, makeObservable, runInAction } from 'mobx';

import { IRootStore } from '@/app/store';
import { MessageType } from '@/entities/message';
import { CreatedMessageWsBody, DeletedMessageWsBody, EditedMessageWsBody, ReadMessagesWsBody } from '@/entities/ws';
import { apiStore, apiUrls } from '@/shared/api';
import { ListModel, LoadingStageModel, QueryParamsModel, ValueModel } from '@/shared/model';
import { ListDirection } from '@/shared/types/meta';
import { formatDate } from '@/shared/utils/formatDate';

import { ChatsQueryParams, IThread, MessageListResponse } from '../../types';
import { BaseChatListModelParams } from '../BaseChatListModel';
import { MessageModel } from '../MessageModel';

import { userColors } from './config';
import { GroupedMessagesByDate } from './types';

type MessageListModelParams = BaseChatListModelParams & {
  thread: IThread;
};

const T_OPTIONS = { ns: 'chats' } as const;

export class MessageListModel {
  readonly list = new ListModel<MessageModel>({ limit: 40 });

  readonly loadingUpStage = new LoadingStageModel();
  readonly loadingDownStage = new LoadingStageModel();
  readonly deletingStage = new LoadingStageModel();
  readonly readingStage = new LoadingStageModel();
  readonly listRef: ValueModel<HTMLDivElement | null> = new ValueModel<HTMLDivElement | null>(null);
  private readonly _scrolledToBottomModel: ValueModel<boolean> = new ValueModel<boolean>(true);
  private readonly _scrollTop: ValueModel<number> = new ValueModel<number>(0);

  private readonly _searchModel: ValueModel<string>;

  private readonly _queryParams: QueryParamsModel<ChatsQueryParams>;

  private readonly _rootStore: IRootStore;
  private readonly _thread: IThread;

  private readonly _messageListRequest: ApiRequest<MessageListResponse>;
  private readonly _deleteMessageRequest = apiStore.createRequest({
    method: 'DELETE',
  });

  private readonly _colorsMap = new Map<number, string>();

  constructor({ queryParams, rootStore, thread }: MessageListModelParams) {
    this._queryParams = queryParams;
    this._rootStore = rootStore;
    this._thread = thread;

    this._searchModel = new ValueModel(queryParams.params.message_list_search);

    this._messageListRequest = apiStore.createRequest({
      url: apiUrls.chats.messageList(thread.id),
    });

    makeObservable(this, {
      isEmptyList: computed,
      messagesGroupedByDate: computed,
      initiallyLoaded: computed,
      scrolledToBottom: computed,

      onChangeSearch: action.bound,
      onSearch: action.bound,
      applyParams: action.bound,
      resetParams: action.bound,
      initialLoad: action.bound,
      onScrollUp: action.bound,
      deleteOwnMessage: action.bound,

      handleCreatedMessageWsEvent: action.bound,
      handleReadMessagesWsEvent: action.bound,
      handleEditedMessageWsEvent: action.bound,
      handleDeletedMessageWsEvent: action.bound,
    });
  }

  get initiallyLoaded(): boolean {
    return !this.list.initial;
  }

  get scrolledToBottom(): boolean {
    return this._scrolledToBottomModel.value;
  }

  get isEmptyList(): boolean {
    return (
      this.list.items.length === 0 &&
      !this.list.loadingStage.isLoading &&
      !this.loadingDownStage.isLoading &&
      !this.loadingUpStage.isLoading
    );
  }

  get messagesGroupedByDate(): Array<GroupedMessagesByDate> {
    const grouped = this.list.items.reduce<Map<string, GroupedMessagesByDate>>((acc, message) => {
      const formattedDate = formatDate(message.createdDate);
      const color = this._getUserColor(message.userInfo.id);

      const group = acc.get(formattedDate);

      if (group) {
        const lastMessage = group.messages.at(-1);

        if (lastMessage && lastMessage.user.id === message.userInfo.id) {
          lastMessage.messages.push(message);
        } else {
          group.messages.push({
            id: `${message.userInfo.id}_${message.createdDate.getTime()}`,
            color,
            user: message.userInfo,
            messages: [message],
          });
        }
      } else {
        acc.set(formattedDate, {
          date: message.createdDate,
          messages: [
            {
              id: `${message.userInfo.id}_${message.createdDate.getTime()}`,
              color,
              user: message.userInfo,
              messages: [message],
            },
          ],
        });
      }

      return acc;
    }, new Map());

    return Array.from(grouped.values());
  }

  get searchValue(): string {
    return this._searchModel.value;
  }

  onChangeSearch(value: string): void {
    this._searchModel.change(value);

    if (value === '') {
      this.applyParams();
    }
  }

  onSearch(value: string) {
    this._searchModel.change(value);
    this.applyParams();
  }

  handleCreatedMessageWsEvent(body: CreatedMessageWsBody): void {
    /*
     * Если не загружали ни разу список, то не добавляем сообщение
     * */
    if (!this.initiallyLoaded) {
      return;
    }

    const message = MessageModel.fromJson({
      server: body,
      rootStore: this._rootStore,
      targetThreadId: this._thread.id,
    });

    this.list.addEntity({
      entity: message,
      key: body.id,
      start: false,
    });

    /*
     * Листаем вниз только если список проскролен вниз(он не читает историю) или если он сам же и написал это сообщение
     * */
    if (this.scrolledToBottom || message.ownMessage) {
      this.scrollToBottom();
    }
  }

  handleReadMessagesWsEvent(body: ReadMessagesWsBody): void {
    body.messages.forEach((server) => {
      const message = this.list.getEntity(server.id);

      if (message) {
        message.readMessage(server.read_at);
      }
    });
  }

  handleEditedMessageWsEvent(body: EditedMessageWsBody): void {
    if (this.list.entities.has(body.id)) {
      this.list.entities.set(
        body.id,
        MessageModel.fromJson({
          server: body,
          rootStore: this._rootStore,
          targetThreadId: this._thread.id,
        }),
      );
    }
  }

  handleDeletedMessageWsEvent(body: DeletedMessageWsBody): void {
    this.list.removeEntity(body.message_id);
  }

  async deleteOwnMessage(message: MessageModel): Promise<BaseResponse> {
    if (this.deletingStage.isLoading) {
      return { isError: true };
    }

    this.deletingStage.loading();

    const response = await this._deleteMessageRequest.call({
      url: apiUrls.chats.message({
        threadId: message.threadInfo.id,
        messageId: message.id,
      }),
    });

    if (response.isError) {
      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.deleteMessageError', T_OPTIONS),
      });

      this.deletingStage.error();

      return { isError: true };
    }

    this._rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.deleteMessageSuccess', T_OPTIONS),
    });

    this.deletingStage.success();

    return { isError: false };
  }

  async init(): Promise<BaseResponse> {
    if (this.initiallyLoaded) {
      /*
       * Обрабатываем кейс повторного открытия чата, если он был проскролен доконца, нужно это и сохранить. Если нет - сохраняем положение
       * */
      if (this.scrolledToBottom) {
        this.scrollToBottom(false);
      } else {
        this._scrollToPrevPosition();
      }

      return {
        isError: false,
      };
    }

    return this.initialLoad();
  }

  async initialLoad(): Promise<BaseResponse> {
    if (!this._thread.lastMessage) {
      this.list.changeInitial(false);

      return {
        isError: false,
      };
    }

    const listRef = this.listRef.value;

    if (this.list.loadingStage.isLoading || !listRef) {
      return { isError: true };
    }

    this.list.loadingStage.loading();

    const response = await this._fetchItems({
      direction: ListDirection.backward,
      fromId: this._thread.lastMessage.id,
      inclusive: true,
    });

    if (response.isError) {
      this.list.loadingStage.error();
    } else {
      this.scrollToBottom(false);

      this.list.changeInitial(false);
      this.list.loadingStage.success();
    }

    return response;
  }

  async onScrollUp(): Promise<BaseResponse> {
    const container = this.listRef.value;

    if (this.loadingUpStage.isLoading || !container) {
      return { isError: true };
    }

    this.loadingUpStage.loading();

    const prevScrollHeight = container.scrollHeight;
    const response = await this._fetchItems({ direction: ListDirection.backward });

    const newScrollHeight = container.scrollHeight;

    this._scrollToPosition({
      top: newScrollHeight - prevScrollHeight,
      smooth: false,
    });

    if (response.isError) {
      this.loadingUpStage.error();
    } else {
      this.loadingUpStage.success();
    }

    return response;
  }

  applyParams(): void {
    this._queryParams.setParams({
      message_list_search: this._searchModel.value,
    });
  }

  resetParams(): void {
    this._searchModel.change('');
    this.applyParams();
  }

  handleScroll = throttle({
    func: () => {
      const container = this.listRef.value;

      if (!container) {
        return;
      }

      const scrollTop = container.scrollTop; // Текущая позиция прокрутки
      const scrollHeight = container.scrollHeight; // Вся высота элемента
      const clientHeight = container.clientHeight; // Видимая высота элемента
      const errorRate = 50;

      this._scrollTop.change(scrollTop);
      this._scrolledToBottomModel.change(scrollTop + clientHeight + errorRate >= scrollHeight);
    },
    timeout: 300,
  });

  readonly scrollToBottom = (smooth: boolean = true) => {
    const container = this.listRef.value;

    if (!container) {
      return;
    }

    this._scrollToPosition({
      top: container.scrollHeight,
      smooth,
    });
  };

  /**
   * Получение списка сообщений
   * @param fromIdFromParams - id сообщения, от которого получаем список
   * @param direction - направление запроса, backward - наверх, forward - вниз
   * @param inclusive - включать ли в результат запроса сообщение с id = fromIdFromParams
   * @returns {Promise<{isError: true} | {isError: false}>}
   * @private
   */
  private readonly _fetchItems = async ({
    fromId: fromIdFromParams,
    direction,
    inclusive = false,
  }: {
    fromId?: string;
    direction: ListDirection;
    inclusive?: boolean;
  }): Promise<BaseResponse> => {
    const start = direction === ListDirection.backward;
    const fromId = fromIdFromParams || (start ? this.list.keys.at(0) : this.list.keys.at(-1));
    const initial = fromIdFromParams !== undefined;

    const response = await this._messageListRequest.call({
      params: {
        limit: this.list.limit,
        from_id: fromId,
        direction,
        inclusive,
      },
    });

    if (response.isError) {
      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.loadMessageListError', { ns: 'chats' }),
      });

      return { isError: true };
    }

    runInAction(() => {
      this.list.fillByRawData(
        response.data.results.reverse(),
        (server) => ({
          key: server.id,
          entity: MessageModel.fromJson({
            server,
            rootStore: this._rootStore,
            targetThreadId: this._thread.id,
          }),
        }),
        initial,
        start,
      );

      this.list.total.change(response.data.total);
    });

    return { isError: false };
  };

  private readonly _getUserColor = (userId: number): string => {
    const colorFromMap = this._colorsMap.get(userId);

    if (colorFromMap) {
      return colorFromMap;
    }

    const color = userColors[this._colorsMap.size % userColors.length];
    this._colorsMap.set(userId, color);

    return color;
  };

  /**
   * Скролит до позиции, при которой уходили из чата
   * @private
   */
  private readonly _scrollToPrevPosition = () => {
    this._scrollToPosition({
      top: this._scrollTop.value,
      smooth: false,
    });
  };

  /**
   * Скролит до позиции
   * @private
   */
  private readonly _scrollToPosition = ({ top, smooth = true }: { top: number; smooth?: boolean }) => {
    const container = this.listRef.value;

    if (!container) {
      return;
    }

    setTimeout(() => {
      container.scrollTo({
        top,
        behavior: smooth ? 'smooth' : undefined,
      });
    }, 0);
  };
}
