import { DictionaryServer } from '@/entities/dictionary';
import { DocumentServer, DocumentWithStatusServer, FileServer } from '@/entities/file';
import { UserType } from '@/entities/user';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer, DestinationCountryDocsServer, OptionFieldsAction, ShipmentType } from './baseStage';

export enum ManagerShipmentStep {
  uploadSpec = 'upload_shipment_specification',
  specVerifying = 'shipment_specification_verifying',
  shipmentType = 'select_shipment_type',
  cargoShipment = 'cargo_shipment_confirmation',
  // StageType.transit fields
  transitCountry = 'select_transit_country',
  transitCountryAwb = 'upload_awb_for_transit_country',
  transitCountryAwbVerifying = 'shipment_transit_awb_verifying',
  // StageType.direct fields
  destinationCountryDocs = 'upload_direct_shipment_documents',
  destinationCountryAwb = 'upload_awb_for_direct_country',
  transfer = 'transfer',
}

export enum ManagerSpecificationVerifyingAction {
  approveSpecification = 'approve_shipment_specification_verifying',
  rejectSpecification = 'reject_shipment_specification_verifying',
}

export enum ManagerAwbVerifyingAction {
  approveAwb = 'approve_awb_verifying',
  rejectAwb = 'reject_awb_verifying',
}

export enum AwbTransitAction {
  uploadFile = 'upload_awb_for_transit_country',
  flightNumber = 'set_flight_number_for_transit_country',
}

export enum AwbDirectAction {
  uploadFile = 'upload_awb_for_direct_country',
  flightNumber = 'set_flight_number_for_direct_country',
}

export type ManagerShipmentAction =
  | Exclude<
      ManagerShipmentStep,
      | ManagerShipmentStep.specVerifying
      | ManagerShipmentStep.transitCountryAwbVerifying
      | ManagerShipmentStep.destinationCountryAwb
      | ManagerShipmentStep.transitCountryAwb
    >
  | ManagerSpecificationVerifyingAction
  | ManagerAwbVerifyingAction
  | OptionFieldsAction.deleteSupplierSpec
  | AwbTransitAction
  | AwbDirectAction;

export enum SupplierShipmentStep {
  uploadSpec = 'upload_shipment_specification',
  specVerifying = 'shipment_specification_verifying',
  transitCountry = 'select_transit_country',
  transitCountryAwb = 'upload_awb_for_transit_country',
  transitCountryAwbVerifying = 'shipment_transit_awb_verifying',
  cargoShipment = 'cargo_shipment_confirmation',
  transfer = 'transfer',
}

export type SupplierShipmentAction = Extract<
  SupplierShipmentStep,
  SupplierShipmentStep.uploadSpec | SupplierShipmentStep.transitCountryAwb
>;

export enum CustomerShipmentStep {
  shipmentType = 'select_shipment_type',
  destinationCountryDocs = 'upload_direct_shipment_documents',
  destinationCountryAwb = 'upload_awb_for_direct_country',
  transfer = 'transfer',
}

type MapUserTypeToShipmentStageData = {
  [UserType.admin]: {
    [ManagerShipmentStep.uploadSpec]: Nullable<DocumentWithStatusServer>;
    [ManagerShipmentStep.specVerifying]: null;
    [ManagerShipmentStep.shipmentType]: Nullable<{ type: ShipmentType }>;
    [ManagerShipmentStep.cargoShipment]: Nullable<{ shipped: boolean }>;
    [ManagerShipmentStep.transitCountry]: Nullable<DictionaryServer>;
    [ManagerShipmentStep.transitCountryAwb]: Nullable<DocumentWithStatusServer>;
    [ManagerShipmentStep.transitCountryAwbVerifying]: null;
    [ManagerShipmentStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer>;
    [ManagerShipmentStep.destinationCountryAwb]: Nullable<DocumentServer>;
  };
  [UserType.manager]: {
    [ManagerShipmentStep.uploadSpec]: Nullable<DocumentWithStatusServer>;
    [ManagerShipmentStep.specVerifying]: null;
    [ManagerShipmentStep.shipmentType]: Nullable<{ type: ShipmentType }>;
    [ManagerShipmentStep.cargoShipment]: Nullable<{ shipped: boolean }>;
    [ManagerShipmentStep.transitCountry]: Nullable<DictionaryServer>;
    [ManagerShipmentStep.transitCountryAwb]: Nullable<DocumentWithStatusServer>;
    [ManagerShipmentStep.transitCountryAwbVerifying]: null;
    [ManagerShipmentStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer>;
    [ManagerShipmentStep.destinationCountryAwb]: Nullable<DocumentServer>;
  };
  [UserType.supplier]: {
    [SupplierShipmentStep.uploadSpec]: Nullable<DocumentWithStatusServer>;
    [SupplierShipmentStep.transitCountry]: null;
    [SupplierShipmentStep.cargoShipment]: Nullable<{ shipped: boolean }>;
    [SupplierShipmentStep.transitCountryAwb]: Nullable<DocumentWithStatusServer>;
    [SupplierShipmentStep.transitCountryAwbVerifying]: null;
  };
  [UserType.customer]: {
    [CustomerShipmentStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer<FileServer>>;
    [CustomerShipmentStep.destinationCountryAwb]: Nullable<DocumentServer>;
  };
  [UserType.agent]: null;
};

type BaseShipmentStageDataServer<U extends UserType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToShipmentStageData[U]
>;

export type ManagerShipmentStageDataServer = BaseShipmentStageDataServer<
  UserType.manager | UserType.admin,
  ManagerShipmentStep
>;
export type SupplierShipmentStageDataServer = BaseShipmentStageDataServer<UserType.supplier, SupplierShipmentStep>;
export type CustomerShipmentStageDataServer = BaseShipmentStageDataServer<UserType.customer, CustomerShipmentStep>;
export type AgentShipmentStageDataServer = BaseShipmentStageDataServer<UserType.agent, null>;

export type ShipmentStageDataServer =
  | ManagerShipmentStageDataServer
  | SupplierShipmentStageDataServer
  | CustomerShipmentStageDataServer;

export type ShipmentAction = ManagerShipmentAction | SupplierShipmentAction;
