import { NotificationCategory, NotificationService } from '@/entities/notification';

export const notifications = {
  en: {
    category: {
      [NotificationCategory.statusChange]: 'Trade status changes',
      [NotificationCategory.docUpload]: 'Document upload updates',
      [NotificationCategory.docVerification]: 'Confirmation of document uploading from managers',
    },
    service: {
      telegram: 'To telegram',
      email: 'To email',
    },
    list: {
      actions: {
        readAll: 'Mark all as read',
        typeFilter: 'Filter by type',
        settings: 'Notification settings',
        rangePickerPlaceholder: 'Select date',
        clearList: 'Clear list',
      },
      emptyList: 'While the notification list is empty',
      emptyFilteredList: 'No notifications found matching these filters',
      messages: {
        fetchError: 'Failed to fetch notifications',
        readAllSuccess: 'All notifications marked as read',
        readAllError: 'Failed to mark all notifications as read',
        readOneSuccess: 'Notification marked as read',
        readOneError: 'Failed to mark notification as read',
      },
    },
    settings: {
      backButton: 'Back',
      title: 'Notification settings',
      accounts: {
        [NotificationService.email]: 'My email',
        [NotificationService.telegram]: 'My Telegram',
      },
      sectionHeadings: {
        whereToSend: 'Where to send',
        whatToSend: 'What to send',
      },
      telegramModal: {
        title: 'Setting up notifications in Telegram',
        stepTitle: 'Step {{step}}',
        goToBotDescription: 'Go to the bot in Telegram',
        startBotDescription: 'In the bot, click the /start button',
        waitBotDescription: 'Waiting for consent...',
        goToBotButton: 'Go to the bot',
        successButton: 'Ready',
        alert: {
          success: 'Bot connection was successful!',
          error: 'Try later',
        },
      },
      unsubscribeModal: {
        title: 'Confirm to turn off notifications',
        description: 'Are you sure you want to turn off all notifications? You may miss important updates',
        okText: 'Turn off all',
      },
      messages: {
        fetchError: 'Failed to fetch notification settings',
        tgBotLinkFetchError: 'Failed to get link to bot in Telegram',
        changeError: 'Failed to change notification settings',
        changeSuccess: 'Notification settings changed successfully',
      },
    },
  },
  ru: {
    category: {
      [NotificationCategory.statusChange]: 'Изменения статуса заказов',
      [NotificationCategory.docUpload]: 'Обновления по загрузке документов',
      [NotificationCategory.docVerification]: 'Подтверждения по загрузке документов от менеджеров',
    },
    service: {
      telegram: 'В телеграм',
      email: 'На почту',
    },
    list: {
      actions: {
        readAll: 'Отметить все как прочитанные',
        typeFilter: 'Фильтровать по типу',
        settings: 'Настройки уведомлений',
        rangePickerPlaceholder: 'Выберите дату',
        clearList: 'Очистить список',
      },
      emptyList: 'Пока список уведомлений пуст',
      emptyFilteredList: 'По данным фильтрам уведомления не найдены',
      messages: {
        fetchError: 'Ошибка получения уведомлений',
        readAllSuccess: 'Все уведомления отмечены как прочитанные',
        readAllError: 'Не удалось отметить все уведомления как прочитанные',
        readOneSuccess: 'Уведомление отмечено как прочитанное',
        readOneError: 'Не удалось отметить уведомление как прочитанное',
      },
    },
    settings: {
      backButton: 'Назад',
      title: 'Настройки уведомлений',
      accounts: {
        [NotificationService.email]: 'Мой почтовый ящик',
        [NotificationService.telegram]: 'Мой Телеграм',
      },
      sectionHeadings: {
        whereToSend: 'Куда присылать',
        whatToSend: 'Что присылать',
      },
      telegramModal: {
        title: 'Настройка уведомлений в Telegram',
        stepTitle: 'Шаг {{step}}',
        goToBotDescription: 'Перейдите в бота в Telegram',
        startBotDescription: 'В боте нажмите кнопку /start',
        waitBotDescription: 'Ожидание согласия...',
        goToBotButton: 'Перейти в бота',
        successButton: 'Готово',
        alert: {
          success: 'Подключение бота прошло успешно!',
          error: 'Попробуйте позже',
        },
      },
      unsubscribeModal: {
        title: 'Подтвердите отключение уведомлений',
        description: 'Вы уверены, что хотите отключить все уведомления? Вы можете пропустить важные обновления',
        okText: 'Отключить все',
      },
      messages: {
        fetchError: 'Не удалось получить настройки уведомлений',
        tgBotLinkFetchError: 'Не удалось получить ссылку на бота в Telegram',
        changeError: 'Не удалось изменить настройки уведомлений',
        changeSuccess: 'Настройки уведомлений изменены',
      },
    },
  },
  zh: {
    category: {
      [NotificationCategory.statusChange]: '订单状态变更',
      [NotificationCategory.docUpload]: '文件上传更新',
      [NotificationCategory.docVerification]: '经理确认文件上传',
    },
    service: {
      telegram: '电报',
      email: '电邮',
    },
    list: {
      actions: {
        readAll: '全部标记为已读',
        typeFilter: '按类别过滤',
        settings: '通知设置',
        rangePickerPlaceholder: '选择日期',
        clearList: '清除列表',
      },
      emptyList: '通知列表为空',
      emptyFilteredList: '根据这些过滤未找到任何通知',
      messages: {
        fetchError: '通知接收错误',
        readAllSuccess: '所有通知均标记为已读',
        readAllError: '无法将所有通知标记为已读',
        readOneSuccess: '通知标记为已读',
        readOneError: '无法将通知标记为已读',
      },
    },
    settings: {
      backButton: '退回',
      title: '通知设置',
      accounts: {
        // [NotificationService.email]: 'Мой почтовый ящик',
        // [NotificationService.telegram]: 'Мой Телеграм',
      },
      sectionHeadings: {
        whereToSend: '发送到哪里',
        whatToSend: '发送什么',
      },
      telegramModal: {
        // title: 'Настройка уведомлений в Telegram',
        // stepTitle: 'Шаг {{step}}',
        // goToBotDescription: 'Перейдите в бота в Telegram',
        // startBotDescription: 'В боте нажмите кнопку /start',
        // waitBotDescription: 'Ожидание согласия...',
        // goToBotButton: 'Перейти в бота',
        // successButton: 'Готово',
        // alert: {
        //   success: 'Подключение бота прошло успешно!',
        //   error: 'Попробуйте позже',
        // },
      },
      unsubscribeModal: {
        title: '确认关闭通知',
        description: '您确定要关闭所有通知吗？您可能会错过重要更新',
        okText: '全部禁用',
      },
      messages: {
        fetchError: '获取通知设置失败',
        // tgBotLinkFetchError: 'Не удалось получить ссылку на бота в Telegram',
        changeError: '更改通知设置失败',
        changeSuccess: '通知设置已更改',
      },
    },
  },
};
