import { BaseResponse } from '@kts-front/types';

import { IRootStore } from '@/app/store';
import { Incoterms } from '@/entities/stage';
import { ListOffsetModel } from '@/shared/model';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';
import { ColumnsData } from '@/shared/ui/Table';

import { BaseBidListFilters, BidModel } from '../model';

import { BidDifferenceType, BidFixingType, BidStatus, BidType } from './server';

export type BidCompany = {
  id: number;
  name: string;
  countryId: number;
};

/**
 * @field deliveryBasis - базис поставки (Incoterms)
 * @field diffCurrencyId - ID валюты
 * @field diffType - Тип дисконта/премии
 * @field diffValue - Значение дисконта/премии
 * @field company - Компания
 */
export type BidClient = {
  userId: number;
  deliveryBasis: Nullable<Incoterms>;
  diffCurrencyId: Nullable<number>;
  diffType: Nullable<BidDifferenceType>;
  diffValue: Nullable<number>;
  company: Nullable<BidCompany>;
};

export interface IBidReply {
  id: number;
  status: BidStatus;
  deliveryBasis: Incoterms;
  diffType: BidDifferenceType;
  diffValue: number;
  diffCurrencyId: number;
  company: Nullable<BidCompany>;
}

export type WeightWithUnit = {
  gram: string;
  ounce: string;
};

/**
 * @field status - Статус сделки
 * @field type - Тип сделки
 * @field productId - ID продукта металла
 * @field shapeId - ID товарной формы
 * @field fixingType - Тип фиксации
 * @field fixingDate - Дата фиксации
 * @field fixingPrice - Цена фиксации
 * @field currencyId - ID валюты
 * @field weight - Вес
 * @field weightUnitId - ID единицы веса
 * @field ligatureWeight - Вес лигатуры
 * @field history - История изменений
 * @field replyCount - Количество откликов
 * @field author - Автор заявки
 * @field buyer - Покупатель
 * @field seller - Продавец
 * @field manager - Менеджер
 */
export interface IBaseBid {
  id: number;
  status: BidStatus;
  type: BidType;
  tradeId: Nullable<number>;
  productId: number;
  shapeId: number;
  fixingType: BidFixingType;
  fixingDate: Nullable<Date>;
  fixingPrice: Nullable<number>;
  currencyId: number;
  weight: number;
  ligatureWeight: number;
  weightUnitId: number;
  history: {
    weight: Nullable<number>;
    ligatureWeight: Nullable<number>;
  };
  replyCount: Nullable<number>;
  buyer: Nullable<BidClient>;
  seller: Nullable<BidClient>;
  manager: Nullable<BidClient>;
}

export enum TableType {
  buy = 'buy',
  sell = 'sell',
  reply = 'reply',
  my = 'my',
}
export type BidModelParams = IBaseBid & { rootStore: IRootStore };

export enum BidDifference {
  premium = 'premium',
  discount = 'discount',
}

export type BidInfo = {
  metal: string;
  shape: string;
  status: TranslationString;
  type: TranslationString;
  fixingType: TranslationString;
  fixingDate: Nullable<string>;
  currency: string;
  weightWithUnit: string;
  ligatureWeightWithUnit: string;
  fixingPrice: Nullable<string>;
  londonPrice: Nullable<string>;
  sellerName: Nullable<string>;
  buyerName: Nullable<string>;
  buyerCountry: Nullable<string>;
  authorDeliveryBasis: Nullable<string>;
  authorDifference: Nullable<string>;
  managerDeliveryBasis: Nullable<string>;
  managerDifference: Nullable<string>;
  respondentDeliveryBasis: Nullable<string>;
  respondentDifference: Nullable<string>;
  replyCount: Nullable<number>;
};

export type BidInfoKeys = keyof Omit<BidInfo, 'replyCount'>;

export type BidPrices = {
  gramPrice: string;
  ouncePrice: string;
  totalOuncePrice: string;
  totalOuncePriceWithDiff: string;
};

export interface IBaseBidListStore<M, F extends BaseBidListFilters<M>, C> {
  list: ListOffsetModel<M, number>;
  filters: F;
  columns: ColumnsData<C>;
  rows: C[];
  isInitialLoading: boolean;
  isEmptyList: boolean;
  isEmptyFilteredList: boolean;
  changePage: (page: number) => void;
  fetchItems: (params: { reset?: boolean }) => Promise<BaseResponse>;
  refetch: () => void;
  refetchByLimit: (limit: number) => void;
}

export interface IBidTableData<Model extends BidModel> {
  model: Model;
  status: string;
  type: BidType;
  metal: Nullable<string>;
  shape: Nullable<string>;
  fixingType: BidFixingType;
  fixingDate: Nullable<Date>;
  londonPrice: Nullable<string>;
  gramPrice: Nullable<string>;
  ouncePrice: Nullable<string>;
  totalOuncePrice: Nullable<string>;
  totalOuncePriceWithDiff: Nullable<string>;
  currency: Nullable<string>;
  companyName: Nullable<string>;
  deliveryBasis: Nullable<string>;
  difference: Nullable<string>;
  weightWithUnit: WeightWithUnit;
  ligatureWeightWithUnit: WeightWithUnit;
  history: {
    weightWithUnit: Nullable<string>;
    ligatureWeightWithUnit: Nullable<string>;
  };
  replyCount: Nullable<number>;
}
