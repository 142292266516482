import * as Sentry from '@sentry/react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { ErrorBoundary } from '@/pages/ErrorBoundary';
import { useNetworkStatus } from '@/shared/hooks/useNetworkStatus';
import { WidthProvider } from '@/shared/hooks/useWidth';
import { Loader, OfflineStatus } from '@/shared/ui';
import { SideMenuPageLayout } from '@/widgets/menu';

import { RootStoreContextProvider } from './store';

export const App = (): React.ReactElement => {
  const { isOnline } = useNetworkStatus();

  const Content = Sentry.withErrorBoundary(Outlet, {
    fallback: <ErrorBoundary />,
  });

  return isOnline ? (
    <RootStoreContextProvider>
      <WidthProvider>
        <SideMenuPageLayout>
          <React.Suspense fallback={<Loader />}>
            <Content />
          </React.Suspense>
        </SideMenuPageLayout>
      </WidthProvider>
    </RootStoreContextProvider>
  ) : (
    <OfflineStatus />
  );
};
