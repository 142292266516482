import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ChatsButtons, NavigateChatsHandler } from '@/entities/chat';
import { useTypedTranslation } from '@/shared/hooks/useTranslation';
import { Loader } from '@/shared/ui';

import { infoKeyList } from '../../config';
import { TradeInfoPopoverModel } from '../../model';
import { TradeInfoList } from '../TradeInfoList';

import s from './TradeInfoPopover.module.scss';

type TradeInfoPopoverProps = {
  className?: string;
  rootClassName?: string;
  model: TradeInfoPopoverModel;
  navigateChats: NavigateChatsHandler;
};

const TradeInfoPopover: React.FC<TradeInfoPopoverProps> = ({ className, rootClassName, model, navigateChats }) => {
  const { loadingStage, tradeInfo, isOpen, open, close } = model;

  const { t } = useTypedTranslation();

  return (
    <Popover
      className={cn(s.popover, className)}
      rootClassName={cn(s.popover_root, rootClassName)}
      open={isOpen}
      onOpenChange={(opened) => {
        if (!opened) {
          close();
        } else {
          open();
        }
      }}
      title={t('tabs.info.title', { ns: 'trade' })}
      trigger="click"
      placement="bottomRight"
      arrow={false}
      content={
        <>
          {tradeInfo.value && !loadingStage.isLoading && (
            <TradeInfoList tradeInfo={tradeInfo.value} infoKeyList={infoKeyList}>
              {!!tradeInfo.value.chats.length && (
                <ChatsButtons close={close} chats={tradeInfo.value.chats} goToChat={navigateChats} />
              )}
            </TradeInfoList>
          )}
          {loadingStage.isLoading && <Loader className={s.popover_loader} fixed={Boolean(tradeInfo.value)} />}
        </>
      }
    >
      <Button icon={<InfoCircleOutlined />} />
    </Popover>
  );
};

export default observer(TradeInfoPopover);
