export const numberFormatter = (value: string): string => {
  return value ? Number(value).toLocaleString() : '';
};

type NumberParserParams = {
  maxFractionDigits: number;
  isAbsolute: boolean;
};

const defaultParams: NumberParserParams = {
  maxFractionDigits: 2,
  isAbsolute: true,
};

/**
 * Форматирует строку с учетом максимального количества цифр после точки
 * @param {object} [params] параметры для форматирования
 * @param {number} [params.maxFractionDigits=2] максимальное количество цифр после точки, по умолчанию = 2
 * @param {boolean} [params.isAbsolute=true] является ли число положительным, по умолчанию = true
 */
export const numberParser =
  (params: Partial<NumberParserParams> = {}): ((value: string) => string) =>
  (value: string): string => {
    const { maxFractionDigits, isAbsolute } = { ...defaultParams, ...params };
    const overMaxFractionDigits = maxFractionDigits + 1;
    /**
     * Регулярное выражение для проверки максимального количества цифр после точки
     */
    const overMaxFractionDigitsRegex = new RegExp(
      `^${isAbsolute ? '' : '-?'}\\d+(\\.\\d{${overMaxFractionDigits},})?$`,
    );

    /**
     * Заменяет недопустимые символы в строке:
     * [^0-9.] - все символы кроме цифр и точки
     * ^0\d+ - Ноль без точки в начале строки
     * ^\. - точка в начале строки
     * \.{2,} - несколько точек подряд
     * (?<=\.\d+)\. - точка в конце строки, если перед ней уже есть точка с цифрами
     */
    return (
      value
        .replace(/[^0-9.]|^0\d+|^\.|\.{2,}|(?<=\.\d+)\./g, (match, offset) => {
          if (match === '-' && offset === 0 && !isAbsolute) {
            return match;
          }

          if (match.startsWith('0')) {
            return '0';
          }

          if (match.startsWith('..')) {
            return '.';
          }

          return '';
        })
        /**
         * Заменяет лишние дробные знаки в строке
         */
        .replace(overMaxFractionDigitsRegex, (match, fraction) => {
          if (fraction) {
            const overage = fraction.length - overMaxFractionDigits;

            return match.slice(0, match.length - overage);
          }

          return match;
        })
    );
  };
