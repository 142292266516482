import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { UploadFileModel } from '@/shared/model/form/UploadFileModel';

import { CustomFormItem } from '../CustomFormItem';
import { CustomUpload } from '../CustomUpload';
import { UploadDragger } from '../UploadDragger';
import { UploadButton } from '../buttons';

/**
 *  default - загрузка через системное окно
 *  dragger - загрузка через drag&drop
 */
type FormUploadVariant = 'default' | 'dragger';

type FormUploadProps = {
  className?: string;
  model: UploadFileModel;
  variant?: FormUploadVariant;
  disabled?: boolean;
  buttonText?: string;
};

const FormUpload: React.FC<FormUploadProps> = ({ className, model, variant = 'default', disabled, buttonText }) => {
  return (
    <CustomFormItem className={className} ref={model.ref} model={model}>
      {variant === 'default' ? (
        <CustomUpload {...model.props} disabled={disabled}>
          {model.props.openFileDialogOnClick && (
            <UploadButton disabled={model.props.disabled || disabled} text={buttonText} />
          )}
        </CustomUpload>
      ) : (
        <UploadDragger {...model.props} disabled={disabled} />
      )}
    </CustomFormItem>
  );
};

export default observer(FormUpload);
