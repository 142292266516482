import { UploadFile } from 'antd';
import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { BaseMessageModel } from '@/entities/chat/model/BaseMessageModel';
import { FileModel } from '@/entities/file';
import { LoadingStageModel, ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { ChatInfo, IMessage, MessageServer, ThreadInfo, TradeInfo, UserInfo } from '../../types';

type JsonParams = {
  server: MessageServer;
  targetThreadId: number;
  rootStore: IRootStore;
};

export class MessageModel extends BaseMessageModel implements IMessage {
  readonly editDate: Nullable<Date>;
  readonly userInfo: UserInfo;
  readonly chatInfo: ChatInfo;
  readonly threadInfo: ThreadInfo;
  // id thread-а, в котором находится сообщение. Не то же самое, что и threadInfo.id, так как сообщение может лежать в общем стриме
  readonly targetThreadId: number;
  readonly tradeInfo: Nullable<TradeInfo>;

  readonly deletingStage = new LoadingStageModel();

  private readonly _readDate: ValueModel<Nullable<Date>>;

  constructor(params: {
    id: string;
    text: Nullable<string>;
    files: Nullable<Array<UploadFile>>;
    createdDate: Date;
    userId: number;
    editDate: Nullable<Date>;
    readDate: Nullable<Date>;

    userInfo: UserInfo;
    chatInfo: ChatInfo;
    threadInfo: ThreadInfo;
    targetThreadId: number;
    tradeInfo: Nullable<TradeInfo>;

    rootStore: IRootStore;
  }) {
    super(params);

    this.editDate = params.editDate;
    this.userInfo = params.userInfo;
    this.chatInfo = params.chatInfo;
    this.threadInfo = params.threadInfo;
    this.tradeInfo = params.tradeInfo;
    this.targetThreadId = params.targetThreadId;

    this._readDate = new ValueModel(params.readDate);

    makeObservable(this, {
      readDate: computed,

      readMessage: action.bound,
    });
  }

  get readDate(): Nullable<Date> {
    return this._readDate.value;
  }

  readMessage(readAt: string | Date): void {
    this._readDate.change(new Date(readAt));
  }

  static fromJson({ server, targetThreadId, rootStore }: JsonParams): MessageModel {
    return new MessageModel({
      id: server.id,
      text: server.text,
      files: server.attachments?.length ? FileModel.fileListFromJson(server.attachments) : null,
      createdDate: new Date(server.created_at),
      editDate: server.edited_at ? new Date(server.edited_at) : null,
      readDate: server.read_at ? new Date(server.read_at) : null,
      userId: server.user.id,
      userInfo: {
        id: server.user.id,
        name: server.user.name,
        type: server.user.type,
        ownInfo: server.user.id === rootStore.userStore.userModel?.id,
      },
      chatInfo: {
        id: server.chat.id,
        type: server.chat.type,
        name: server.chat.name,
        userType: server.chat.user_type,
      },
      threadInfo: {
        id: server.thread.id,
        type: server.thread.type,
      },
      targetThreadId,
      tradeInfo: server.trade && {
        id: server.trade.id,
        stage: server.trade.key,
      },
      rootStore,
    });
  }
}
