import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { apiStore, apiUrls } from '@/shared/api';
import { ListModel, LoadingStageModel, ToggleModel, ValueModel } from '@/shared/model';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable, Options } from '@/shared/types/values';

import { ThreadListReaponse, ThreadType } from '../../types';
import { BaseThreadModel } from '../BaseThreadModel';

type GeneralStreamModalModelParams = {
  sendMessage: (threadId?: Nullable<number>) => Promise<BaseResponse>;
  chatId: number;
  rootStore: IRootStore;
};

const T_OPTIONS = { ns: 'chats' } as const;

export class GeneralStreamModalModel {
  readonly isOnlyGeneralStream = new ValueModel(false);

  readonly modalState = new ToggleModel();

  readonly threadId = new SelectModel<number>({
    initialValue: null,
    placeholder: (t) => t('generalStreamModal.labelListDeal', T_OPTIONS),
  });

  readonly loadingStage = new LoadingStageModel();

  private readonly _threadList = new ListModel<BaseThreadModel, number>();

  private readonly _threadListRequest: ApiRequest<ThreadListReaponse>;

  private readonly _sendMessage: (threadId?: Nullable<number>) => Promise<BaseResponse>;

  private _rootStore: IRootStore;

  constructor({ chatId, sendMessage, rootStore }: GeneralStreamModalModelParams) {
    this._sendMessage = sendMessage;

    this._threadListRequest = apiStore.createRequest({
      url: apiUrls.chats.threadList(chatId),
    });

    this._rootStore = rootStore;

    makeObservable(this, {
      threadOptions: computed,
      disabled: computed,

      reset: action.bound,
      onSubmit: action.bound,
      closeModal: action.bound,
      loadThreads: action.bound,
    });
  }

  get threadOptions(): Options<number> {
    return this._threadList.items.reduce<Options<number>>((acc, thread) => {
      if (thread.type === ThreadType.trade) {
        acc.push({
          value: thread.id,
          label: thread.name,
        });
      }

      return acc;
    }, []);
  }

  get disabled() {
    return !this.isOnlyGeneralStream.value && !this.threadId.value;
  }

  closeModal() {
    this.reset();
    this.modalState.close();
  }

  reset(): void {
    this.isOnlyGeneralStream.reset();
    this.threadId.reset();
  }

  async onSubmit(): Promise<void> {
    const { isError } = await this._sendMessage(this.threadId.value);

    if (!isError) {
      this.closeModal();
    }
  }

  async loadThreads(): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const response = await this._threadListRequest.call({
      params: {
        limit: 10_000,
      },
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    this._threadList.fillByRawData(response.data.results, (server) => ({
      key: server.id,
      entity: BaseThreadModel.fromServer(server, this._rootStore),
    }));

    this.loadingStage.success();

    return { isError: false };
  }
}
