import { ID } from '../types/meta';

export const apiPrefix = '/api';

const getClientPrefix = (endpoint: string): string => `/client/${endpoint}`;
const getUserPrefix = (endpoint: string): string => `/user/${endpoint}`;
const getAgentPrefix = (endpoint: string): string => `/agent/${endpoint}`;
const getTradePrefix = (endpoint: string): string => `/trade/${endpoint}`;
const getDocumentPrefix = (endpoint: string): string => `/document/${endpoint}`;
const getDictionaryPrefix = (endpoint: string): string => `/handbook/${endpoint}`;
const getNotificationPrefix = (endpoint: string): string => `/notification/${endpoint}`;
const getChatPrefix = (endpoint: string): string => `/chat/${endpoint}`;
const getThreadPrefix = (endpoint: string): string => `/thread/${endpoint}`;
const getMessagePrefix = ({ threadId, endpoint = '' }: { threadId: ID; endpoint?: string }): string =>
  getThreadPrefix(`${threadId}/message/${endpoint}`);
const getBidPrefix = (endpoint: string): string => `/bid/${endpoint}`;
const getBidListPrefix = (endpoint: string): string => getBidPrefix(`list/${endpoint}`);
const getWSPrefix = (endpoint: string): string => `/ws/${endpoint}`;

export const apiUrls = {
  user: {
    current: getUserPrefix('current'),
    add: getUserPrefix('add'),
    edit: (id: ID) => getUserPrefix(String(id)),
    createPassword: getUserPrefix('create_password'),
    login: getUserPrefix('login'),
    company: getUserPrefix('company'),
    updatePassword: getUserPrefix('update_password'),
  },
  client: {
    list: getClientPrefix('list'),
  },
  agent: {
    list: getAgentPrefix('list'),
  },
  trade: {
    list: getTradePrefix('list'),
    downloadList: getTradePrefix('list/download'),
    create: getTradePrefix('create'),
    details: (id: ID) => getTradePrefix(String(id)),
    edit: (id: ID) => getTradePrefix(`${id}/edit`),
    action: (id: ID) => getTradePrefix(`${id}/action`),
    history: (id: ID) => getTradePrefix(`${id}/logs`),
    upload: (id: ID) => getTradePrefix(`${id}/upload`),
    downloadDocs: (id: ID) => getTradePrefix(`${id}/download`),
    delete: (id: ID) => getTradePrefix(`${id}/delete`),
  },
  document: {
    list: getDocumentPrefix('list'),
    download: getDocumentPrefix('list/download'),
    filters: getDocumentPrefix('filters'),
    original: (id: ID) => getDocumentPrefix(`${id}/original`),
    update: (id: ID) => getDocumentPrefix(String(id)),
    generationByDocType: <T extends string>(docType: T) => getDocumentPrefix(`generation/${docType}/`),
    generationById: (id: ID) => getDocumentPrefix(`generation/${id}`),
  },
  dictionaries: {
    legalCompanyList: getDictionaryPrefix('legal_company'),
    logisticCompanyList: getDictionaryPrefix('logistic_company'),
    currencyList: getDictionaryPrefix('currency'),
    productTypeList: getDictionaryPrefix('product_type'),
    productList: getDictionaryPrefix('product'),
    product: (id: ID) => getDictionaryPrefix(`product/${id}`),
    metalList: getDictionaryPrefix('metal'),
    signatoryList: getDictionaryPrefix('signatory'),
    countryList: getDictionaryPrefix('country'),
  },
  notifications: {
    list: getNotificationPrefix('list'),
    read: getNotificationPrefix('read'),
    settings: getNotificationPrefix('settings'),
    tgBotLink: getNotificationPrefix('tg_bot_link'),
  },
  chats: {
    chatList: getChatPrefix('list'),
    chat: (id: ID) => getChatPrefix(String(id)),
    threadList: (id: ID) => getChatPrefix(`${id}/list`),
    thread: (id: ID) => getThreadPrefix(String(id)),
    messageList: (threadId: ID) => getMessagePrefix({ threadId, endpoint: 'list' }),
    message: ({ threadId, messageId }: { threadId: ID; messageId: ID }) =>
      getMessagePrefix({ threadId, endpoint: String(messageId) }),
    messageCreate: (threadId: ID) => getMessagePrefix({ threadId, endpoint: 'create' }),
    messageRead: ({ threadId, messageId }: { threadId: ID; messageId: ID }) =>
      getMessagePrefix({ threadId, endpoint: `${messageId}/read` }),
  },
  bid: {
    create: getBidPrefix('create'),
    managerCreate: getBidPrefix('create/manager'),
    bid: (id: ID) => getBidPrefix(`${id}`),
    reply: (id: ID) => getBidPrefix(`${id}/reply`),
    publish: (id: ID) => getBidPrefix(`${id}/publish`),
    approve: (bidId: ID, replyId: ID) => getBidPrefix(`${bidId}/reply/${replyId}`),
    createTrade: (id: ID) => getBidPrefix(`${id}/create_trade`),
    bidReplyList: (id: ID) => getBidPrefix(`${id}/reply/list`),
    buyList: getBidListPrefix('buy'),
    sellList: getBidListPrefix('sell'),
    replyList: getBidListPrefix('reply'),
    myBidsList: getBidListPrefix('my'),
    managerBuyList: getBidListPrefix('manager/buy'),
    managerSellList: getBidListPrefix('manager/sell'),
    managerMyBidsList: getBidListPrefix('manager/my'),
  },
  ws: {
    connection: getWSPrefix('connection'),
    subscription: getWSPrefix('subscription'),
  },
};
