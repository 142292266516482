import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable, reaction } from 'mobx';

import { IRootStore } from '@/app/store';
import { IDictionariesStore, ProductType } from '@/entities/dictionary';
import { ListOffsetModel, LocalStore, RequestPollingModel, ValueModel } from '@/shared/model';
import { ColumnsData } from '@/shared/ui/Table';

import { IBaseBidListStore } from '../types';

import { BaseBidListFilters } from './BaseBidListFilters';
import { BidModel } from './BidModel';

export type BaseBidListStoreParams<M, F extends BaseBidListFilters<M>> = {
  filters: F;
  list: ListOffsetModel<M, number>;
  rootStore: IRootStore;
};

export abstract class BaseBidListStore<M extends BidModel, F extends BaseBidListFilters<M>, C>
  extends LocalStore
  implements IBaseBidListStore<M, F, C>
{
  readonly list: ListOffsetModel<M, number>;
  readonly filters: F;

  protected readonly _rootStore: IRootStore;

  protected readonly _rows = new ValueModel<C[]>([]);

  private readonly _productListPolling: RequestPollingModel;

  constructor({ filters, list, rootStore }: BaseBidListStoreParams<M, F>) {
    super();

    this._rootStore = rootStore;

    this.filters = filters;
    this.list = list;

    this._productListPolling = new RequestPollingModel({
      request: () => this._rootStore.dictionariesStore.products.load(ProductType.metal),
      timeout: 10_000,
    });

    makeObservable(this, {
      isInitialLoading: computed,
      isEmptyList: computed,
      isEmptyFilteredList: computed,
      columns: computed,
      rows: computed,

      changePage: action.bound,
      fetchItems: action.bound,
      refetch: action.bound,
      refetchByLimit: action.bound,
    });

    this.addReactions([
      reaction(
        () => this.filters.params,
        () => this.refetch(),
      ),
      /**
       * Если в списке есть модель без цены фиксации, то запускаем поллинг списка металлов,
       * чтобы сразу подтягивать спот-прайс для всех заявок без цены фиксации.
       */
      reaction(
        () => this.list.items.some((item) => !item.fixingPrice),
        (isNeedPolling) => {
          if (isNeedPolling) {
            this._productListPolling.watchProgress();
          } else {
            this._productListPolling.stopPolling();
          }
        },
      ),
    ]);
  }
  protected get _dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get isInitialLoading(): boolean {
    return (this.list.length === 0 && this.list.loadingStage.isLoading) || this._dictionaries.isInitialLoading;
  }

  get isEmptyList(): boolean {
    return (
      this.list.items.length === 0 &&
      !this.list.loadingStage.isLoading &&
      !this._dictionaries.isInitialLoading &&
      !this.filters.isFiltered
    );
  }

  get isEmptyFilteredList(): boolean {
    return (
      this.list.items.length === 0 &&
      !this.list.loadingStage.isLoading &&
      !this._dictionaries.isInitialLoading &&
      this.filters.isFiltered
    );
  }

  get rows(): C[] {
    return this._rows.value;
  }

  abstract get columns(): ColumnsData<C>;

  abstract fetchItems(params: { reset?: boolean }): Promise<BaseResponse>;

  refetch = (): void => {
    this.fetchItems({ reset: true });
  };

  changePage = (page: number): void => {
    this.list.changePage(page);
    this.filters.applyFilters();
  };

  refetchByLimit = (limit: number): void => {
    this.list.changeLimit(limit);
    this.refetch();
  };

  destroy(): void {
    super.destroy();
    this.list.entities.forEach((entity) => entity.destroy());
    this._productListPolling.stopPolling();
  }
}
