import { BaseResponse } from '@kts-front/types';
import { Subscription } from 'centrifuge';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import queryString from 'query-string';

import { IRootStore } from '@/app/store';
import { ChatListModel, ChatsQueryParams, ChatType, MessageModel } from '@/entities/chat';
import { ReadingService } from '@/entities/chat/model/ReadingService';
import { ChatWsEvents, ChatWsEventType, WsClientEvent, WSLocation } from '@/entities/ws';
import { apiStore, apiUrls } from '@/shared/api';
import { routerUrls } from '@/shared/config/routes';
import { LocalStore, QueryParamsModel, ValueModel } from '@/shared/model';
import { ID } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { IChatsStore, SubscriptionChannel } from './types';

type ChatsStoreParams = {
  queryParams: URLSearchParams;
  rootStore: IRootStore;
};

export class ChatsStore extends LocalStore implements IChatsStore {
  readonly chats: ChatListModel;

  readonly eventQueue: ChatWsEvents[] = [];

  readonly eventHandling = new ValueModel<boolean>(false);

  private readonly _subscription = new ValueModel<Nullable<Subscription>>(null);

  private readonly _subscriptionRequest = apiStore.createRequest<{ token: string }>({
    url: apiUrls.ws.subscription,
    params: {
      location: WSLocation.chat,
    },
  });

  private readonly _rootStore: IRootStore;
  private readonly _readingService: ReadingService = new ReadingService();
  private readonly _queryParams: QueryParamsModel<ChatsQueryParams>;

  constructor(params: ChatsStoreParams) {
    super();

    this._rootStore = params.rootStore;

    const initialQueryParams = this._normalizeQueryParams(params.queryParams);

    this._queryParams = new QueryParamsModel({
      params: initialQueryParams,
      routerStore: params.rootStore.routerStore,
    });

    this.chats = new ChatListModel({
      queryParams: this._queryParams,
      rootStore: params.rootStore,
      chatsStore: this,
    });

    makeObservable<ChatsStore, '_wsEventHandler'>(this, {
      eventQueue: observable,

      personalChatSelected: computed,

      navigateChats: action.bound,
      _wsEventHandler: action.bound,
      unsubscribe: action.bound,
      subscribe: action.bound,
      readMessage: action.bound,
    });

    this.addReactions([
      reaction(() => this.eventQueue.length, this._wsEventHandler),
      reaction(() => this.eventHandling.value, this._wsEventHandler),
    ]);
  }

  get personalChatSelected(): boolean {
    const selectedChat = this.chats.selectedItem;

    return selectedChat !== null && selectedChat.type !== ChatType.public;
  }

  navigateChats(params?: { chatId: ID; threadId?: ID }): void {
    const queryParams = this._queryParams.params;

    const searchParams: Partial<ChatsQueryParams> = {
      chat_list_search: queryParams.chat_list_search,
      client_type: queryParams.client_type,
      ...(params?.threadId
        ? {
            thread_list_search: queryParams.thread_list_search,
            trade_stages: queryParams.trade_stages,
          }
        : {}),
    };

    this._rootStore.routerStore.navigate({
      pathname: routerUrls.chats.create(params),
      search: queryString.stringify(searchParams, { skipNull: true, skipEmptyString: true, arrayFormat: 'index' }),
    });
  }

  readMessage(message: MessageModel): void {
    this._readingService.readMessage({
      id: message.id,
      threadId: message.targetThreadId,
      createdDate: message.createdDate,
    });
  }

  unsubscribe(): void {
    if (!this._subscription.value) {
      return;
    }

    this._subscription.value.off(WsClientEvent.publication, this._addWSEvent);
    this._subscription.value.unsubscribe();
  }

  async subscribe(): Promise<BaseResponse> {
    const result = await this._rootStore.centrifugeStore.getClient();
    const channel = this._subscriptionChannel;

    if (result.isError || !channel) {
      return { isError: true };
    }

    const client = result.data;

    let subscription = client.getSubscription(channel);

    if (!subscription) {
      const token = await this._getSubscriptionToken(channel);

      subscription = client.newSubscription(channel, {
        token,
        getToken: () => this._getSubscriptionToken(channel),
      });
    }

    subscription.on(WsClientEvent.publication, this._addWSEvent);
    subscription.subscribe();

    this._subscription.change(subscription);

    return { isError: false };
  }

  destroy(): void {
    super.destroy();
    this.unsubscribe();
    this._readingService.destroy();
    this.chats.destroy();
  }

  private get _subscriptionChannel(): Nullable<SubscriptionChannel<WSLocation.chat, number>> {
    const { userModel } = this._rootStore.userStore;

    return userModel && `${WSLocation.chat}:${userModel.id}`;
  }

  private async _wsEventHandler(): Promise<void> {
    if (this.eventHandling.value) {
      return;
    }

    const event = this.eventQueue.shift();

    if (!event) {
      return;
    }

    this.eventHandling.change(true);

    switch (event.type) {
      case ChatWsEventType.messageCreated:
        await this.chats.handleCreatedMessageWsEvent(event.body);

        break;

      case ChatWsEventType.messageRead:
        this.chats.handleReadMessagesWsEvent(event.body);

        break;

      case ChatWsEventType.threadCreated:
        await this.chats.handleCreatedThreadWsEvent(event.body);

        break;

      case ChatWsEventType.messageEdited:
        this.chats.handleEditedMessageWsEvent(event.body);

        break;

      case ChatWsEventType.messageDeleted:
        this.chats.handleDeletedMessageWsEvent(event.body);
    }

    this.eventHandling.change(false);
  }

  private readonly _addWSEvent = ({ data }: { data: ChatWsEvents }) => {
    this.eventQueue.push(data);
  };

  private readonly _normalizeQueryParams = (queryParams: URLSearchParams): ChatsQueryParams => {
    const paramsObj: Partial<ChatsQueryParams> = queryString.parse(queryParams.toString(), {
      parseNumbers: true,
      arrayFormat: 'index',
    });

    return {
      chat_list_search: paramsObj.chat_list_search ?? '',
      client_type: paramsObj.client_type ?? 'all',
      thread_list_search: paramsObj.thread_list_search ?? '',
      trade_stages: paramsObj.trade_stages ?? [],
      message_list_search: paramsObj.message_list_search ?? '',
    };
  };

  private readonly _getSubscriptionToken = async (channel: string): Promise<string> => {
    const response = await this._subscriptionRequest.call({
      params: { channel },
    });

    if (response.isError) {
      return '';
    }

    return response.data.token;
  };
}
