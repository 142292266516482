import { UserType } from '@/entities/user';
import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { mapStageTypeToLabel, mapUserTypeToStageTypesInChatFilters, StageType } from '../stage';

import { TradeInfoKeys } from './types';

export const tradeStageChatFilterOptionsCreator = (userType: UserType): Options<StageType> =>
  mapUserTypeToStageTypesInChatFilters[userType].map((stage) => ({
    value: stage,
    label: mapStageTypeToLabel[stage],
  }));

export const infoKeyList: TradeInfoKeys[] = [
  'supplier',
  'customer',
  'legalCompanyFrom',
  'legalCompanyTo',
  'productType',
  'product',
  'weightWithUnit',
  'ligatureWeightWithUnit',
  'fixingDate',
  'buyAmount',
  'sellAmount',
  'buyAmountTotal',
  'sellAmountTotal',
  'transitCountry',
  'transitLogisticCompany',
  'destinationLogisticCompany',
];

const T_OPTIONS = { ns: 'trade' } as const;

export const mapTradeInfoToLabel = (isMainRole: boolean): Record<TradeInfoKeys, TranslationString> => ({
  supplier: (t) => t('createTrade.fields.supplier', T_OPTIONS),
  customer: (t) => t('createTrade.fields.customer', T_OPTIONS),
  legalCompanyFrom: (t) => t('createTrade.fields.legalCompanyFrom', T_OPTIONS),
  legalCompanyTo: (t) => t(isMainRole ? 'createTrade.fields.legalCompanyTo' : 'createTrade.fields.supplier', T_OPTIONS),
  productType: (t) => t('createTrade.fields.productType', T_OPTIONS),
  product: (t) => t('createTrade.fields.product', T_OPTIONS),
  weightWithUnit: (t) => t('createTrade.fields.chemicalWeight', T_OPTIONS),
  ligatureWeightWithUnit: (t) => t('createTrade.fields.ligatureWeight', T_OPTIONS),
  fixingDate: (t) => t('createTrade.fields.fixingDate', T_OPTIONS),
  buyAmount: (t) => t('createTrade.fields.buyPrice', T_OPTIONS),
  buyAmountTotal: (t) => t('createTrade.fields.buyPriceTotal', T_OPTIONS),
  sellAmount: (t) => t(isMainRole ? 'createTrade.fields.sellPrice' : 'createTrade.fields.unitPrice', T_OPTIONS),
  sellAmountTotal: (t) =>
    t(isMainRole ? 'createTrade.fields.sellPriceTotal' : 'createTrade.fields.totalСost', T_OPTIONS),
  transitCountry: (t) =>
    t(isMainRole ? 'tabs.info.fields.transitCountryForManager' : 'tabs.info.fields.transitCountryForOther', T_OPTIONS),
  transitLogisticCompany: (t) => t('tabs.info.fields.transitLogisticCompany', T_OPTIONS),
  destinationLogisticCompany: (t) => t('tabs.info.fields.destinationLogisticCompany', T_OPTIONS),
});
