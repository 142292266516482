import { debounce } from '@kts-front/utils';
import { useEffect } from 'react';

import { useWidth } from './useWidth';

/**
 * @field ROW_HEIGHT - высота строки таблицы (px)
 * @field TABLE_HEADER_HEIGHT - высота строки заголовка таблицы (px)
 * @field HEADER_HEIGHT - высота заголовка страницы (px)
 * @field PAGINATION_HEIGHT - высота пагинации (px)
 */
type Heights = {
  ROW_HEIGHT: number;
  TABLE_HEADER_HEIGHT: number;
  HEADER_HEIGHT: number;
  PAGINATION_HEIGHT: number;
};

type Device = 'desktop' | 'tablet';

export type HeightsMap = Record<Device, Heights>;

const defaultHeights: HeightsMap = {
  desktop: {
    ROW_HEIGHT: 56,
    TABLE_HEADER_HEIGHT: 48,
    HEADER_HEIGHT: 76,
    PAGINATION_HEIGHT: 72,
  },
  tablet: {
    ROW_HEIGHT: 48,
    TABLE_HEADER_HEIGHT: 40,
    HEADER_HEIGHT: 112,
    PAGINATION_HEIGHT: 64,
  },
};

const calculateRowsCount = (pageHeight: number, device: Device, heights?: HeightsMap): number => {
  const { ROW_HEIGHT, TABLE_HEADER_HEIGHT, HEADER_HEIGHT, PAGINATION_HEIGHT } = (heights ?? defaultHeights)[device];

  const totalRowsHeight = pageHeight - HEADER_HEIGHT - PAGINATION_HEIGHT - TABLE_HEADER_HEIGHT;

  return Math.floor(totalRowsHeight / ROW_HEIGHT);
};

export const useUpdateTableContent = (
  pageRef: React.RefObject<HTMLDivElement | null>,
  refetchByLimit: (limit: number) => void,
  heights?: HeightsMap,
): void => {
  const { isDesktop } = useWidth();
  const device: Device = isDesktop ? 'desktop' : 'tablet';

  useEffect(() => {
    if (!pageRef.current) {
      return;
    }

    let rowsCount = calculateRowsCount(pageRef.current.clientHeight, device, heights);

    const updateTableContent = (observedEntities?: ResizeObserverEntry[]) => {
      const pageEntity = observedEntities?.[0];

      if (pageEntity) {
        const newRowsCount = calculateRowsCount(pageEntity.borderBoxSize[0].blockSize, device, heights);

        if (rowsCount === newRowsCount) {
          return;
        }

        rowsCount = newRowsCount;
      }

      refetchByLimit(rowsCount);
    };

    updateTableContent();

    const observer = new ResizeObserver(debounce({ func: updateTableContent, timeout: 300 }));
    observer.observe(pageRef.current);

    return () => {
      observer.disconnect();
    };
  }, [pageRef.current, device, heights]);
};
