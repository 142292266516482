import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';
import { CustomTypographyText } from '@/shared/ui';
import { getDateFormat } from '@/shared/utils/getDateFormat';

import s from './SectionTitleAsDate.module.scss';

type SectionTitleAsDateProps = {
  className?: string;
  title: string | Date;
};

const SectionTitleAsDate: React.FC<SectionTitleAsDateProps> = ({ className, title }) => {
  const titleFormat = getDateFormat({
    dateString: title,
    formatWithUnit: 'DD MMMM YYYY',
    formatWithoutUnit: 'DD MMMM',
    unitType: 'year',
  });

  let sectionTitle: TranslationString | Dayjs = dayjs(title);

  if (sectionTitle.isToday()) {
    sectionTitle = (t) => t('date.today', { ns: 'shared' });
  } else if (sectionTitle.isYesterday()) {
    sectionTitle = (t) => t('date.yesterday', { ns: 'shared' });
  }

  return (
    <CustomTypographyText className={cn(s['section-title'], className)} type="secondary">
      {typeof sectionTitle === 'object' ? dayjs(sectionTitle).format(titleFormat) : sectionTitle}
    </CustomTypographyText>
  );
};

export default React.memo(SectionTitleAsDate);
