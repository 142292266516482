import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable, reaction } from 'mobx';

import { ChatListPayload, ChatModel, IChatsStore, ThreadListModel } from '@/entities/chat';
import { MessageType } from '@/entities/message';
import {
  CreatedMessageWsBody,
  CreatedThreadWsBody,
  DeletedMessageWsBody,
  EditedMessageWsBody,
  ReadMessagesWsBody,
} from '@/entities/ws';
import { apiStore, apiUrls } from '@/shared/api';
import { ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { ChatListClientType, ChatListReaponse, ChatServer } from '../../types';
import { BaseChatListModel, BaseChatListModelParams } from '../BaseChatListModel';

type ChatListModelParams = BaseChatListModelParams & {
  chatsStore: IChatsStore;
};

export class ChatListModel extends BaseChatListModel<ChatModel, ChatListPayload> {
  private readonly _clientTypeModel: ValueModel<ChatListClientType>;
  private _chatsStore: IChatsStore;

  private readonly _chatListRequest = apiStore.createRequest<ChatListReaponse>({
    url: apiUrls.chats.chatList,
  });

  constructor(params: ChatListModelParams) {
    super({
      ...params,
      searchValue: params.queryParams.params.chat_list_search,
    });

    this._chatsStore = params.chatsStore;
    this._clientTypeModel = new ValueModel(params.queryParams.params.client_type);

    makeObservable(this, {
      clientType: computed,
      threads: computed,
      sortedList: computed,

      onChangeClientType: action.bound,
      handleCreatedThreadWsEvent: action.bound,
      handleCreatedMessageWsEvent: action.bound,
      handleReadMessagesWsEvent: action.bound,
      handleEditedMessageWsEvent: action.bound,
      handleDeletedMessageWsEvent: action.bound,
    });

    this.addReactions([
      reaction(
        () => {
          const queryParams = this._queryParams.params;

          return [queryParams.chat_list_search, queryParams.client_type];
        },
        (current, prev) => {
          if (current.some((value, index) => value !== prev[index])) {
            this.initialLoad({ reset: true });
          }
        },
      ),
    ]);
  }

  get sortedList(): ChatModel[] {
    const copy = [...this.list.items];

    return copy.sort((item1, item2) => {
      if (item1.pinned) {
        return -1;
      }

      if (item2.pinned) {
        return 1;
      }

      return 0;
    });
  }

  get threads(): Nullable<ThreadListModel> {
    return this.selectedItem?.threads ?? null;
  }

  get clientType(): ChatListClientType {
    return this._clientTypeModel.value;
  }

  onChangeClientType(value: ChatListClientType): void {
    this._clientTypeModel.change(value);
    this.applyParams();
  }

  applyParams(): void {
    this._queryParams.setParams({
      chat_list_search: this._searchModel.value,
      client_type: this._clientTypeModel.value,
      thread_list_search: '',
      trade_stages: [],
    });
  }

  resetParams(): void {
    this._selectedItemModel.change(null);
    this._searchModel.change('');
    this._clientTypeModel.change('all');
    this.applyParams();
  }

  async handleCreatedThreadWsEvent(body: CreatedThreadWsBody): Promise<void> {
    const chatId = body.chat_id;
    const result = await this._moveItemToTop(chatId);

    /*
     * В случае если такой chat был в списке, то обрабатываем событие
     * */
    if (!result.isError && !result.data.isNewInList) {
      const chat = result.data.item;
      await chat.handleCreatedThreadWsEvent(body);
    }

    const selectedChat = this.selectedItem;

    /*
     * В случае если есть выбранный чат и он не в списке, то также обрабатываем событие
     * */
    if (
      selectedChat &&
      selectedChat.id === chatId &&
      (result.isError || (!result.isError && result.data.item !== selectedChat))
    ) {
      await selectedChat.handleCreatedThreadWsEvent(body);
    }
  }

  async handleCreatedMessageWsEvent(body: CreatedMessageWsBody): Promise<void> {
    const chatId = body.chat.id;
    const result = await this._moveItemToTop(chatId);

    /*
     * В случае если такой chat был в списке, то обрабатываем событие
     * */
    if (!result.isError && !result.data.isNewInList) {
      const chat = result.data.item;
      await chat.handleCreatedMessageWsEvent(body);
    }

    const selectedChat = this.selectedItem;

    /*
     * В случае если есть выбранный чат и он не в списке, то также обрабатываем событие
     * */
    if (
      selectedChat &&
      selectedChat.id === chatId &&
      (result.isError || (!result.isError && result.data.item !== selectedChat))
    ) {
      await selectedChat.handleCreatedMessageWsEvent(body);
    }
  }

  handleReadMessagesWsEvent(body: ReadMessagesWsBody): void {
    const chatId = body.chat_id;
    const chat = this.list.getEntity(chatId);

    if (chat) {
      chat.handleReadMessagesWsEvent(body);
    }

    const selectedChat = this.selectedItem;

    /*
     * В случае если есть выбранный чат и он не в списке, то также обрабатываем событие
     * */
    if (selectedChat && selectedChat.id === chatId && (!chat || chat !== selectedChat)) {
      selectedChat.handleReadMessagesWsEvent(body);
    }
  }

  handleEditedMessageWsEvent(body: EditedMessageWsBody): void {
    const chatId = body.chat.id;
    const chat = this.list.getEntity(chatId);

    if (chat) {
      chat.handleEditedMessageWsEvent(body);
    }

    const selectedChat = this.selectedItem;

    /*
     * В случае если есть выбранный чат и он не в списке, то также обрабатываем событие
     * */
    if (selectedChat && selectedChat.id === chatId && (!chat || chat !== selectedChat)) {
      selectedChat.handleEditedMessageWsEvent(body);
    }
  }

  handleDeletedMessageWsEvent(body: DeletedMessageWsBody): void {
    const result = this._moveItemToPosition(
      ChatModel.fromJson({
        server: body.chat,
        queryParams: this._queryParams,
        chatsStore: this._chatsStore,
        rootStore: this._rootStore,
      }),
    );

    if (!result.isError) {
      const chat = result.data;
      chat.handleDeletedMessageWsEvent(body);
    }

    const selectedChat = this.selectedItem;

    /*
     * В случае если есть выбранный чат и он не в списке, то также обрабатываем событие
     * */
    if (
      selectedChat &&
      selectedChat.id === body.chat.id &&
      (result.isError || (!result.isError && result.data !== selectedChat))
    ) {
      selectedChat.handleDeletedMessageWsEvent(body);
    }
  }

  destroy() {
    super.destroy();

    this.list.items.forEach((item) => item.destroy());
  }

  protected get _paramsJson(): Nullable<ChatListPayload> {
    const search = this._searchModel.value;
    const clientType = this._clientTypeModel.value;

    if (!search && clientType === 'all') {
      return null;
    }

    return {
      ...(search ? { search } : {}),
      ...(clientType !== 'all' ? { user_type: clientType } : {}),
    };
  }

  protected async _fetchItems(reset: boolean): Promise<BaseResponse> {
    if (reset) {
      this.list.changeInitial(true);
    }

    const params = {
      limit: this.list.limit,
      offset: this.list.offset,
      ...(this._paramsJson ?? {}),
    };

    const response = await this._chatListRequest.call({
      params,
    });

    if (response.isError) {
      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.loadChatListError', { ns: 'chats' }),
      });

      return { isError: true };
    }

    this.list.fillByRawData(
      response.data.results,
      (raw) => ({
        key: raw.id,
        entity: ChatModel.fromJson({
          server: raw,
          queryParams: this._queryParams,
          chatsStore: this._chatsStore,
          rootStore: this._rootStore,
        }),
      }),
      reset,
    );

    this.list.changeInitial(false);
    this.list.total.change(response.data.total);

    return { isError: false };
  }

  protected async _fetchItem(chatId: number, omitParams: boolean = false): Promise<BaseResponse<ChatModel>> {
    const params = omitParams || !this._paramsJson ? {} : this._paramsJson;

    const response = await apiStore
      .createRequest<Nullable<ChatServer>>({
        params,
        url: apiUrls.chats.chat(chatId),
      })
      .call();

    if (response.isError || !response.data) {
      return { isError: true };
    }

    return {
      isError: false,
      data: ChatModel.fromJson({
        server: response.data,
        queryParams: this._queryParams,
        chatsStore: this._chatsStore,
        rootStore: this._rootStore,
      }),
    };
  }
}
