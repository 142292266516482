import { action, computed, makeObservable, observable } from 'mobx';

export class NewMessageCounter {
  private _count: number;

  constructor(count: number) {
    this._count = count;

    makeObservable<NewMessageCounter, '_count'>(this, {
      _count: observable,

      count: computed,
      hasNewMessages: computed,

      change: action.bound,
    });
  }

  get hasNewMessages(): boolean {
    return this.count > 0;
  }

  get count(): number {
    return this._count;
  }

  change(count: number): void {
    this._count = count;
  }
}
