import { HistoryAction } from '@/entities/tradeHistory';

import {
  AwbDirectAction,
  AwbTransitAction,
  ManagerAwbDestinationAction,
  ManagerAwbVerifyingAction,
  ManagerConfirmationStep,
  ManagerConfirmationVerifyingAction,
  ManagerCreationStep,
  ManagerFinalizationStep,
  ManagerPaymentStep,
  ManagerShipmentStep,
  ManagerSpecificationVerifyingAction,
  ManagerTransactionAction,
  ManagerTransportationStep,
  OptionFieldsAction,
} from '../../stage';

export const tradeHistory = {
  en: {
    manager: {
      [ManagerCreationStep.createTrade]: 'Trade № {{tradeId}} created',

      [ManagerConfirmationStep.deliveryConfirmation]: 'Delivery confirmation uploaded',
      [ManagerConfirmationStep.confirmationSigning]: 'Delivery confirmation signed',

      [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]: 'Delivery confirmation marked correct',
      [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]: 'Delivery confirmation marked incorrect',

      [ManagerPaymentStep.uploadBill]: 'Supplier invoice uploaded',
      [ManagerPaymentStep.payBill]: 'The invoice is paid',

      [ManagerShipmentStep.uploadSpec]: 'Supplier specification uploaded',
      [ManagerShipmentStep.shipmentType]: 'Shipment type selected',
      [ManagerShipmentStep.cargoShipment]: 'Confirmed shipment of cargo',
      [ManagerShipmentStep.transitCountry]: 'Transit country selected',
      [ManagerShipmentStep.destinationCountryDocs]: 'Documents for destination country uploaded',

      [ManagerSpecificationVerifyingAction.approveSpecification]: 'Specification marked correct',
      [ManagerSpecificationVerifyingAction.rejectSpecification]: 'Specification marked incorrect',
      [ManagerAwbVerifyingAction.approveAwb]: 'AWB marked correct',
      [ManagerAwbVerifyingAction.rejectAwb]: 'AWB marked incorrect',
      [AwbTransitAction.uploadFile]: 'AWB for transit country uploaded',
      [AwbTransitAction.flightNumber]: 'Flight number for transit country loaded',
      [AwbDirectAction.uploadFile]: 'AWB for destination country uploaded',
      [AwbDirectAction.flightNumber]: 'Flight number for destination country loaded',

      [ManagerTransportationStep.transitCountryCargoArrival]: 'Arrival of cargo in transit country confirmed',
      [ManagerTransportationStep.cargoArrival]: 'Arrival of cargo in destination country confirmed',
      [ManagerTransportationStep.tradeCloseSupplierDocs]: 'Documents for closing trade with supplier uploaded',
      [ManagerTransportationStep.destinationCountryDocs]: 'Documents for destination country uploaded',
      [ManagerTransportationStep.transitCountryCargoShipment]: 'Shipment of cargo from transit country confirmed',
      [ManagerTransportationStep.destinationCountryCargoArrival]: 'Arrival of cargo in destination country confirmed',

      [ManagerAwbDestinationAction.uploadFile]: 'AWB for destination country uploaded',
      [ManagerAwbDestinationAction.flightNumber]: 'Flight number for destination country loaded',

      [ManagerFinalizationStep.cargoDelivered]: 'Cargo delivery confirmed',
      [ManagerFinalizationStep.tradeCloseCustomerDocs]: 'Documents for closing trade with customer uploaded',
      [ManagerTransactionAction.addTransaction]: 'Payment transaction completed',
      [ManagerTransactionAction.deleteTransaction]: 'Payment transaction deleted',
      [ManagerTransactionAction.finish]: 'The deal is completed',

      [OptionFieldsAction.deleteSupplierSpec]: 'Supplier specification deleted',
      [OptionFieldsAction.setDestinationLogisticCompany]: 'A logistics company in the destination country selected',
      [OptionFieldsAction.setTransitLogisticCompany]: 'A logistics company in the transit country selected',
      [OptionFieldsAction.setTrackingLink]: 'Tracking link added',
      [OptionFieldsAction.uploadFinalizationPhoto]: 'Photo uploaded',
      [OptionFieldsAction.uploadAuthorizationLetter]: 'Authorization letter uploaded',
      [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: 'Indirect taxes payment certificate uploaded',
      [OptionFieldsAction.uploadWarrantyLoa]: 'Warranty LOA uploaded',

      [HistoryAction.transferToPayment]: 'The deal has been moved to the "Payment" stage',
      [HistoryAction.transferToShipment]: 'The deal has been moved to the "Shipment" stage',
      [HistoryAction.transferToTransportation]: 'The deal has been moved to the "Transportation" stage',
      [HistoryAction.transferToFinalization]: 'The deal has been moved to the "Finalization" stage',
      [HistoryAction.cargoDirectShipmentConfirm]: 'Shipment of cargo to the destination country confirmed',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Shipment of cargo to the transit country confirmed',
    },
    supplier: {
      [AwbTransitAction.uploadFile]: 'AWB for destination country uploaded',
      [AwbTransitAction.flightNumber]: 'The flight number for the destination country is indicated',
      [ManagerTransportationStep.tradeCloseSupplierDocs]: 'Documents for closing trade uploaded',
      [ManagerTransportationStep.transitCountryCargoArrival]: 'Arrival of cargo in destination country confirmed',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Shipment of cargo to the destination country confirmed',
      [HistoryAction.transferToTransportation]: 'The deal has been moved to the "Completing the deal" stage.',
      [HistoryAction.transferToFinalization]: 'The manager marked the deal as completed',
    },
  },
  ru: {
    manager: {
      [ManagerCreationStep.createTrade]: 'Создана сделка № {{tradeId}}',

      [ManagerConfirmationStep.deliveryConfirmation]: 'Загружено подтверждение поставки',
      [ManagerConfirmationStep.confirmationSigning]: 'Подписано подтверждение поставки',

      [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]: 'Подтверждение поставки отмечено верным',
      [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]: 'Подтверждение поставки отмечено ошибочным',

      [ManagerPaymentStep.uploadBill]: 'Загружен счет от поставщика',
      [ManagerPaymentStep.payBill]: 'Счет оплачен',

      [ManagerShipmentStep.uploadSpec]: 'Загружена спецификация поставщика',
      [ManagerShipmentStep.shipmentType]: 'Выбран тип отгрузки',
      [ManagerShipmentStep.cargoShipment]: 'Подтверждена отгрузка груза',
      [ManagerShipmentStep.transitCountry]: 'Выброна страна транзита',
      [ManagerShipmentStep.destinationCountryDocs]: 'Загрузжены документы для страны назначения',

      [ManagerSpecificationVerifyingAction.approveSpecification]: 'Спецификация отмечена верной',
      [ManagerSpecificationVerifyingAction.rejectSpecification]: 'Спецификация отмечена ошибочной',
      [ManagerAwbVerifyingAction.approveAwb]: 'AWB отмечен верным',
      [ManagerAwbVerifyingAction.rejectAwb]: 'AWB отмечен ошибочным',
      [AwbTransitAction.uploadFile]: 'Загружен AWB для страны транзита',
      [AwbTransitAction.flightNumber]: 'Указан номер рейса для страны транзита',
      [AwbDirectAction.uploadFile]: 'Загружен AWB для страны назначения',
      [AwbDirectAction.flightNumber]: 'Указан номер рейса для страны назначения',

      [ManagerTransportationStep.transitCountryCargoArrival]: 'Подтверждено прибытие груза в страну транзита',
      [ManagerTransportationStep.cargoArrival]: 'Подтверждено прибытие груза в страну назначения',
      [ManagerTransportationStep.tradeCloseSupplierDocs]: 'Загружены закрывающие документы по сделке для поставщика',
      [ManagerTransportationStep.destinationCountryDocs]: 'Загрузжены документы для страны назначения',
      [ManagerTransportationStep.transitCountryCargoShipment]: 'Подтверждена отгрузка груза из страны транзита',
      [ManagerTransportationStep.destinationCountryCargoArrival]: 'Подтверждено прибытие груза в страну назначения',

      [ManagerAwbDestinationAction.uploadFile]: 'Загружен AWB для страны назначения',
      [ManagerAwbDestinationAction.flightNumber]: 'Указан номер рейса для страны назначения',

      [ManagerFinalizationStep.cargoDelivered]: 'Подтверждена доставка груза',
      [ManagerFinalizationStep.tradeCloseCustomerDocs]: 'Загружены документы для закрытия сделки с покупателем',
      [ManagerTransactionAction.addTransaction]: 'Произведена транзакция оплаты',
      [ManagerTransactionAction.deleteTransaction]: 'Произведено удаление транзакции оплаты',
      [ManagerTransactionAction.finish]: 'Сделка завершена',

      [OptionFieldsAction.deleteSupplierSpec]: 'Удалена спецификация поставщика',
      [OptionFieldsAction.setDestinationLogisticCompany]: 'Установлена логистическая компания в стране назначения',
      [OptionFieldsAction.setTransitLogisticCompany]: 'Установлена логистическая компания в стране транзита',
      [OptionFieldsAction.setTrackingLink]: 'Указана ссылка для отслеживания',
      [OptionFieldsAction.uploadFinalizationPhoto]: 'Загружена фотография',
      [OptionFieldsAction.uploadAuthorizationLetter]: 'Загружено авторизационное письма',
      [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: 'Загружена справка об уплате косвенных налогов',
      [OptionFieldsAction.uploadWarrantyLoa]: 'Загружена доверенность LOA',

      [HistoryAction.transferToPayment]: 'Сделка переведена на этап "Оплата"',
      [HistoryAction.transferToShipment]: 'Сделка переведена на этап "Отгрузка"',
      [HistoryAction.transferToTransportation]: 'Сделка переведена на этап "Перевозка"',
      [HistoryAction.transferToFinalization]: 'Сделка переведена на этап "Завершение сделки"',
      [HistoryAction.cargoDirectShipmentConfirm]: 'Подтверждена отгрузка груза в страну назначения',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Подтверждена отгрузка груза в страну транзита',
    },
    supplier: {
      [AwbTransitAction.uploadFile]: 'Загружен AWB для страны назначения',
      [AwbTransitAction.flightNumber]: 'Указан номер рейса для страны назначения',
      [ManagerTransportationStep.tradeCloseSupplierDocs]: 'Загружены закрывающие документы по сделке',
      [ManagerTransportationStep.transitCountryCargoArrival]: 'Подтверждено прибытие груза в страну назначения',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Подтверждена отгрузка груза в страну назначения',
      [HistoryAction.transferToTransportation]: 'Сделка переведена на этап "Завершение сделки"',
      [HistoryAction.transferToFinalization]: 'Менеджер отметил сделку завершенной',
    },
  },
  zh: {
    manager: {
      [ManagerCreationStep.createTrade]: '交易编号 {{tradeId}} 已创建 ',

      [ManagerConfirmationStep.deliveryConfirmation]: '交货确认已上传',
      [ManagerConfirmationStep.confirmationSigning]: '交货确认已签署',

      [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]: '交货确认标记正确',
      [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]: '交货确认标记为不正确',

      [ManagerPaymentStep.uploadBill]: '供应商账单已上传',
      [ManagerPaymentStep.payBill]: '账单已付',

      [ManagerShipmentStep.uploadSpec]: '供应商规格已上传',
      [ManagerShipmentStep.shipmentType]: '装运类型已选择',
      [ManagerShipmentStep.cargoShipment]: '货物装运已确认',
      [ManagerShipmentStep.transitCountry]: '过境国已选择',
      [ManagerShipmentStep.destinationCountryDocs]: '已上传目的国文件',

      [ManagerSpecificationVerifyingAction.approveSpecification]: '规格标记为正确',
      [ManagerSpecificationVerifyingAction.rejectSpecification]: '规格标记为不正确',
      [ManagerAwbVerifyingAction.approveAwb]: 'AWB标记为准确',
      [ManagerAwbVerifyingAction.rejectAwb]: 'AWB标记为不准确',
      [AwbTransitAction.uploadFile]: '已为过境国选择AWB',
      // [AwbTransitAction.flightNumber]: 'Указан номер рейса для страны транзита',
      [AwbDirectAction.uploadFile]: '已上传目的国AWB',
      // [AwbDirectAction.flightNumber]: 'Указан номер рейса для страны назначения',

      [ManagerTransportationStep.transitCountryCargoArrival]: '货物抵达过境国已确认',
      [ManagerTransportationStep.cargoArrival]: '货物抵达目的国已确认',
      [ManagerTransportationStep.tradeCloseSupplierDocs]: '为供应商上传成交文件',
      [ManagerTransportationStep.destinationCountryDocs]: '已上传目的国文件',
      [ManagerTransportationStep.transitCountryCargoShipment]: '货物装运目的国已确认',
      [ManagerTransportationStep.destinationCountryCargoArrival]: '货物抵达目的国已确认',

      [ManagerAwbDestinationAction.uploadFile]: '已上传目的国AWB',
      // [ManagerAwbDestinationAction.flightNumber]: 'Указан номер рейса для страны назначения',

      [ManagerFinalizationStep.cargoDelivered]: '货物交付已确认',
      [ManagerFinalizationStep.tradeCloseCustomerDocs]: '与买方成交的文件已上传',
      [ManagerTransactionAction.addTransaction]: '付款交易完成',
      [ManagerTransactionAction.deleteTransaction]: '付款交易已删除',
      [ManagerTransactionAction.finish]: '交易完成',

      [OptionFieldsAction.deleteSupplierSpec]: '供应商规格已删除',
      [OptionFieldsAction.setDestinationLogisticCompany]: '在目的国的物流公司已设定',
      [OptionFieldsAction.setTransitLogisticCompany]: '在过境国的物流公司已设定',
      [OptionFieldsAction.setTrackingLink]: '指定了跟踪链接',
      [OptionFieldsAction.uploadFinalizationPhoto]: '照片已上传',
      [OptionFieldsAction.uploadAuthorizationLetter]: '授权书已上传',
      [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: '间接税缴纳证明已上传',
      [OptionFieldsAction.uploadWarrantyLoa]: 'LOA委托书已上传',

      [HistoryAction.transferToPayment]: '交易已进入“付款”阶段',
      [HistoryAction.transferToShipment]: '交易已进入“装运”阶段',
      [HistoryAction.transferToTransportation]: '交易已进入“运输”阶段',
      [HistoryAction.transferToFinalization]: '交易已进入“交易完成”阶段',
      [HistoryAction.cargoDirectShipmentConfirm]: '确认货物已运往目的国',
      [HistoryAction.cargoTransitShipmentConfirm]: '确认货物已运往过境国',
    },
    supplier: {
      [AwbTransitAction.uploadFile]: '已上传目的国AWB',
      // [AwbTransitAction.flightNumber]: 'Указан номер рейса для страны назначения',
      // [ManagerTransportationStep.tradeCloseSupplierDocs]: 'Загружены закрывающие документы по сделке',
      [ManagerTransportationStep.transitCountryCargoArrival]: '货物抵达目的国已确认',
      [HistoryAction.cargoTransitShipmentConfirm]: '确认货物已运往目的国',
      [HistoryAction.transferToTransportation]: '交易已进入“交易完成”阶段',
      [HistoryAction.transferToFinalization]: '经理将交易标记为已完成',
    },
  },
};
