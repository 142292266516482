import { StageType } from '@/entities/stage';

export const trade = {
  en: {
    tradeName: 'Trade № {{tradeId}}',
    trades: 'Trades',
    tabs: {
      actions: {
        searchLine: 'Find document',
        roleMenu: 'Filter by role',
        docsArchiveLink: 'Download archive',
      },
      currentStep: {
        title: 'Current step',
      },
      info: {
        title: 'Information',
        fields: {
          transitCountryForManager: 'Transit country',
          transitCountryForOther: 'Destination country',
          transitLogisticCompany: 'Transit logistic company',
          destinationLogisticCompany: 'Destination logistic company',
          trackingLink: 'Track cargo',
        },
      },
      documents: {
        title: 'Documents',
        uploadButton: 'Upload document',
        emptyListText: 'No documents have been uploaded yet',
        filteredEmptyListText:
          'No results were found based on your search or filtering. Change your request and try again.',
        docTypeLabel: 'Document type',
        docTypePlaceholder: 'Select document type',
        uploadModalTitle: "Uploading deal's document",
      },
      specification: {
        title: 'Specification',
        uploadButton: 'Upload specification',
        emptyListText: 'No specifications have been uploaded yet',
        docTypeLabel: 'Specification type',
        docTypePlaceholder: 'Select specification type',
        uploadModalTitle: 'Uploading specification',
        uploadError: 'Error uploading specification',
        uploadSuccess: 'Specification uploaded successfully',
        updateError: 'Error updating specification',
        updateSuccess: 'Specification updated successfully',
      },
      updates: {
        title: 'Updates',
        emptyListText: 'No updates available',
      },
    },
    transfer: {
      title: 'Transfer to next stage',
      description: 'Complete the stage by clicking on the button “Transfer\u00A0to\u00A0{{ stage }}“',
      buttonText: 'Transfer to {{ stage }}',
      // map current stage to next stage
      [StageType.creation]: 'Confirmation',
      [StageType.confirmation]: 'Payment',
      [StageType.payment]: 'Shipment',
      [StageType.shipment]: 'Transportation',
      [StageType.transportation]: 'Complete Deal',
      [StageType.finalization]: '',
    },
    tradeStageFilter: {
      title: 'Filtering trades by status',
      all: 'All trades',
    },
    createTrade: {
      title: 'Create trade',
      fields: {
        supplier: 'Supplier',
        customer: 'Customer',
        legalCompanyFrom: 'Legal entity (from who buy)',
        legalCompanyTo: 'Legal entity (from who sell)',
        productType: 'Product type',
        product: 'Product',
        chemicalWeight: 'Chemical weight',
        ligatureWeight: 'Ligature weight',
        amount: 'Amount',
        fixingDate: 'Fixing date',
        sellPrice: 'Sell price (per unit)',
        sellPriceTotal: 'Sell price total',
        buyPrice: 'Buy price (per unit)',
        buyPriceTotal: 'Buy price total',
        supplierSpec: 'Supplier specification',
        totalСost: 'Total cost',
        unitPrice: 'Unit price',
      },
    },
    messages: {
      createTradeSuccess: 'Trade created successfully',
      createTradeError: 'Failed to create trade',
      fetchTradeInfoError: 'Error loading trade information',
    },
  },
  ru: {
    tradeName: 'Сделка № {{tradeId}}',
    trades: 'Сделки',
    tabs: {
      actions: {
        searchLine: 'Найти документ',
        roleMenu: 'Фильтровать по роли',
        docsArchiveLink: 'Скачать архив',
      },
      currentStep: {
        title: 'Текущий шаг',
      },
      info: {
        title: 'Информация',
        fields: {
          transitCountryForManager: 'Страна транзита',
          transitCountryForOther: 'Страна назначения',
          transitLogisticCompany: 'Логисты в стране транзита',
          destinationLogisticCompany: 'Логисты в стране назначения',
          trackingLink: 'Отслеживать груз',
        },
      },
      documents: {
        title: 'Документы',
        uploadButton: 'Загрузить документ',
        emptyListText: 'Документы еще не были загружены',
        filteredEmptyListText:
          'По вашему запросу или фильтрации ничего не найдено. Измените запрос и попробуйте снова.',
        docTypeLabel: 'Тип документа',
        docTypePlaceholder: 'Выберите тип документа',
        uploadModalTitle: 'Загрузка документа по сделке',
      },
      specification: {
        title: 'Спецификация',
        uploadButton: 'Загрузить спецификацию',
        emptyListText: 'Спецификации еще не были загружены',
        docTypeLabel: 'Тип спецификации',
        docTypePlaceholder: 'Выберите тип спецификации',
        uploadModalTitle: 'Загрузка спецификации',
        uploadError: 'Ошибка загрузки спецификации',
        uploadSuccess: 'Спецификация успешно загружена',
        updateError: 'Ошибка обновления спецификации',
        updateSuccess: 'Спецификация успешно обновлена',
      },
      updates: {
        title: 'Обновления',
        emptyListText: 'Обновления отсутствуют',
      },
    },
    transfer: {
      title: 'Перейдите на следующий этап',
      description: 'Завершите этап, нажав на кнопку “Перейти\u00A0к\u00A0{{ stage }}“',
      buttonText: 'Перейти к {{ stage }}',
      // map current stage to next stage
      [StageType.creation]: 'Подтверждению',
      [StageType.confirmation]: 'Оплате',
      [StageType.payment]: 'Отгрузке',
      [StageType.shipment]: 'Перевозке',
      [StageType.transportation]: 'Завершению\u00A0сделки',
      [StageType.finalization]: '',
    },
    tradeStageFilter: {
      title: 'Фильтрация сделок по статусам',
      all: 'Все сделки',
    },
    createTrade: {
      title: 'Создание сделки',
      fields: {
        supplier: 'Поставщик',
        customer: 'Покупатель',
        legalCompanyFrom: 'Юридическое лицо (от кого покупаем)',
        legalCompanyTo: 'Юридическое лицо (от кого продаем)',
        productType: 'Вид товара',
        product: 'Товар',
        chemicalWeight: 'Вес химический',
        ligatureWeight: 'Вес лигатурный',
        amount: 'Сумма сделки покупателя',
        fixingDate: 'Дата фиксинга',
        sellPrice: 'Продажа (за единицу)',
        sellPriceTotal: 'Общая стоимость продажи',
        buyPrice: 'Покупка (за единицу)',
        buyPriceTotal: 'Общая стоимость покупки',
        supplierSpec: 'Документ со спецификацией товаров',
        totalСost: 'Общая стоимость',
        unitPrice: 'Цена за единицу',
      },
    },
    messages: {
      createTradeSuccess: 'Сделка успешно создана',
      createTradeError: 'Произошла ошибка при создании сделки',
      fetchTradeInfoError: 'Ошибка загрузки информации по сделке',
    },
  },
  zh: {
    tradeName: '交易号 {{tradeId}}',
    trades: '交易',
    tabs: {
      actions: {
        searchLine: '查找文档',
        roleMenu: '按角色过滤',
        docsArchiveLink: '下载存档',
      },
      currentStep: {
        title: '当前步骤',
      },
      info: {
        title: '信息',
        fields: {
          transitCountryForManager: '过境国',
          transitCountryForOther: '目的国',
          transitLogisticCompany: '过境国的物流人员',
          destinationLogisticCompany: '目的国的物流人员',
          trackingLink: '追踪货物',
        },
      },
      documents: {
        title: '文件',
        uploadButton: '上传文件',
        emptyListText: '尚未上传任何文件',
        filteredEmptyListText: '根据您的搜索或过滤，未搜到任何结果。更改您的请求并重试。',
        docTypeLabel: '文件类型',
        docTypePlaceholder: '选择文件类型',
        uploadModalTitle: '上传交易文件',
      },
      specification: {
        title: '规格',
        uploadButton: '上传规格',
        emptyListText: '尚未上传规格',
        docTypeLabel: '规格类型',
        docTypePlaceholder: '选择规格类型',
        uploadModalTitle: '规格上传中',
        uploadError: '规格上传错误',
        uploadSuccess: '规格上传成功',
        updateError: '规格更新出错',
        updateSuccess: '规格更新成功',
      },
      updates: {
        title: '更新',
        emptyListText: '无更新',
      },
    },
    transfer: {
      title: '转到下一阶段',
      description: '单击 “转到\u00A0{{ stage }}” 按钮完成该阶段',
      buttonText: '转到 {{ stage }}',
      // map current stage to next stage
      [StageType.creation]: '确认',
      [StageType.confirmation]: '支付',
      [StageType.payment]: '装运',
      [StageType.shipment]: '搬运',
      [StageType.transportation]: '完成交易',
      [StageType.finalization]: '',
    },
    tradeStageFilter: {
      title: '按状态过滤交易',
      all: '所有交易',
    },
    createTrade: {
      title: '交易创建中',
      fields: {
        supplier: '供应商',
        customer: '买方',
        legalCompanyFrom: '法人实体（我们代表其购买）',
        legalCompanyTo: '法人实体（我们代表其销售）',
        productType: '货物种类',
        product: '货物',
        chemicalWeight: '化学重量',
        ligatureWeight: '结构重量',
        amount: '买家交易金额',
        fixingDate: '拍板成交日期',
        sellPrice: '销售（单位）',
        sellPriceTotal: '总售价',
        buyPrice: '购买（单位）',
        buyPriceTotal: '购买总价',
        supplierSpec: '货物规格文件',
        totalСost: '总额',
        unitPrice: '单价',
      },
    },
    messages: {
      createTradeSuccess: '交易已成功创建',
      createTradeError: '创建交易时出错',
      fetchTradeInfoError: '交易信息加载错误',
    },
  },
};
