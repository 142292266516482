import { Input } from 'antd';
import { SearchProps } from 'antd/es/input';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';

import s from './SearchBar.module.scss';

type SearchBarProps = SearchProps & {
  className?: string;
  fullWidth?: boolean;
};

const SearchBar = ({ className, fullWidth = false, ...props }: SearchBarProps): React.ReactElement<SearchBarProps> => {
  const { t } = useTypedTranslation('shared');

  return (
    <Input.Search
      className={cn(s['search-bar'], fullWidth && s['search-bar_fullWidth'], className)}
      placeholder={t('placeholders.search')}
      {...props}
      allowClear
    />
  );
};

export default SearchBar;
