import { computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { NewMessageCounter } from '@/entities/chat';
import { BaseMessageModel } from '@/entities/chat/model/BaseMessageModel';
import { LocalStore, ValueModel } from '@/shared/model';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { ChatInfo, ChatType, IBaseThread, ThreadServer, ThreadType, TradeInfo } from '../../types';

export type BaseThreadModelParams = {
  id: number;
  type: ThreadType;
  pinned: boolean;
  createdDate: Date;
  name: TranslationString;
  chatInfo: ChatInfo;
  tradeInfo: Nullable<TradeInfo>;
  newMessageCount: number;
  lastMessage: Nullable<BaseMessageModel>;
  lastReadMessageId: Nullable<string>;
};

export class BaseThreadModel extends LocalStore implements IBaseThread {
  readonly id: number;
  readonly type: ThreadType;
  readonly pinned: boolean;
  readonly createdDate: Date;
  readonly name: TranslationString;
  readonly newMessageCounter: NewMessageCounter;
  readonly lastMessageModel: ValueModel<Nullable<BaseMessageModel>>;
  readonly lastReadMessageId: Nullable<string>;

  readonly tradeInfo: Nullable<TradeInfo>;
  readonly chatInfo: ChatInfo;

  constructor({
    id,
    type,
    pinned,
    createdDate,
    name,
    chatInfo,
    tradeInfo,
    newMessageCount,
    lastMessage,
    lastReadMessageId,
  }: BaseThreadModelParams) {
    super();

    this.id = id;
    this.type = type;
    this.pinned = pinned;
    this.createdDate = createdDate;
    this.name = name;
    this.chatInfo = chatInfo;
    this.tradeInfo = tradeInfo;
    this.newMessageCounter = new NewMessageCounter(newMessageCount);
    this.lastMessageModel = new ValueModel<Nullable<BaseMessageModel>>(lastMessage);
    this.lastReadMessageId = lastReadMessageId;

    makeObservable(this, {
      lastMessage: computed,
      lastMessageDate: computed,
    });
  }

  get lastMessage(): Nullable<BaseMessageModel> {
    return this.lastMessageModel.value;
  }

  get lastMessageDate(): Nullable<Date> {
    return this.lastMessage?.createdDate ?? null;
  }

  static paramsFromServer(server: ThreadServer, rootStore: IRootStore): BaseThreadModelParams {
    const tradeId = server.trade?.id;
    const threadName: TranslationString =
      server.type === ThreadType.trade && tradeId
        ? (t) => t('tradeName', { ns: 'trade', tradeId })
        : (t) => t('threadPublic', { ns: 'chats' });

    const chatName: TranslationString =
      server.chat.type === ChatType.public ? (t) => t('chatPublic', { ns: 'chats' }) : server.chat.name;

    return {
      id: server.id,
      type: server.type,
      pinned: server.pinned,
      createdDate: new Date(server.created_at),
      name: server.type === ThreadType.proxy ? chatName : threadName,
      newMessageCount: server.new_message_count,
      lastReadMessageId: server.last_read_message_id,
      lastMessage: server.last_message
        ? BaseMessageModel.fromJsonParams({
            server: server.last_message,
            rootStore,
          })
        : null,
      tradeInfo: server.trade && {
        id: server.trade.id,
        stage: server.trade.key,
      },
      chatInfo: {
        id: server.chat.id,
        type: server.chat.type,
        name: server.chat.name,
        userType: server.chat.user_type,
      },
    };
  }

  static fromServer(server: ThreadServer, rootStore: IRootStore): BaseThreadModel {
    return new BaseThreadModel(this.paramsFromServer(server, rootStore));
  }
}
