import { UploadFile } from 'antd';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { TranslationString } from '../types/localization';
import { Validator, ValidatorResult } from '../types/validator';
import { Nullable } from '../types/values';

import { renderTranslationString } from './renderTranslation';

const T_OPTIONS = { ns: 'shared' } as const;

export enum FieldError {
  buyPriceUnit = 'buyPriceUnit',
  email = 'email',
  paymentUnit = 'paymentUnit',
  phoneNumber = 'phoneNumber',
  required = 'required',
  sellPriceUnit = 'sellPriceUnit',
  weightUnit = 'weightUnit',
}

export enum AcceptFileFormats {
  pdf = '.pdf',
  xls = '.xls',
  xlsx = '.xlsx',
  docx = '.docx',
  doc = '.doc',
  rtf = '.rtf',
  dot = '.dot',
  dotx = '.dotx',
  jpg = '.jpg',
  jpeg = '.jpeg',
  png = '.png',
}

export const ACCEPT_FILE_FORMATS: AcceptFileFormats[] = Object.values(AcceptFileFormats);

export const phoneValidator: Validator<string> = (value: string): ValidatorResult => {
  if (!value) {
    return null;
  }

  if (!isValidPhoneNumber(value)) {
    return (t) => t(`validationErrors.${FieldError.phoneNumber}`, T_OPTIONS);
  }

  return null;
};

export const emailValidator: Validator<string> = (value: string): ValidatorResult => {
  if (!value) {
    return null;
  }

  if (
    !value.match(
      /^[-!#$%&'*+=?^`{}|~\w]+(?:\.[-!#$%&'*+=?^`{}|~\w]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,6})/gm,
    )
  ) {
    return (t) => t(`validationErrors.${FieldError.email}`, T_OPTIONS);
  }

  return null;
};

export const emptyValueValidator =
  (
    error: TranslationString = FieldError.required,
  ): Validator<Nullable<string | number | Date | Array<unknown> | Set<unknown>>> =>
  (value): ValidatorResult => {
    if (
      !value ||
      (typeof value === 'string' && !value.trim()) ||
      (Array.isArray(value) && !value.length) ||
      (value instanceof Set && !value.size)
    ) {
      return (t) =>
        error === FieldError.required ? t(`validationErrors.${error}`, T_OPTIONS) : renderTranslationString(error, t);
    }

    return null;
  };

const MAX_FILE_SIZE = 100 * 1024 * 1024;

export const fileSizeValidator: Validator<UploadFile[]> = (files): ValidatorResult => {
  if (!files.length) {
    return null;
  }

  if (files.some(({ originFileObj }) => originFileObj && originFileObj.size > MAX_FILE_SIZE)) {
    return (t) => t('validationErrors.fileSize', { ...T_OPTIONS, fileSize: MAX_FILE_SIZE / 1024 / 1024 });
  }

  return null;
};

export const fileFomatValidator =
  (formats: string[] = ACCEPT_FILE_FORMATS): Validator<UploadFile[]> =>
  (files): ValidatorResult => {
    if (!files.length) {
      return null;
    }

    if (files.some(({ name }) => !formats.some((format) => name.toLowerCase().endsWith(format)))) {
      return (t) => t('validationErrors.fileFormat', T_OPTIONS);
    }

    return null;
  };

export const stringLengthValidator =
  (length: number): Validator<string> =>
  (value): ValidatorResult => {
    if (!value) {
      return null;
    }

    if (value.length > length) {
      return (t) => t('validationErrors.stringLength', { ...T_OPTIONS, length });
    }

    return null;
  };

export const numberLimitValidator =
  ({ min, max, unit }: Partial<{ min: number; max: number; unit: string }>): Validator<string | number> =>
  (value): ValidatorResult => {
    if (!value) {
      return null;
    }

    const isNullableMin = min === undefined;
    const isNullableMax = max === undefined;

    if (isNullableMin && isNullableMax) {
      return null;
    }

    const valueAsNumber = Number(value);

    if (isNaN(valueAsNumber)) {
      return numberValidator(value);
    }

    if (!isNullableMin && !isNullableMax && (valueAsNumber < min || valueAsNumber > max)) {
      return (t) => t('validationErrors.numberLimit', { ...T_OPTIONS, min, max, unit });
    }

    if (!isNullableMin && valueAsNumber < min) {
      return (t) => t('validationErrors.numberLimitByMin', { ...T_OPTIONS, min, unit });
    }

    if (!isNullableMax && valueAsNumber > max) {
      return (t) => t('validationErrors.numberLimitByMax', { ...T_OPTIONS, max, unit });
    }

    return null;
  };

export const numberValidator: Validator<string | number> = (value): ValidatorResult => {
  if (!value) {
    return null;
  }

  const valueAsNumber = Number(value);

  if (isNaN(valueAsNumber) || valueAsNumber === 0) {
    return (t) => t('validationErrors.invalidNumber', T_OPTIONS);
  }

  return null;
};
