import * as React from 'react';

import { UserType } from '@/entities/user';
import { TransComp } from '@/shared/ui';

import { ChatListClientType } from './types';

const clientTypes: Array<ChatListClientType> = ['all', UserType.customer, UserType.supplier];

const T_OPTIONS = { ns: 'chats' } as const;

export const clientTypeOptions: Array<{ value: ChatListClientType; label: React.ReactNode }> = clientTypes.map(
  (value) => ({
    value,
    label: <TransComp>{(t) => t(`header.clients.${value}`, T_OPTIONS)}</TransComp>,
  }),
);
