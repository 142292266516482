import { action, makeObservable, reaction } from 'mobx';

import { IRootStore } from '@/app/store';
import { BidModel } from '@/entities/bid';
import { ProductType } from '@/entities/dictionary';
import { MessageType } from '@/entities/message';
import { ICreationStage } from '@/entities/stage';
import { apiStore, apiUrls } from '@/shared/api';
import { routerUrls } from '@/shared/config/routes';
import { ToggleModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { TradeWorkflowResponse } from '../../types';
import { CreateTradeModel } from '../CreateTradeModel';

import { customerFields, requiredFields, supplierFields } from './config';

type Params = {
  rootStore: IRootStore;
  bidId: Nullable<number>;
  data: ICreationStage;
};

const T_OPTIONS = { ns: 'trade' } as const;

export class CreateTradeStore extends CreateTradeModel {
  readonly modalState = new ToggleModel();

  private readonly _bidId: Nullable<number>;

  private _createTradeRequest = apiStore.createRequest<TradeWorkflowResponse>({
    method: 'POST',
    url: apiUrls.trade.create,
  });

  constructor({ bidId, ...params }: Params) {
    super(params);

    this._bidId = bidId;

    makeObservable(this, {
      closeModal: action.bound,
      resetForm: action.bound,
      submitModal: action.bound,
    });

    this.addReactions([
      // При изменении полей "Поставщика" чистим ошибки полей "Покупателя"
      reaction(
        () => supplierFields.map((field) => this[field].value),
        () => customerFields.forEach((field) => this[field].resetError()),
      ),
      // При изменении полей "Покупателя" чистим ошибки полей "Поставщика"
      reaction(
        () => customerFields.map((field) => this[field].value),
        () => supplierFields.forEach((field) => this[field].resetError()),
      ),
    ]);
  }

  resetForm() {
    this.formFields.forEach((field) => {
      if (field !== 'buyPriceUnit' && field !== 'sellPriceUnit') {
        this[field].reset();
      }
    });
  }

  closeModal() {
    if (!this.requestStage.isLoading) {
      this.resetForm();
      this.modalState.close();
    }
  }

  private _validateModal = () => {
    const supplierSelected = this.supplier.value !== null;
    const customerSelected = this.customer.value !== null;

    requiredFields.forEach((field) => {
      this[field].validate();
    });

    if (this.productTypeHasProducts) {
      this.product.validate();
      this.ligatureWeight.validate();
    }

    if (supplierSelected && !customerSelected) {
      supplierFields.forEach((field) => {
        this[field].validate();
      });

      customerFields.some((field) => this[field].value && this[field].validate());

      return;
    }

    if (customerSelected && !supplierSelected) {
      customerFields.forEach((field) => {
        this[field].validate();
      });

      supplierFields.some((field) => this[field].value && this[field].validate());

      return;
    }

    const supplierErrors = supplierFields.some((field) => this[field].validate());
    const customerErrors = customerFields.some((field) => this[field].validate());

    if (customerErrors && !supplierErrors) {
      customerFields.forEach((field) => {
        this[field].resetError();
      });

      customerFields.some((field) => this[field].value && this[field].validate());

      return;
    }

    if (supplierErrors && !customerErrors) {
      supplierFields.forEach((field) => {
        this[field].resetError();
      });

      supplierFields.some((field) => this[field].value && this[field].validate());

      return;
    }
  };

  async submitModal() {
    this._validateModal();

    if (this.isError) {
      this.scrollToErrorField();

      return;
    }

    if (this.requestStage.isLoading) {
      return;
    }

    this.requestStage.loading();

    const response = await this._createTradeRequest.call({
      multipartFormData: true,
      data: {
        ...this.toJson(),
        ...(this._bidId ? { bid_id: this._bidId } : {}),
      },
    });

    if (response.isError) {
      this.requestStage.error();
      this.rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.createTradeError', T_OPTIONS),
      });

      return;
    }

    this.modalState.close();
    this.requestStage.success();
    this.rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.createTradeSuccess', T_OPTIONS),
    });

    this.rootStore.routerStore.navigate(routerUrls.tradeWorkflow.create(String(response.data.id)));
  }

  static fromDefaultParams({ rootStore }: Pick<Params, 'rootStore'>): CreateTradeStore {
    return new CreateTradeStore({
      rootStore,
      bidId: null,
      data: {
        supplierSpec: [],
        customer: null,
        supplier: null,
        legalCompanyFrom: null,
        legalCompanyTo: null,
        productType: null,
        product: null,
        weight: '',
        weightUnit: null,
        fixingDate: null,
        sellPrice: '',
        sellPriceUnit: null,
        sellPriceTotal: '',
        buyPrice: '',
        buyPriceUnit: null,
        buyPriceTotal: '',
        ligatureWeight: '',
      },
    });
  }

  static fromBidModel<Model extends BidModel>({
    bidModel,
    rootStore,
  }: {
    bidModel: Model;
    rootStore: IRootStore;
  }): CreateTradeStore {
    const { product } = bidModel.bidDictionaries ?? {};
    const productType = rootStore.dictionariesStore.productTypes.list.items.find(
      (item) => item.type === ProductType.metal,
    );

    return new CreateTradeStore({
      rootStore,
      bidId: bidModel.id,
      data: {
        supplierSpec: [],
        supplier: bidModel.seller?.userId ?? null,
        customer: bidModel.buyer?.userId ?? null,
        legalCompanyFrom: null,
        legalCompanyTo: null,
        productType: productType?.value ?? null,
        product: product
          ? {
              id: product.value,
              label: product.label,
            }
          : null,
        weight: String(bidModel.weight),
        ligatureWeight: String(bidModel.ligatureWeight ?? ''),
        weightUnit: bidModel.weightUnitId,
        fixingDate: bidModel.fixingDate,
        sellPrice: '',
        sellPriceUnit: null,
        sellPriceTotal: '',
        buyPrice: '',
        buyPriceUnit: null,
        buyPriceTotal: '',
      },
    });
  }

  destroy(): void {
    super.destroy();
  }
}
