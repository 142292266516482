export const chats = {
  en: {
    header: {
      title: 'Chats',
      clients: {
        all: 'All',
        supplier: 'Suppliers',
        customer: 'Customers',
      },
      mainSearchInput: 'Find a client/trade',
      searchInput: 'Find a manager/trade',
    },
    plugs: {
      selectChat: 'Select a chat to join the discussion',
      emptyChat: 'There are no messages in this chat yet.',
      emptyChatList: 'Сhat list is empty for now.',
      emptyFilteredChatList: 'No chats were found based on filtering or search results, please change your query',
      emptyMessageList: 'Message list is empty for now.',
    },
    chatPublic: 'General questions',
    threadPublic: 'General stream',
    ownMessagePrefix: 'You: ',
    sentTo: 'Sent to',
    generalStreamModal: {
      title: 'Choose where to send your message',
      alertText:
        'A message sent to the deal chat is automatically duplicated in the general stream. To send a message only to the general stream, check the box below',
      checkboxLabel: 'Only in the general stream',
      labelListDeal: 'Select a chat from the list',
      buttonSubmit: 'Submit',
    },
    exceedsTotalFilesSizeModal: {
      title: 'Files size exceeds 100 MB',
      alertText:
        'The message cannot be sent because the total size of the files exceeds 100 MB. Replace the files and send the message again.',
    },
    messageInput: {
      placeholder: 'Write your message',
      sendButton: 'Send',
      uploadTooltip: 'Upload files up to 100 MB in total',
      sendTooltip: 'Press Enter to send, Shift+Enter to go to a new line',
    },
    chatsButtons: {
      manager: 'Go to trade chat',
      supplier: 'Chat with supplier',
      customer: 'Chat with customer',
    },
    messages: {
      loadChatListError: 'Failed to load chat list',
      loadMessageListError: 'Failed to load message list',
      sendMessageError: 'There was an error sending your message',
      sendMessageSuccess: 'Message sent successfully',
      deleteMessageSuccess: 'Message deleted successfully',
      deleteMessageError: 'Failed to delete message',
    },
  },
  ru: {
    header: {
      title: 'Чаты',
      clients: {
        all: 'Все',
        supplier: 'Поставщики',
        customer: 'Покупатели',
      },
      mainSearchInput: 'Найти клиента/сделку',
      searchInput: 'Найти менеджера/сделку',
    },
    plugs: {
      selectChat: 'Выберите чат, чтобы присоедениться к обсуждению',
      emptyChat: 'В этом чате пока нет сообщений',
      emptyChatList: 'Список чатов пока пуст',
      emptyFilteredChatList: 'По результатам фильтрации или поиска не было найдено чатов, поменяйте запрос',
      emptyMessageList: 'Список сообщений пока пуст',
    },
    chatPublic: 'Общие вопросы',
    threadPublic: 'Общий Стрим',
    ownMessagePrefix: 'Вы: ',
    sentTo: 'Отправлено в',
    generalStreamModal: {
      title: 'Выберите, куда отправить ваше сообщение',
      alertText:
        'Сообщение, отправленное в чат сделки, автоматические дублируется в общем стриме. Чтобы отправить сообщение только в общий стрим, отметьте галочку ниже',
      checkboxLabel: 'Только в общий стрим',
      labelListDeal: 'Выберите чат из списка',
      buttonSubmit: 'Отправить',
    },
    exceedsTotalFilesSizeModal: {
      title: 'Размер файлов превышает 100 MB',
      alertText:
        'Нельзя отправить сообщение, так как общий размер файлов превышает 100 MB. Замените файлы и отправьте сообщение снова',
    },
    messageInput: {
      placeholder: 'Напишите ваше сообщение',
      sendButton: 'Отправить',
      uploadTooltip: 'Загрузите файлы общим объемом до 100 MB',
      sendTooltip: 'Нажмите Enter чтобы отправить, Shift+Enter чтобы перейти на новую строку',
    },
    chatsButtons: {
      manager: 'Перейти в чат по сделке',
      supplier: 'Чат с поставщиком',
      customer: 'Чат с покупателем',
    },
    messages: {
      loadChatListError: 'Не удалось загрузить список чатов',
      loadMessageListError: 'Не удалось загрузить список сообщений',
      sendMessageError: 'Произошла ошибка при отправке сообщения',
      sendMessageSuccess: 'Сообщение успешно отправлено',
      deleteMessageSuccess: 'Сообщение успешно удалено',
      deleteMessageError: 'Не удалось удалить сообщение',
      editMessageSuccess: 'Сообщение успешно отредактировано',
      editMessageError: 'Не удалось отредактировать сообщение',
    },
  },
  zh: {
    header: {
      title: '聊天',
      clients: {
        all: '所有',
        supplier: '供应商',
        customer: '买方',
      },
      mainSearchInput: '寻找客户/交易',
      searchInput: '寻找经理/交易',
    },
    plugs: {
      selectChat: '选择一个聊天室加入讨论',
      emptyChat: '此聊天中还没有消息',
      emptyChatList: '聊天列表暂时是空的',
      emptyFilteredChatList: '根据筛选或搜索结果未找到聊天记录，请更改您的查询',
      // emptyMessageList: 'Список сообщений пока пуст',
    },
    chatPublic: '共同问题',
    threadPublic: '共同直播',
    ownMessagePrefix: '您：',
    sentTo: '发送至',
    generalStreamModal: {
      title: '请选择将您的信息发送至哪里',
      alertText: '发送到交易聊天的信息会自动复制到普通信息流中。要只向共享信息流发送信息，请勾选以下复选框',
      checkboxLabel: '仅发送至主流',
      labelListDeal: '从列表中选择聊天',
      buttonSubmit: '发送',
    },
    exceedsTotalFilesSizeModal: {
      title: '文件大小超过 100 MB',
      alertText: '由于文件总大小超过 100 MB，您无法发送信息。替换文件并再次发送信息',
      buttonSubmit: '返回',
    },
    messageInput: {
      placeholder: '请留言',
      sendButton: '发送',
      uploadTooltip: '上传总容量高达100 MB的文件',
      sendTooltip: '按 Enter 发送，按 Shift+Enter 移至新行',
    },
    chatsButtons: {
      manager: '前往交易聊天',
      supplier: '与供应商联系',
      customer: '与客户聊天',
    },
    messages: {
      loadChatListError: '加载聊天列表失败',
      loadMessageListError: '加载信息列表失败',
      sendMessageError: '发送消息时发生错误',
      sendMessageSuccess: '消息已成功发送',
      deleteMessageSuccess: '消息已成功删除',
      deleteMessageError: '无法删除消息',
      editMessageSuccess: '消息编辑成功',
      editMessageError: '消息编辑失败',
    },
  },
};
