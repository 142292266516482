import { CommentOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { ClientType } from '@/entities/user';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { NavigateChatsHandler } from '../../model';
import { ChatCoords } from '../../types';

import s from './ChatsButtons.module.scss';

type ChatsButtonsProps = {
  className?: string;
  chats: Array<ChatCoords>;
  goToChat: NavigateChatsHandler;
  close?: VoidFunction;
};

const ChatsButtons: React.FC<ChatsButtonsProps> = ({ className, chats, goToChat, close }) => {
  return (
    chats.length > 0 && (
      <Flex className={cn(s.chats, className)} gap={8} wrap="wrap">
        {chats.map((chat) => {
          if (chat.userType === null) {
            return null;
          }

          return (
            <ApproveButton
              className={s.chats_button}
              key={`${chat.chatId}-${chat.threadId}`}
              icon={<CommentOutlined />}
              text={(t) => t(`chatsButtons.${chat.userType as ClientType}`, { ns: 'chats' })}
              size="middle"
              onClick={() => {
                goToChat({ chatId: chat.chatId, threadId: chat.threadId });
                close?.();
              }}
            />
          );
        })}
      </Flex>
    )
  );
};

export default React.memo(ChatsButtons);
