import { action, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { BaseMessageModel, ChatsQueryParams, IChat } from '@/entities/chat';
import { TradeInfoPopoverModel } from '@/entities/trade';
import { CreatedMessageWsBody, DeletedMessageWsBody, EditedMessageWsBody, ReadMessagesWsBody } from '@/entities/ws';
import { QueryParamsModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { IThread, ThreadServer } from '../../types';
import { BaseChatListModelParams } from '../BaseChatListModel';
import { BaseThreadModel, BaseThreadModelParams } from '../BaseThreadModel';
import { MessageInputModel } from '../MessageInputModel';
import { MessageListModel } from '../MessageListModel';

type JsonParams = BaseChatListModelParams & {
  server: ThreadServer;
  chat: IChat;
};

type ThreadModelParams = BaseThreadModelParams & {
  queryParams: QueryParamsModel<ChatsQueryParams>;
  rootStore: IRootStore;
};

export class ThreadModel extends BaseThreadModel implements IThread {
  readonly messages: MessageListModel;

  readonly messageInput: MessageInputModel;

  readonly tradeInfoPopoverModel: Nullable<TradeInfoPopoverModel>;

  private _rootStore: IRootStore;

  constructor({ rootStore, queryParams, ...params }: ThreadModelParams) {
    super(params);

    this.messages = new MessageListModel({
      thread: this,
      rootStore,
      queryParams,
    });

    this.messageInput = new MessageInputModel({
      threadModel: this,
      rootStore,
    });

    this.tradeInfoPopoverModel =
      params.tradeInfo &&
      new TradeInfoPopoverModel({
        tradeId: params.tradeInfo.id,
        rootStore,
        clientType: params.chatInfo.userType,
      });

    this._rootStore = rootStore;

    makeObservable(this, {
      handleCreatedMessageWsEvent: action,
      handleReadMessagesWsEvent: action,
      handleEditedMessageWsEvent: action,
      handleDeletedMessageWsEvent: action,
    });
  }

  handleCreatedMessageWsEvent(body: CreatedMessageWsBody): void {
    this.lastMessageModel.change(
      BaseMessageModel.fromJsonParams({
        server: { ...body, user_id: body.user.id },
        rootStore: this._rootStore,
      }),
    );

    /*
     * Обновляем кол-во новых сообщений
     * */
    this.newMessageCounter.change(body.target_thread_new_message_count);

    this.messages.handleCreatedMessageWsEvent(body);
  }

  handleReadMessagesWsEvent(body: ReadMessagesWsBody): void {
    /*
     * Обновляем счетчик новых сообщений
     * */
    this.newMessageCounter.change(body.thread_new_message_count);

    this.messages.handleReadMessagesWsEvent(body);
  }

  handleEditedMessageWsEvent(body: EditedMessageWsBody): void {
    const lastMessage = this.lastMessageModel.value;

    if (lastMessage && lastMessage.id === body.id) {
      this.lastMessageModel.change(
        BaseMessageModel.fromJsonParams({
          server: { ...body, user_id: body.user.id },
          rootStore: this._rootStore,
        }),
      );
    }

    this.messages.handleEditedMessageWsEvent(body);
  }

  handleDeletedMessageWsEvent(body: DeletedMessageWsBody): void {
    this.messages.handleDeletedMessageWsEvent(body);
  }

  static fromJson({ server, ...params }: JsonParams): ThreadModel {
    return new ThreadModel({
      ...BaseThreadModel.paramsFromServer(server, params.rootStore),
      rootStore: params.rootStore,
      queryParams: params.queryParams,
    });
  }
}
