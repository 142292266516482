import { action, makeObservable } from 'mobx';

import { DictionaryField } from '@/entities/dictionary';
import { DocumentFileType, FileModel, UploadArbitraryDocsModel, UploadOptionalFileModel } from '@/entities/file';
import { OptionFieldsAction, StageType } from '@/entities/stage/types';
import { TradeWorkflowResponse } from '@/entities/trade';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { stringLengthValidator } from '@/shared/utils/validators';

import { AttachingTrackingLinkModel } from '../../AttachingTrackingLinkModel';
import { SelectOptionalOptionModel } from '../../SelectOptionalOptionModel/SelectOptionalOptionModel';

import { optionalFieldsServer } from './config';
import { IOptionalFieldsModel } from './types';

type Params = {
  specification: UploadOptionalFileModel;
  arbitraryDocs: UploadArbitraryDocsModel;
  transitCountryLogisticCompany: SelectOptionalOptionModel<number>;
  destinationCountryLogisticCompany: SelectOptionalOptionModel<number>;
  flightTrackingLink: AttachingTrackingLinkModel;
  warrantyLoa: UploadOptionalFileModel;
  paymentTaxesCertificate: UploadOptionalFileModel;
  authorizationLetter: UploadOptionalFileModel;
  photo: UploadOptionalFileModel;
  tradeWorkflowStore: ITradeWorkflowStore;
};

export class OptionalFieldsModel implements IOptionalFieldsModel {
  readonly specification: UploadOptionalFileModel;

  readonly arbitraryDocs: UploadArbitraryDocsModel;

  readonly transitCountryLogisticCompany: SelectOptionalOptionModel<number>;

  readonly destinationCountryLogisticCompany: SelectOptionalOptionModel<number>;

  readonly flightTrackingLink: AttachingTrackingLinkModel;

  readonly warrantyLoa: UploadOptionalFileModel;

  readonly paymentTaxesCertificate: UploadOptionalFileModel;

  readonly authorizationLetter: UploadOptionalFileModel;

  readonly photo: UploadOptionalFileModel;

  readonly tradeWorkflowStore: ITradeWorkflowStore;

  constructor({
    specification,
    arbitraryDocs,
    transitCountryLogisticCompany,
    destinationCountryLogisticCompany,
    flightTrackingLink,
    warrantyLoa,
    paymentTaxesCertificate,
    authorizationLetter,
    photo,
    tradeWorkflowStore,
  }: Params) {
    this.specification = specification;
    this.arbitraryDocs = arbitraryDocs;
    this.transitCountryLogisticCompany = transitCountryLogisticCompany;
    this.destinationCountryLogisticCompany = destinationCountryLogisticCompany;
    this.flightTrackingLink = flightTrackingLink;
    this.warrantyLoa = warrantyLoa;
    this.paymentTaxesCertificate = paymentTaxesCertificate;
    this.authorizationLetter = authorizationLetter;
    this.photo = photo;
    this.tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      reloadOptionalFields: action.bound,
    });
  }

  async reloadOptionalFields(): Promise<void> {
    if (this.tradeWorkflowStore.loadingStage.isLoading) {
      return;
    }

    this.tradeWorkflowStore.loadingStage.loading();

    const response = await this.tradeWorkflowStore.tradeDetailsRequest.call();

    if (response.isError) {
      this.tradeWorkflowStore.loadingStage.error();

      return;
    }

    this._updateOptionalFields(response.data);

    this.tradeWorkflowStore.updateTradeWorkflow(response.data, true);

    this.tradeWorkflowStore.loadingStage.success();
  }

  private _updateOptionalFields(response: TradeWorkflowResponse): void {
    const server = { ...response, ...response.stage.data };

    optionalFieldsServer.forEach((serverName) => {
      if (!server[serverName]) {
        return;
      }

      switch (serverName) {
        case 'arbitrary_documents':
          const arbitraryDocs = FileModel.fileListFromJson(server[serverName]);
          this.arbitraryDocs.onChangeOriginDocs(arbitraryDocs);

          break;

        case 'authorization_letter':
          const authorizationLetter = FileModel.fileListFromJson(server[serverName]);
          this.authorizationLetter.change(authorizationLetter);
          this.authorizationLetter.changeInitializedByValue(true);

          break;

        case 'destination_logistic_company':
          // @ts-ignore
          this.destinationCountryLogisticCompany.change(server[serverName].id);
          this.destinationCountryLogisticCompany.changeInitializedByValue(true);

          break;

        case 'finalization_photo':
          const finalizationPhoto = FileModel.fileListFromJson(server[serverName]);
          this.photo.change(finalizationPhoto);
          this.photo.changeInitializedByValue(true);

          break;

        case 'indirect_taxes_payment_certificate':
          const paymentTaxesCertificate = FileModel.fileListFromJson(server[serverName]);
          this.paymentTaxesCertificate.change(paymentTaxesCertificate);
          this.paymentTaxesCertificate.changeInitializedByValue(true);

          break;

        case 'supplier_specification':
          const specification = FileModel.fileListFromJson(server[serverName]);
          this.specification.change(specification);
          this.specification.changeInitializedByValue(true);

          break;

        case 'tracking_link':
          // @ts-ignore
          this.flightTrackingLink.change(server[serverName]);
          this.flightTrackingLink.changeInitializedByValue(true);

          break;

        case 'transit_logistic_company':
          // @ts-ignore
          this.transitCountryLogisticCompany.change(server[serverName].id);
          this.transitCountryLogisticCompany.changeInitializedByValue(true);

          break;

        case 'warranty_loa':
          const warrantyLoa = FileModel.fileListFromJson(server[serverName]);
          this.warrantyLoa.change(warrantyLoa);
          this.warrantyLoa.changeInitializedByValue(true);

          break;
      }
    });
  }

  static fromJson({ tradeWorkflowStore }: { tradeWorkflowStore: ITradeWorkflowStore }): OptionalFieldsModel {
    const { tradeInfo, rootStore } = tradeWorkflowStore;
    const { isSupplierRole, isMainRole } = rootStore.userStore;

    const modelDisabled = tradeInfo.tradeDisabled || tradeInfo.stageDisabled;

    const isShipmentStage = tradeInfo.currentStageType === StageType.shipment;
    const isTransportationStage = tradeInfo.currentStageType === StageType.transportation;
    const isFinalizationStage = tradeInfo.currentStageType === StageType.finalization;

    return new OptionalFieldsModel({
      specification: new UploadOptionalFileModel({
        initialValue: tradeInfo.supplierSpecification ? [tradeInfo.supplierSpecification] : [],
        label: (t) => t('optionalFields.specification.label', { ns: 'stage' }),
        tooltip: (t) => t('optionalFields.specification.tooltip', { ns: 'stage' }),
        disabled: modelDisabled,
        docType: DocumentFileType.supplierSpecification,
        tradeWorkflowStore,
      }),

      arbitraryDocs: UploadArbitraryDocsModel.fromJson(tradeWorkflowStore, modelDisabled),

      transitCountryLogisticCompany: new SelectOptionalOptionModel<number>({
        initialValue: tradeInfo.transitLogisticCompany?.value ?? null,
        label: (t) => t('optionalFields.transitCountryLogisticCompany.label', { ns: 'stage' }),
        placeholder: (t) => t('optionalFields.transitCountryLogisticCompany.placeholder', { ns: 'stage' }),
        dictionaryFieldName: DictionaryField.logisticCompanies,
        disabled: modelDisabled || (!isShipmentStage && !isTransportationStage),
        approveAction: OptionFieldsAction.setTransitLogisticCompany,
        tradeWorkflowStore,
      }),

      destinationCountryLogisticCompany: new SelectOptionalOptionModel<number>({
        initialValue: tradeInfo.destinationLogisticCompany?.value ?? null,
        label: (t) => t('optionalFields.destinationCountryLogisticCompany.label', { ns: 'stage' }),
        placeholder: (t) => t('optionalFields.destinationCountryLogisticCompany.placeholder', { ns: 'stage' }),
        dictionaryFieldName: DictionaryField.logisticCompanies,
        disabled: modelDisabled || (!isShipmentStage && !isTransportationStage),
        approveAction: OptionFieldsAction.setDestinationLogisticCompany,
        tradeWorkflowStore,
      }),

      flightTrackingLink: new AttachingTrackingLinkModel({
        initialValue: tradeInfo.trackingLink ?? '',
        label: (t) => t('optionalFields.flightTrackingLink.label', { ns: 'stage' }),
        placeholder: (t) => t('optionalFields.flightTrackingLink.placeholder', { ns: 'stage' }),
        disabled: modelDisabled || (!isShipmentStage && !isTransportationStage),
        validators: [stringLengthValidator(128)],
        tradeWorkflowStore,
      }),

      warrantyLoa: new UploadOptionalFileModel({
        initialValue: tradeInfo.warrantyLoa ? [tradeInfo.warrantyLoa] : [],
        label: (t) => t('optionalFields.warrantyLoa.label', { ns: 'stage' }),
        disabled: modelDisabled || !isShipmentStage,
        docType: DocumentFileType.warrantyLoa,
        tradeWorkflowStore,
      }),

      paymentTaxesCertificate: new UploadOptionalFileModel({
        initialValue: tradeInfo.paymentTaxesCertificate ? [tradeInfo.paymentTaxesCertificate] : [],
        label: (t) => t('optionalFields.paymentTaxesCertificate.label', { ns: 'stage' }),
        tooltip: (t) => t('optionalFields.paymentTaxesCertificate.tooltip', { ns: 'stage' }),
        disabled: modelDisabled || (isMainRole && !isTransportationStage) || (isSupplierRole && !isFinalizationStage),
        docType: DocumentFileType.indirectTaxesPaymentCertificate,
        tradeWorkflowStore,
      }),

      authorizationLetter: new UploadOptionalFileModel({
        initialValue: tradeInfo.authorizationLetter ? [tradeInfo.authorizationLetter] : [],
        label: (t) => t('optionalFields.authorizationLetter.label', { ns: 'stage' }),
        disabled: modelDisabled || !isTransportationStage,
        docType: DocumentFileType.authorizationLetter,
        tradeWorkflowStore,
      }),

      photo: new UploadOptionalFileModel({
        initialValue: tradeInfo.finalizationPhoto ? [tradeInfo.finalizationPhoto] : [],
        label: (t) => t('optionalFields.photo.label', { ns: 'stage' }),
        disabled: modelDisabled,
        docType: DocumentFileType.finalizationPhoto,
        tradeWorkflowStore,
      }),

      tradeWorkflowStore,
    });
  }
}
