import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { action, autorun, computed, makeObservable, reaction } from 'mobx';

import { IDictionariesStore } from '@/entities/dictionary';
import { CustomerPaymentServer, ManagerTransactionAction } from '@/entities/stage/types';
import { TradeWorkflowResponse } from '@/entities/trade';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore, ValueModel } from '@/shared/model';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { InputTextAreaModel } from '@/shared/model/form/InputTextAreaModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable } from '@/shared/types/values';
import { numberParser } from '@/shared/utils';
import { formatDate } from '@/shared/utils/formatDate';
import { FieldError, emptyValueValidator, numberValidator, stringLengthValidator } from '@/shared/utils/validators';

type Params = {
  id: number;
  transactionNumber: InputModel;
  date: DatePickerModel;
  price: InputModel;
  unit: SelectModel;
  priceUSD: InputModel;
  comment: InputTextAreaModel;
  approved: ValueModel<boolean>;
  tradeWorkflowStore: ITradeWorkflowStore;
};

type CustomerPaymentPayload = {
  action: ManagerTransactionAction.addTransaction;
  identifier: string;
  created_at: string;
  amount: number;
  price_unit_id: number;
  amount_usd: number;
  description: string;
};

export class CustomerPaymentModel extends LocalStore {
  readonly id: number;
  readonly transactionNumber: InputModel;
  readonly date: DatePickerModel;
  readonly price: InputModel;
  readonly unit: SelectModel;
  readonly priceUSD: InputModel;
  readonly comment: InputTextAreaModel;

  readonly approved: ValueModel<boolean>;
  readonly loadingStage = new LoadingStageModel();
  readonly tradeWorkflowStore: ITradeWorkflowStore;

  protected readonly _addPaymentRequest: ApiRequest<TradeWorkflowResponse>;

  constructor({ id, transactionNumber, date, price, unit, priceUSD, comment, approved, tradeWorkflowStore }: Params) {
    super();

    this.id = id;
    this.transactionNumber = transactionNumber;
    this.date = date;
    this.price = price;
    this.unit = unit;
    this.priceUSD = priceUSD;
    this.comment = comment;
    this.approved = approved;
    this.tradeWorkflowStore = tradeWorkflowStore;

    this._addPaymentRequest = apiStore.createRequest({
      method: 'POST',
      url: apiUrls.trade.action(tradeWorkflowStore.tradeId),
    });

    makeObservable(this, {
      isApproved: computed,
      isError: computed,
      currencyList: computed,

      validate: action.bound,
      approvePayment: action.bound,
    });

    this.addReactions([
      // Если значений для Валют не было при инициализации,
      // то устанавливаем значения, после получения словарей
      autorun(() => {
        if (this.tradeWorkflowStore.dictionaries.loadingStage.isSuccess) {
          const defaultCurrency =
            this.currencyList.items.find((currency) => currency.label === 'RUB') ?? this.currencyList.items[0];

          !this.unit.value && this.unit.change(defaultCurrency.value);
        }
      }),
      reaction(
        () => ({ priceValue: this.price.value, priceError: this.price.error, unitError: this.unit.error }),
        ({ priceError, unitError }) => {
          if (priceError || !unitError) {
            return;
          }

          this.price.changeError(unitError);
        },
      ),
    ]);
  }

  get isApproved(): boolean {
    return this.approved.value;
  }

  get isError(): boolean {
    return (
      this.transactionNumber.isError ||
      this.price.isError ||
      this.unit.isError ||
      this.comment.isError ||
      this.priceUSD.isError ||
      this.date.isError
    );
  }

  get currencyList(): IDictionariesStore['currencies']['list'] {
    return this.tradeWorkflowStore.dictionaries.currencies.list;
  }

  validate(): void {
    this.transactionNumber.validate();
    this.date.validate();
    this.price.validate();
    this.unit.validate();
    this.priceUSD.validate();
    this.comment.validate();
  }

  private _toJson(): Nullable<CustomerPaymentPayload> {
    const payload: Partial<CustomerPaymentPayload> = {
      action: ManagerTransactionAction.addTransaction,
      ...(this.transactionNumber.value ? { identifier: this.transactionNumber.value } : {}),
      ...(this.date.value ? { created_at: formatDate(this.date.value) } : {}),
      ...(this.price.value ? { amount: Number(this.price.value) } : {}),
      ...(this.unit.value ? { price_unit_id: Number(this.unit.value) } : {}),
      ...(this.priceUSD.value ? { amount_usd: Number(this.priceUSD.value) } : {}),
      ...(this.comment.value ? { description: this.comment.value } : {}),
    };

    const payloadKeys = Object.keys(payload) as (keyof CustomerPaymentPayload)[];

    if (payloadKeys.some((key) => !payload[key])) {
      return null;
    }

    return payload as CustomerPaymentPayload;
  }

  async approvePayment(): Promise<BaseResponse> {
    this.validate();

    if (this.isError) {
      return { isError: true };
    }

    const payload = this._toJson();

    if (!payload) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const response = await this._addPaymentRequest.call({
      multipartFormData: true,
      data: payload,
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    this.approved.change(true);
    this.loadingStage.success();

    return { isError: false };
  }

  static fromJson({
    id,
    payment,
    tradeWorkflowStore,
  }: {
    id: number;
    payment?: CustomerPaymentServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): CustomerPaymentModel {
    return new CustomerPaymentModel({
      id,
      transactionNumber: new InputModel({
        initialValue: payment?.identifier ?? '',
        placeholder: (t) => t('manager.customerPayments.placeholders.number', { ns: 'finalizationStage' }),
        validators: [emptyValueValidator(), stringLengthValidator(16)],
        required: true,
      }),
      date: new DatePickerModel({
        initialValue: payment?.created_at ? new Date(payment.created_at) : null,
        placeholder: (t) => t('manager.customerPayments.placeholders.date', { ns: 'finalizationStage' }),
        validators: [emptyValueValidator()],
        required: true,
      }),
      price: new InputModel({
        initialValue: payment?.amount ? String(payment.amount) : '',
        placeholder: (t) => t('manager.customerPayments.placeholders.price', { ns: 'finalizationStage' }),
        parser: numberParser(),
        validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
        required: true,
      }),
      unit: new SelectModel({
        initialValue: payment?.price_unit.id ?? null,
        validators: [emptyValueValidator(FieldError.paymentUnit)],
        required: true,
      }),
      priceUSD: new InputModel({
        initialValue: payment?.amount_usd ? String(payment.amount_usd) : '',
        placeholder: (t) => t('manager.customerPayments.placeholders.priceUSD', { ns: 'finalizationStage' }),
        parser: numberParser(),
        validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
        required: true,
      }),
      comment: new InputTextAreaModel({
        initialValue: payment?.description ?? '',
        placeholder: (t) => t('manager.customerPayments.placeholders.comment', { ns: 'finalizationStage' }),
        validators: [stringLengthValidator(512)],
        maxRows: 2,
      }),
      approved: new ValueModel<boolean>(Boolean(payment)),
      tradeWorkflowStore,
    });
  }

  destroy(): void {
    super.destroy();
  }
}
