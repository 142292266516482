import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';

import s from './UserStatus.module.scss';

type UserStatusProps = {
  accepted: boolean;
  className?: string;
};

const UserStatus = ({ accepted, className }: UserStatusProps): React.ReactElement<UserStatusProps> => {
  const { t } = useTypedTranslation('user');

  const status = accepted ? t('userStatus.active') : t('userStatus.notActive');

  return (
    <span
      className={cn(
        s['client-status'],
        accepted ? s['client-status_color-accepted'] : s['client-status_color-not-accepted'],
        className,
      )}
    >
      {status}
    </span>
  );
};

export default UserStatus;
