import {
  StageType,
  SupplierPaymentAction,
  SupplierPaymentStageDataServer,
  SupplierPaymentStep,
} from '@/entities/stage/types';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { BaseUploadFileFieldsModel } from '../../fieldsModel/BaseUploadFileFieldsModel';
import { WaitingFieldsWithFileModel } from '../../fieldsModel/WaitingFieldsWithFileModel';

import { mapSupplierStepToNumber } from './config';

type Params = BaseStageModelParams<SupplierPaymentStep> & {
  uploadBillFields: BaseUploadFileFieldsModel<SupplierPaymentStep, SupplierPaymentAction>;
  payBillFields: WaitingFieldsWithFileModel<SupplierPaymentStep>;
};

export class SupplierPaymentStageModel extends BaseStageModel<StageType.payment, SupplierPaymentStep> {
  readonly uploadBillFields: BaseUploadFileFieldsModel<SupplierPaymentStep, SupplierPaymentAction>;
  readonly payBillFields: WaitingFieldsWithFileModel<SupplierPaymentStep>;

  constructor({ uploadBillFields, payBillFields, ...params }: Params) {
    super({
      type: StageType.payment,
      ...params,
    });

    this.uploadBillFields = uploadBillFields;
    this.payBillFields = payBillFields;
  }

  get isPaidBill(): boolean {
    return this.payBillFields.file.isUploaded;
  }

  get currentStep(): number {
    return mapSupplierStepToNumber(this.step);
  }

  get steps(): SupplierPaymentStep[] {
    return [SupplierPaymentStep.uploadBill, SupplierPaymentStep.payBill];
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: SupplierPaymentStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): SupplierPaymentStageModel {
    return new SupplierPaymentStageModel({
      uploadBillFields: BaseUploadFileFieldsModel.fromJson({
        data: dataServer.steps[SupplierPaymentStep.uploadBill],
        step: SupplierPaymentStep.uploadBill,
        approveAction: SupplierPaymentStep.uploadBill,
        tradeWorkflowStore,
      }),
      payBillFields: WaitingFieldsWithFileModel.fromJson({
        data: dataServer.steps[SupplierPaymentStep.payBill],
        step: SupplierPaymentStep.payBill,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
