import { Drawer, DrawerProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';
import { CustomButton } from '../form/CustomButton';

import s from './CustomDrawer.module.scss';

type ButtonProps = {
  disabled?: boolean;
  loading?: boolean;
};

type CustomDrawerProps = Omit<DrawerProps, 'title'> &
  Partial<{
    title: TranslationNode;
    okText: TranslationNode;
    cancelText: TranslationNode;
    onOk: VoidFunction;
    onCancel: VoidFunction;
    closeButtonProps: ButtonProps;
    cancelButtonProps: ButtonProps;
    okButtonProps: ButtonProps;
  }>;

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  className,
  rootClassName,
  title,
  cancelText = (t) => t('buttons.cancel', { ns: 'shared' }),
  okText = (t) => t('buttons.create', { ns: 'shared' }),
  closeButtonProps,
  cancelButtonProps,
  okButtonProps,
  onClose,
  onCancel,
  onOk,
  footer,
  ...props
}) => {
  const handleCancel = React.useCallback(() => {
    if (!cancelButtonProps?.disabled && !cancelButtonProps?.loading && onCancel) {
      onCancel();
    }
  }, [onCancel, cancelButtonProps?.disabled, cancelButtonProps?.loading]);

  const handleClose = React.useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) => {
      if (!closeButtonProps?.disabled && onClose) {
        onClose(e);
      }
    },
    [onClose, closeButtonProps?.disabled],
  );

  const renderFooter = React.useMemo(() => {
    return (
      footer ?? (
        <>
          <CustomButton block onClick={onCancel} {...cancelButtonProps}>
            {cancelText}
          </CustomButton>
          <CustomButton type="primary" block onClick={onOk} {...okButtonProps}>
            {okText}
          </CustomButton>
        </>
      )
    );
  }, [footer, onCancel, onOk, cancelButtonProps, okButtonProps, cancelText, okText]);

  return (
    <Drawer
      className={cn(s.drawer, className)}
      rootClassName={cn(s.drawer_root, rootClassName)}
      title={<TransComp>{title}</TransComp>}
      onClose={onClose ? handleClose : handleCancel}
      destroyOnClose
      footer={renderFooter}
      {...props}
    />
  );
};

export default CustomDrawer;
