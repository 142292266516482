import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ShipmentType, StageModel, StepComponentProps } from '@/entities/stage';
import { StepDescription } from '@/shared/ui';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { DestinationCountryDocsFieldsModel } from '../../model/fieldsModel/DestinationCountryDocsFieldsModel';
import AcceptanceCertificate from '../AcceptanceCertificate';
import AdditionalAgreement from '../AdditionalAgreement';
import CustomerSpecification from '../CustomerSpecification';
import Invoice from '../Invoice';

const DestinationCountryDocs = <Model extends StageModel>({ model, fieldKey }: StepComponentProps<Model>) => {
  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof DestinationCountryDocsFieldsModel)) {
    return null;
  }

  return (
    <StepDescription>
      {fields.shipmentType === ShipmentType.transit && (
        <Invoice model={fields.exportInvoice} disabled={fields.isDisabled || fields.isLoading} />
      )}
      <Invoice model={fields.customerCommercialInvoice} disabled={fields.isDisabled || fields.isLoading} />
      <CustomerSpecification model={fields.customerSpecification} disabled={fields.isDisabled || fields.isLoading} />
      <AcceptanceCertificate
        model={fields.customerAcceptanceCertificate}
        disabled={fields.isDisabled || fields.isLoading}
      />
      <AdditionalAgreement
        model={fields.customerAdditionalAgreement}
        disabled={fields.isDisabled || fields.isLoading}
      />
      <ApproveButton
        loading={fields.isLoading}
        disabled={fields.isDisabled || fields.approveDisabled}
        onClick={fields.approveStep}
        block
      />
    </StepDescription>
  );
};

export default observer(DestinationCountryDocs);
