import { BaseFileServer } from '@/entities/file';
import { StageType } from '@/entities/stage';
import { ClientType, UserServer, UserType } from '@/entities/user';
import { ApiListResponse } from '@/shared/types/api';
import { Nullable } from '@/shared/types/values';

/**
 * @field public - Публичный чат
 * @field personal - Личный чат
 */
export enum ChatType {
  public = 'public',
  personal = 'personal',
}

/**
 * @field proxy - Проксирование чата
 * @field stream - Общий стрим
 * @field trade - Чат по сделке
 */
export enum ThreadType {
  proxy = 'proxy',
  stream = 'stream',
  trade = 'trade',
}

export type ChatTradeServer = {
  id: number;
  key: StageType;
};

export type ChatServer = {
  id: number;
  type: ChatType;
  name: string;
  new_message_count: number;
  last_message_at: Nullable<string>;
  user_type: Nullable<UserType>;
  created_at: string;
  pinned: boolean;
};

export type ThreadServer = {
  id: number;
  type: ThreadType;
  new_message_count: number;
  last_message: Nullable<BaseMessageServer>;
  last_read_message_id: Nullable<string>;
  trade: Nullable<ChatTradeServer>;
  chat: ChatServer;
  created_at: string;
  pinned: boolean;
};

export type BaseMessageServer = {
  id: string;
  created_at: string;
  user_id: number;
  text: Nullable<string>;
  attachments: Nullable<Array<BaseFileServer>>;
};

export type MessageServer = Omit<BaseMessageServer, 'user_id'> & {
  edited_at: Nullable<string>;
  read_at: Nullable<string>;
  user: UserServer;
  trade: Nullable<ChatTradeServer>;
  chat: ChatServer;
  thread: ThreadServer;
};

export type ChatListReaponse = ApiListResponse<ChatServer>;

export type ThreadListReaponse = ApiListResponse<ThreadServer>;

export type MessageListResponse = ApiListResponse<MessageServer>;

export type ChatListPayload = {
  search?: string;
  user_type?: ClientType;
};

export type ThreadListPayload = {
  search?: string;
  key?: Array<StageType>;
};
