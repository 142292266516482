import { useLocalStore } from '@kts-front/hooks';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useRootStore } from '@/app/store';

import { ChatsStore } from './ChatsStore';

const ChatsStoreContext = React.createContext<ChatsStore | null>(null);

export const ChatsStoreContextProvider: React.FC<React.PropsWithChildren> = observer(({ children }) => {
  const rootStore = useRootStore();
  const [queryParams] = useSearchParams();
  const { store } = useLocalStore(() => new ChatsStore({ queryParams, rootStore }));

  React.useEffect(() => {
    store.subscribe();
  }, []);

  return <ChatsStoreContext.Provider value={store}>{children}</ChatsStoreContext.Provider>;
});

export const useChatsStore = () => {
  const context = React.useContext(ChatsStoreContext);

  if (!context) {
    throw new Error('ChatsStoreContext was not provided');
  }

  return context;
};
