import { DocumentFileType } from '@/entities/file';

export const transportationStage = {
  en: {
    manager: {
      transitCountryCargoArrival: {
        title: 'Arrival of cargo',
        description: 'When the cargo arrives in the transit country, confirm arrival',
      },
      cargoArrival: {
        title: 'Arrival of cargo',
        description: 'When the cargo arrives in the destination country, confirm arrival',
      },
      tradeCloseSupplierDocs: {
        title: 'Upload documents for closing the trade with the supplier',
        descriptions: {
          signedCertificate: 'Upload the statement, signed on both sides',
          additionalAgreement: 'Upload supplementary agreement',
        },
      },
      destinationCountryDocs: {
        title: 'Upload documents for destination country',
        descriptions: {
          exportInvoice: 'Upload invoice for export 4%',
          customerCommercialInvoice: 'Upload the commercial invoice for the customer',
          customerSpecification: 'Upload the specification for the customer on our company letterhead',
          customerAcceptanceCertificate: 'Transfer and acceptance report for the customer',
          customerAdditionalAgreement: 'Supplementary agreement for the customer',
        },
      },
      destinationCountryAwb: {
        title: "Upload the destination country's AWB or enter the flight number",
      },
      transitCountryCargoShipment: {
        title: 'Shipment of cargo',
        description: 'When the shipment leaves the transit country, confirm shipment',
      },
      destinationCountryCargoArrival: {
        title: 'Arrival of cargo',
        description: 'When the cargo arrives in the destination country, confirm arrival',
      },
    },
    customer: {
      cargoArrival: {
        title: 'Waiting for the cargo to arrive',
        description: 'Wait until the manager marks the arrival of the cargo in the destination country',
      },
      destinationCountryDocs: {
        title: 'Wait for the deal documents to load',
        waitDescription: 'Once the documents are uploaded they will appear here',
        approveDescription: 'Documents uploaded',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Invoice',
          [DocumentFileType.customerSpecification]: 'Specification',
          [DocumentFileType.customerAcceptanceCertificate]: 'Transfer and acceptance report signed on one side',
          [DocumentFileType.customerAdditionalAgreement]: 'Supplementary agreement',
        },
      },
      destinationCountryAwb: {
        title: 'Wait for AWB or flight number to load',
      },
      transfer: {
        title: 'The cargo has arrived',
      },
    },
  },
  ru: {
    manager: {
      transitCountryCargoArrival: {
        title: 'Прибытие груза',
        description: 'Когда груз прибудет в страну транзита, подтвердите прибытие',
      },
      cargoArrival: {
        title: 'Прибытие груза',
        description: 'Когда груз прибудет в страну назначения, подтвердите прибытие',
      },
      tradeCloseSupplierDocs: {
        title: 'Загрузите документы для закрытия сделки с поставщиком',
        descriptions: {
          signedCertificate: 'Загрузите акт, подписанный с двух сторон',
          additionalAgreement: 'Загрузите дополнительное соглашение',
        },
      },
      destinationCountryDocs: {
        title: 'Загрузите документы для страны назначения',
        descriptions: {
          exportInvoice: 'Загрузите инвойс для экспорта 4%',
          customerCommercialInvoice: 'Загрузите коммерческий инвойс для покупателя',
          customerSpecification: 'Загрузите спецификацию для покупателя на бланке нашей компании',
          customerAcceptanceCertificate: 'Акт приема-передачи, подписанный с одной стороны',
          customerAdditionalAgreement: 'Дополнительное соглашение для покупателя',
        },
      },
      destinationCountryAwb: {
        title: 'Загрузите AWB страны назначения или введите номер рейса',
      },
      transitCountryCargoShipment: {
        title: 'Отгрузка груза',
        description: 'Когда груз будет отправлен из страны транзита, подтвердите отгрузку',
      },
      destinationCountryCargoArrival: {
        title: 'Прибытие груза',
        description: 'Когда груз прибудет в страну назначения, подтвердите прибытие',
      },
    },
    customer: {
      cargoArrival: {
        title: 'Ожидание прибытия груза',
        description: 'Дождитесь, пока менеджер отметит прибытие груза в страну назначения',
      },
      destinationCountryDocs: {
        title: 'Ожидайте загрузку документов по сделке',
        waitDescription: 'Когда документы будут загружены, они появятся здесь',
        approveDescription: 'Документы загружены',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Инвойс',
          [DocumentFileType.customerSpecification]: 'Спецификация',
          [DocumentFileType.customerAcceptanceCertificate]: 'Акт-приема передачи, подписанный с одной стороны',
          [DocumentFileType.customerAdditionalAgreement]: 'Дополнительное соглашение',
        },
      },
      destinationCountryAwb: {
        title: 'Ожидайте загрузку AWB или номера рейса',
      },
      transfer: {
        title: 'Груз прибыл',
      },
    },
  },
  zh: {
    manager: {
      transitCountryCargoArrival: {
        title: '货物抵达',
        description: '当货物抵达转运国时，确认抵达情况',
      },
      cargoArrival: {
        title: '货物抵达',
        description: '当货物抵达目的地国家时，确认货物到达',
      },
      tradeCloseSupplierDocs: {
        title: '上传文件以结束与供应商的交易',
        descriptions: {
          signedCertificate: '下载双方签字的文件',
          additionalAgreement: '下载附加协议',
        },
      },
      destinationCountryDocs: {
        title: '上传目的国文件',
        descriptions: {
          exportInvoice: '下载出口发票 4%',
          customerCommercialInvoice: '下载买方的商业发票',
          customerSpecification: '下载带有我们公司抬头的买方规格',
          customerAcceptanceCertificate: '一方签署的验收证明书',
          customerAdditionalAgreement: '买方的附加协议',
        },
      },
      destinationCountryAwb: {
        title: '下载目的地国家的AWB或输入航班号',
      },
      transitCountryCargoShipment: {
        title: '装运',
        description: '货件离开过境国后，确认货件',
      },
      destinationCountryCargoArrival: {
        title: '货物抵达',
        description: '当货物抵达目的地国家时，确认货物到达',
      },
    },
    customer: {
      cargoArrival: {
        title: '等待货物到达',
        description: '等待经理标记货物到达目的国',
      },
      destinationCountryDocs: {
        title: '等待交易文件加载',
        waitDescription: '文件上传后，它们将出现在此处',
        approveDescription: '文件已上传',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: '发票',
          [DocumentFileType.customerSpecification]: '规格',
          [DocumentFileType.customerAcceptanceCertificate]: '一方签署的验收证明书 ',
          [DocumentFileType.customerAdditionalAgreement]: '附加协议',
        },
      },
      destinationCountryAwb: {
        title: '期望下载AWB或航班号',
      },
      transfer: {
        title: '货物已抵达',
      },
    },
  },
};
