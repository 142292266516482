import { BaseResponse, ILocalStore } from '@kts-front/types';
import { UploadFile } from 'antd';

import { BaseMessageModel } from '@/entities/chat/model/BaseMessageModel';
import { StageType } from '@/entities/stage';
import { TradeInfoPopoverModel } from '@/entities/trade';
import { ClientType, UserType } from '@/entities/user';
import { ListOffsetModel, LoadingStageModel } from '@/shared/model';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { IChatsStore, MessageInputModel, MessageListModel, NewMessageCounter } from '../model';

import { ChatType, ThreadType } from './server';

export enum MessageMenuKey {
  edit = 'edit',
  delete = 'delete',
}

export type UserInfo = {
  id: number;
  name: string;
  type: UserType;
  ownInfo: boolean;
};

export type TradeInfo = {
  id: number;
  stage: StageType;
};

export type ChatInfo = {
  id: number;
  type: ChatType;
  name: TranslationString;
  userType: Nullable<UserType>;
};

export type ThreadInfo = {
  id: number;
  type: ThreadType;
};

export type ChatCoords = {
  chatId: number;
  threadId: number;
  userType: ClientType | null;
};

/**
 * pinned - Закрепленный ли чат
 * createdDate - Дата создания чата
 * name - Название чата
 * newMessageCount - Количество непрочитанных сообщений
 * lastMessageDate - Дата последнего сообщения
 */

export interface IBaseChat extends ILocalStore {
  id: number;
  pinned: boolean;
  createdDate: Date;
  name: TranslationString;
  newMessageCounter: NewMessageCounter;
  lastMessageDate: Nullable<Date>;
}

/**
 * type - Тип чата (публичный или персональный)
 * pinned - Закрепленный ли чат
 * createdDate - Дата создания чата
 * name - Название чата
 * newMessageCount - Количество непрочитанных сообщений
 * lastMessageDate - Дата последнего сообщения
 * userType - Тип пользователя, с которым общаемся, если общий чат, то будет null
 * chatsStore - Хранилище чатов
 */
export interface IChat extends IBaseChat {
  type: ChatType;
  userType: Nullable<UserType>;
  chatsStore: IChatsStore;
}

/**
 * type - Тип треда (публичный или персональный)
 * pinned - Закрепленный ли тред
 * name - Название треда
 * createdDate - Дата создания треда
 * newMessageCount - Количество непрочитанных сообщений
 * tradeInfo - Информация о сделке, к которому относится тред, если общий стрим, то будет null
 * chatInfo - Информация о родительском чате, к которому относится тред
 * lastMessageInfo - Информация о последнем сообщении
 * lastReadMessageId - ID последнего прочитанного сообщения
 */
export interface IBaseThread extends IBaseChat {
  type: ThreadType;
  tradeInfo: Nullable<TradeInfo>;
  chatInfo: ChatInfo;
  lastMessage: Nullable<BaseMessageModel>;
  lastReadMessageId: Nullable<string>;
}

export interface IThread extends IBaseThread {
  messages: MessageListModel;
  messageInput: MessageInputModel;
  tradeInfoPopoverModel: Nullable<TradeInfoPopoverModel>;
}

/**
 * text - Текст сообщения
 * files - Список файлов, прикрепленных к сообщению
 * createDate - Дата создания сообщения
 * editDate - Дата редактирования сообщения
 * readDate - Дата прочтения сообщения
 * userInfo - Информация о пользователе, кто отправил сообщение
 * tradeInfo - Информация о сделке, к которому относится сообщение, если сообщение отправлено в общий чат, то будет null
 * chatInfo - Информация о родительском чате, куда отправлено сообщение, если сообщение в списке родительского чата, то будет null
 * threadInfo - Информация о треде, куда отправлено сообщение, если сообщение в списке родительского треда, то будет null
 */
export interface IMessage {
  id: string;
  ownMessage: boolean;
  text: Nullable<string>;
  files: Nullable<Array<UploadFile>>;
  createdDate: Date;
  editDate: Nullable<Date>;
  readDate: Nullable<Date>;
  userInfo: UserInfo;
  chatInfo: ChatInfo;
  threadInfo: ThreadInfo;
  tradeInfo: Nullable<TradeInfo>;
}

export type ChatListClientType = ClientType | 'all';

export type ChatsQueryParams = {
  chat_list_search: string;
  client_type: ChatListClientType;
  thread_list_search: string;
  trade_stages: Array<StageType>;
  message_list_search: string;
};

export interface IBaseChatListModel<Model> {
  readonly list: ListOffsetModel<Model, number>;
  readonly initLoadingStage: LoadingStageModel;
  readonly selectedItem: Nullable<Model>;
  readonly isEmptyList: boolean;
  readonly isEmptyFilteredList: boolean;

  selectItem(chatId: number): void;
  unselectItem(): void;
  applyParams(): void;
  resetParams(): void;

  initialLoad(): Promise<BaseResponse>;
  loadMore(): Promise<BaseResponse>;
}

export type CreateMessagePayload = {
  text?: string;
  files?: Array<File>;
};

export type EditMessagePayload = CreateMessagePayload & {
  old_files: Array<number>;
};
