import { AwbStatus } from '../types';

export const stage = {
  en: {
    optionalFields: {
      messages: {
        saveDataSuccess: 'Data saved successfully',
        saveDataError: 'Failed to save data',
        saveLinkSuccess: 'Link saved successfully',
        saveLinkError: 'Failed to save link',
      },
      specification: {
        label: 'Upload supplier specification',
        tooltip: 'Upload specification also on the next stages, in the “Specification” section',
      },
      arbitraryDocs: {
        label: 'Upload arbitrary documents',
        tooltip: 'Upload documents also on the next stages, in the “Documents” section',
        uploadModalTitle: 'Upload arbitrary documents',
      },
      transitCountryLogisticCompany: {
        label: 'Select logistic company in transit country',
        placeholder: 'Select company',
      },
      destinationCountryLogisticCompany: {
        label: 'Select logistic company in destination country',
        placeholder: 'Select company',
      },
      flightTrackingLink: {
        label: 'Flight tracking link',
        placeholder: 'Attach tracking link also on the next stages, in the “Transportation” section',
      },
      warrantyLoa: {
        label: 'Upload warranty LOA',
      },
      paymentTaxesCertificate: {
        label: 'Upload payment taxes certificate',
        tooltip: 'Upload a certificate of payment of indirect taxes',
      },
      authorizationLetter: {
        label: 'Upload authorization letter',
      },
      photo: {
        label: 'Upload photo',
      },
    },
    requiredStep: {
      awbDoc: {
        descriptionFile: 'Upload AWB for transit country and click “Approve”',
        descriptionNumber: 'Enter flight number and click “Approve”',
        [AwbStatus.wait]: 'Once the AWB is loaded or the flight number is entered, the information will appear here',
        [AwbStatus.approveFile]: 'Manager uploaded AWB',
        [AwbStatus.approveNumber]: 'The manager entered the flight number',
        buttonFile: 'AWB',
        buttonNumber: 'Flight number',
        numberPlaceholder: 'Enter number',
      },
    },
    invoiceForm: {
      fileNames: {
        exportInvoice: 'Invoice for export 4%',
        commercialInvoice: 'Commercial invoice',
      },
      date: 'Date',
      country: 'Country of manufacture',
      flightNumber: 'International Air Waybill Number or Flight Number',
      flightNumberPlaceholder: 'Enter number',
      packing: 'Packing',
      incoterms: 'Incoterms',
      signature: 'Authorized signature',
    },
    packing: {
      bag: 'Bag',
      plasticPallet: 'Plastic pallet',
    },
    additionalAgreement: {
      fileName: 'Supplementary agreement',
      date: 'Date',
      signature: 'Authorized signature',
      invoiceNumber: 'Invoice number',
      invoiceNumberPlaceholder: 'Enter number',
      invoiceDate: 'Invoice date',
      debtRepaymentDate: 'Debt repayment date',
    },
    acceptanceCertificate: {
      fileName: 'Transfer and acceptance report',
      date: 'Date',
      signatureJobTitle: 'Job title',
      signature: 'Authorized signature',
      actingUnder: 'Acting under',
      actingUnderPlaceholder: 'Enter document information',
      actingUnderTooltip: 'Enter information about the document under which the signatory is acting',
      customerPersonalId: "Customer's identity document number",
      supplierPersonalId: "Supplier's identity document number",
      personalIdPlaceholder: 'Enter personal id',
      sum: 'Sum',
      sumPlaceholder: 'Enter sum',
    },
    messages: {
      generationSuccess: 'Document generated successfully',
      generationError: 'Document generation error',
      loadFieldsDataError: 'Failed to load document data',
    },
    customerSpecification: {
      title: 'Specification for the buyer',
      signatory: 'Signatory',
      specify: 'Specify',
      year: 'Year',
      uin: 'UIN',
      informationAboutIngots: 'Information about bars',
      countAddIngots_one: 'Added {{count}} ingot',
      countAddIngots_other: 'Added {{count}} ingots',
      ingotNumber: 'Ingot number',
      ingotNumberPlaceholder: 'Number',
      weight: 'Chemical weight',
      weightPlaceholder: 'Weight in g',
      ligatureWeight: 'Ligature weight',
      metalPurity: 'Purity of metal (%)',
      metalPurityPlaceholder: 'Cleanliness in (%)',
      yearOfProduction: 'Year of production',
      action: {
        add: 'Add ingot',
      },
      tooltip:
        'For automatic generation of the specification, data from the commercial invoice is required. For correct generation of the specification, generate the commercial invoice document in advance',
      message: {
        emptyIngotFieldsError: 'Please fill in all fields for each bar to create a specification',
      },
    },
  },
  ru: {
    optionalFields: {
      messages: {
        saveDataSuccess: 'Данные успешно сохранены',
        saveDataError: 'Не удалось сохранить данные',
        saveLinkSuccess: 'Ссылка успешно сохранена',
        saveLinkError: 'Не удалось сохранить ссылку',
      },
      specification: {
        label: 'Загрузите спецификацию поставщика',
        tooltip: 'Загрузить спецификацию также можно на следующих этапах, в разделе “Спецификация”',
      },
      arbitraryDocs: {
        label: 'Загрузите документы по сделке',
        tooltip: 'Загрузить документы также можно на следующих этапах, в разделе “Документы”',
        uploadModalTitle: 'Загрузка документов по сделке',
      },
      transitCountryLogisticCompany: {
        label: 'Выберите логистическую компанию в стране транзита',
        placeholder: 'Выберите компанию',
      },
      destinationCountryLogisticCompany: {
        label: 'Выберите логистическую компанию в стране назначения',
        placeholder: 'Выберите компанию',
      },
      flightTrackingLink: {
        label: 'Ссылка для отслеживания рейса',
        placeholder: 'Укажите ссылку для отслеживания рейса',
      },
      warrantyLoa: {
        label: 'Загрузите доверенность LOA',
      },
      paymentTaxesCertificate: {
        label: 'Загрузите справку об уплате косвенных налогов',
        tooltip: 'Eсли поставщик намерен оформить возврат НДС',
      },
      authorizationLetter: {
        label: 'Загрузите авторизационное письмо',
      },
      photo: {
        label: 'Загрузите фото',
      },
    },
    requiredStep: {
      awbDoc: {
        descriptionFile: 'Загрузите AWB и нажмите “Подтвердить”',
        descriptionNumber: 'Введите номер рейса и нажмите “Подтвердить”',
        [AwbStatus.wait]: 'Когда будет загружен AWB или введен номер рейса, информация появится здесь',
        [AwbStatus.approveFile]: 'Менеджер загрузил AWB',
        [AwbStatus.approveNumber]: 'Менеджер вписал номер рейса',
        buttonFile: 'AWB',
        buttonNumber: 'Номер рейса',
        numberPlaceholder: 'Введите номер',
      },
    },
    invoiceForm: {
      date: 'Дата',
      country: 'Страна производства',
      flightNumber: 'Номер международной авианакладной или номер рейса',
      flightNumberPlaceholder: 'Введите номер',
      packing: 'Упаковка',
      incoterms: 'Инкотермс',
      signature: 'Cписок подписантов',
    },
    packing: {
      bag: 'Сумка',
      plasticPallet: 'Пластиковый поддон',
    },
    additionalAgreement: {
      date: 'Дата',
      signature: 'Cписок подписантов',
      invoiceNumber: 'Номер инвойса',
      invoiceNumberPlaceholder: 'Введите номер',
      invoiceDate: 'Дата инвойса',
      debtRepaymentDate: 'Дата погашения задолженности',
    },
    acceptanceCertificate: {
      date: 'Дата',
      signatureJobTitle: 'Cписок должностей',
      signature: 'Cписок подписантов',
      actingUnder: 'Действует на основании',
      actingUnderPlaceholder: 'Введите информацию о документе',
      actingUnderTooltip: 'Введите информацию о документе, по которому действует подписант',
      customerPersonalId: 'Номер документа, удостоверяющего личность покупателя',
      supplierPersonalId: 'Номер документа, удостоверяющего личность поставщика',
      personalIdPlaceholder: 'Введите идентификационный номер',
      sum: 'Сумма',
      sumPlaceholder: 'Укажите сумму',
    },
    messages: {
      generationSuccess: 'Документ успешно сгенерирован',
      generationError: 'Ошибка генерации документа',
      loadFieldsDataError: 'Не удалось загрузить данные документа',
    },
    customerSpecification: {
      title: 'Спецификация для покупателя',
      signatory: 'Подписант',
      specify: 'Указать',
      year: 'Год',
      uin: 'UIN',
      informationAboutIngots: 'Информация о слитках',
      countAddIngots_one: 'Добавлен {{count}} слиток',
      countAddIngots_few: 'Добавлено {{count}} слитка',
      countAddIngots_many: 'Добавлено {{count}} слитков',
      ingotNumber: 'Номер слитка',
      ingotNumberPlaceholder: 'Номер',
      weight: 'Химический вес',
      weightPlaceholder: 'Вес в г',
      ligatureWeight: 'Лигатурный вес',
      metalPurity: 'Чистота металла (%)',
      metalPurityPlaceholder: 'Чистота в (%)',
      yearOfProduction: 'Год производства',
      action: {
        add: 'Добавить слиток',
      },
      tooltip:
        'Для автогенерации спецификации требуются данные из коммерческого инвойса. Для корректной генерации спецификации сгенерируйте заранее документ коммерческий инвойс',
      message: {
        emptyIngotFieldsError: 'Пожалуйста, заполните все поля для каждого слитка, чтобы создать спецификацию',
      },
    },
  },
  zh: {
    optionalFields: {
      messages: {
        saveDataSuccess: '数据保存成功',
        saveDataError: '数据保存失败',
        saveLinkSuccess: '链接保存成功',
        saveLinkError: '链接保存失败',
      },
      specification: {
        label: '上传供应商的规格',
        tooltip: '您还可以在以下阶段的“规格书”部分上传规格',
      },
      arbitraryDocs: {
        label: '上传交易文档',
        tooltip: '您还可以在以下阶段的“文档”部分上传文档',
        uploadModalTitle: '上传交易文件',
      },
      transitCountryLogisticCompany: {
        label: '请选择过境国的物流公司',
        placeholder: '请选择公司',
      },
      destinationCountryLogisticCompany: {
        label: '请选择目的国的物流公司',
        placeholder: '请选择公司',
      },
      flightTrackingLink: {
        label: '航班追踪链接',
        placeholder: '请指定航班追踪的链接',
      },
      warrantyLoa: {
        label: '请上传LOA委托书',
      },
      paymentTaxesCertificate: {
        label: '请上传间接税缴纳证明',
        tooltip: '如果供应商打算退还增值税',
      },
      authorizationLetter: {
        label: '请上传授权书',
      },
      photo: {
        label: '上传照片',
      },
    },
    requiredStep: {
      awbDoc: {
        descriptionFile: '下载AWB并点击"确认"',
        descriptionNumber: '输入航班号并点击"确认"',
        [AwbStatus.wait]: '当AWB或航班号上传时，它会出现在这里',
        [AwbStatus.approveFile]: '经理上传了AWB',
        [AwbStatus.approveNumber]: '经理输入了航班号',
        buttonFile: 'AWB',
        buttonNumber: '航班号',
        numberPlaceholder: '输入号码',
      },
    },
    invoiceForm: {
      date: '日期',
      country: '生产国',
      flightNumber: '国际空运提单号或航班号',
      flightNumberPlaceholder: '输入号码',
      packing: '包装',
      incoterms: '国贸条规',
      signature: '签名人名单',
    },
    packing: {
      bag: '包',
      plasticPallet: '塑料托盘',
    },
    additionalAgreement: {
      date: '日期',
      signature: '签名人名单',
      invoiceNumber: '发票号',
      invoiceNumberPlaceholder: '输入号码',
      invoiceDate: '发票日期',
      debtRepaymentDate: '还清日期',
    },
    acceptanceCertificate: {
      date: '日期',
      signatureJobTitle: '职位列表',
      signature: '签名人名单',
      actingUnder: '依据',
      actingUnderPlaceholder: '输入文档信息',
      actingUnderTooltip: '输入签名人据以行事的文件的相关信息',
      customerPersonalId: '买方身份证件号码',
      supplierPersonalId: '供应商身份证件号码',
      personalIdPlaceholder: '输入身份号码',
      sum: '金额',
      sumPlaceholder: '输入金额',
    },
    messages: {
      generationSuccess: '文件已成功生成',
      generationError: '文档生成失败',
      loadFieldsDataError: '加载文档数据失败',
    },
    customerSpecification: {
      title: '买方规格',
      signatory: '指出',
      specify: '指出',
      year: '年份',
      uin: 'UIN',
      informationAboutIngots: '有关金条的资料',
      countAddIngots_other: '新增了 {{count}} 金条',
      ingotNumber: '金条的数量',
      ingotNumberPlaceholder: '输入号码',
      weight: '化学重量',
      weightPlaceholder: '克重',
      ligatureWeight: '结构重量',
      metalPurity: '金属纯度(%)',
      metalPurityPlaceholder: 'Чистота в (%)',
      yearOfProduction: '生产年份',
      action: {
        add: '加一金条',
      },
      tooltip: '要自动生成规格，需要商业发票中的数据。要正确生成规格，请提前生成商业发票文档',
      message: {
        emptyIngotFieldsError: '请填写每个铸锭的所有字段以创建规格',
      },
    },
  },
};
