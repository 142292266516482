import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';
import { CustomModal, StepDescription } from '@/shared/ui';
import { FormInputTextArea } from '@/shared/ui/form/FormInputTextArea';

import { StageModel, StepComponentProps } from '../..';
import { DocumentVerifyingFieldsModel } from '../../model/fieldsModel/DocumentVerifyingFieldsModel';

import s from './DocumentVerifyingStep.module.scss';

const DocumentVerifyingStep = <Model extends StageModel>({
  model,
  fieldKey,
  description,
}: StepComponentProps<Model>) => {
  const { t } = useTypedTranslation('file');

  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof DocumentVerifyingFieldsModel)) {
    return null;
  }

  return (
    <StepDescription description={description} direction="horizontal">
      <CustomModal
        title={t('documentVerifyingForm.modal.title')}
        width={640}
        open={fields.isOpenModal}
        onCancel={fields.closeModal}
        cancelButtonProps={{ disabled: fields.isLoading }}
        onOk={fields.rejectStep}
        okText={t('documentVerifyingForm.modal.okText')}
        okButtonProps={{
          loading: fields.isLoading,
          disabled: fields.error.isError || !fields.error.value,
        }}
        danger
      >
        <FormInputTextArea model={fields.error} disabled={fields.isLoading} />
      </CustomModal>
      <Button
        className={cn(!fields.isWaiting && s.step_chosen)}
        icon={<CloseOutlined />}
        danger={fields.isRejected}
        onClick={fields.openModal}
        size="small"
      >
        {t('documentVerifyingForm.modal.reject')}
      </Button>
      <Button
        className={cn(fields.isApproved && s.step_approved_button, !fields.isWaiting && s.step_chosen)}
        icon={<CheckOutlined />}
        onClick={fields.approveStep}
        size="small"
      >
        {t('documentVerifyingForm.modal.approve')}
      </Button>
    </StepDescription>
  );
};

export default observer(DocumentVerifyingStep);
