import { ApiRequest } from '@kts-front/call-api';
import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { UnitDictionaryServer } from '@/entities/dictionary';
import { MessageType } from '@/entities/message';
import { UserType } from '@/entities/user';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore, ToggleModel, ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';
import { formatNumberWithUnit, numberFormatter } from '@/shared/utils';
import { formatDate } from '@/shared/utils/formatDate';

import { TradeInfo, TradeInfoServer } from '../types';

type TradeInfoPopoverModelParams = {
  tradeId: number;
  rootStore: IRootStore;
  // тип клиента с кем ведет диалог к thread-е
  clientType: UserType | null;
};

export class TradeInfoPopoverModel extends LocalStore {
  readonly tradeInfo = new ValueModel<Nullable<TradeInfo>>(null);

  readonly popoverState = new ToggleModel();

  readonly loadingStage = new LoadingStageModel();

  private readonly _rootStore: IRootStore;

  private readonly _tradeInfoRequest: ApiRequest<TradeInfoServer>;
  private readonly _clientType: UserType | null;
  private readonly _modalState: ToggleModel = new ToggleModel();

  constructor({ tradeId, rootStore, clientType }: TradeInfoPopoverModelParams) {
    super();

    this._rootStore = rootStore;
    this._clientType = clientType;

    this._tradeInfoRequest = apiStore.createRequest({
      url: apiUrls.trade.details(tradeId),
    });

    makeObservable(this, {
      isOpen: computed,
      open: action.bound,
      close: action.bound,
    });
  }

  get isOpen(): boolean {
    return this._modalState.isOpen;
  }

  open(): void {
    if (this.isOpen) {
      return;
    }

    this._modalState.open();
    this._load();
  }

  close(): void {
    if (!this.isOpen) {
      return;
    }

    this._tradeInfoRequest.cancel();
    this._modalState.close();
  }

  private async _load(): Promise<void> {
    if (this.loadingStage.isLoading) {
      return;
    }

    this.loadingStage.loading();

    const response = await this._tradeInfoRequest.call();

    if (response.isError) {
      if (response.data !== undefined) {
        this._rootStore.notificationsStore.addNotification({
          type: MessageType.error,
          message: (t) => t('messages.fetchTradeInfoError', { ns: 'trade' }),
        });
      }

      this.loadingStage.error();
    } else {
      this.tradeInfo.change(this._tradeInfoFromJson(response.data, this._clientType));
      this.loadingStage.success();
    }
  }

  private _tradeInfoFromJson = (data: TradeInfoServer, clientType: UserType | null): TradeInfo => {
    const dictionaries = this._rootStore.dictionariesStore;
    const productTypeId = data.product_type_id;
    const productId = data.product_id;

    const productType = dictionaries.productTypes.list.getEntity(productTypeId);
    const product = productType && productId !== null ? productType.productList.getEntity(productId) : null;
    const weightUnit = productType?.unitList ? productType.unitList.getEntity(data.weight_unit_id) : null;

    return {
      productType: productType && productType.label,
      weightWithUnit: weightUnit && `${numberFormatter(String(data.weight))} ${weightUnit.label}`,
      ligatureWeightWithUnit:
        product && weightUnit && `${numberFormatter(String(data.ligature_weight))} ${weightUnit.label}`,
      fixingDate: formatDate(data.fixing_date, 'DD-MM-YYYY'),
      product: product && product.label,
      customer: data.customer && data.customer.name,
      supplier: data.supplier && data.supplier.name,
      legalCompanyFrom: data.legal_company_from && data.legal_company_from.name,
      legalCompanyTo: data.legal_company_to && data.legal_company_to.name,
      sellAmount: this._amountFromJson(data.sell_price, data.sell_price_unit),
      sellAmountTotal: this._amountFromJson(data.sell_price_total, data.sell_price_unit),
      buyAmount: this._amountFromJson(data.buy_price, data.buy_price_unit),
      buyAmountTotal: this._amountFromJson(data.buy_price_total, data.buy_price_unit),
      transitCountry:
        (data.transit_country && dictionaries.countries.list.getEntity(data.transit_country)?.label) || null,
      transitLogisticCompany: data.transit_logistic_company && data.transit_logistic_company.name,
      destinationLogisticCompany: data.destination_logistic_company && data.destination_logistic_company.name,
      chats: data.threads
        .filter((thread) => thread.user_type !== clientType)
        .map((thread) => ({
          chatId: thread.chat_id,
          threadId: thread.thread_id,
          userType: thread.user_type,
        })),
    };
  };

  private _amountFromJson = (price: Nullable<number>, unit: Nullable<UnitDictionaryServer>): Nullable<string> => {
    return price && unit ? formatNumberWithUnit(price, unit.name) : null;
  };
}
