import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { IChatsStore, NewMessageCounter } from '@/entities/chat';
import { UserType } from '@/entities/user';
import {
  CreatedMessageWsBody,
  CreatedThreadWsBody,
  DeletedMessageWsBody,
  EditedMessageWsBody,
  ReadMessagesWsBody,
} from '@/entities/ws';
import { LocalStore, QueryParamsModel, ValueModel } from '@/shared/model';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { ChatServer, ChatsQueryParams, ChatType, IChat } from '../../types';
import { BaseChatListModelParams } from '../BaseChatListModel';
import { ThreadListModel } from '../ThreadListModel';

type JsonParams = BaseChatListModelParams & {
  server: ChatServer;
  chatsStore: IChatsStore;
};

type ChatModelParams = {
  id: number;
  type: ChatType;
  createdDate: Date;
  newMessageCount: number;
  lastMessageDate: Nullable<Date>;
  userType: Nullable<UserType>;
  name: TranslationString;
  pinned: boolean;
  queryParams: QueryParamsModel<ChatsQueryParams>;
  rootStore: IRootStore;
  chatsStore: IChatsStore;
};

export class ChatModel extends LocalStore implements IChat {
  readonly id: number;
  readonly type: ChatType;
  readonly pinned: boolean;
  readonly createdDate: Date;
  readonly name: TranslationString;
  readonly newMessageCounter: NewMessageCounter;
  readonly lastMessageDateModel: ValueModel<Nullable<Date>>;
  readonly userType: Nullable<UserType>;
  readonly threads: ThreadListModel;
  readonly chatsStore: IChatsStore;

  constructor({
    id,
    type,
    name,
    createdDate,
    newMessageCount,
    lastMessageDate,
    userType,
    pinned,
    queryParams,
    rootStore,
    chatsStore,
  }: ChatModelParams) {
    super();

    this.id = id;
    this.type = type;
    this.pinned = pinned;
    this.createdDate = createdDate;
    this.name = name;
    this.newMessageCounter = new NewMessageCounter(newMessageCount);
    this.lastMessageDateModel = new ValueModel(lastMessageDate);
    this.userType = userType;
    this.threads = new ThreadListModel({ chat: this, queryParams, rootStore });
    this.chatsStore = chatsStore;

    makeObservable(this, {
      lastMessageDate: computed,

      handleCreatedThreadWsEvent: action.bound,
      handleCreatedMessageWsEvent: action.bound,
      handleReadMessagesWsEvent: action.bound,
      handleEditedMessageWsEvent: action.bound,
      handleDeletedMessageWsEvent: action.bound,
    });
  }

  get lastMessageDate(): Nullable<Date> {
    return this.lastMessageDateModel.value;
  }

  handleCreatedThreadWsEvent(body: CreatedThreadWsBody): Promise<void> {
    return this.threads.handleCreatedThreadWsEvent(body);
  }

  async handleCreatedMessageWsEvent(body: CreatedMessageWsBody): Promise<void> {
    /*
     * Обновляем кол-во новых сообщений в чате
     * */
    this.newMessageCounter.change(body.chat.new_message_count);

    /*
     * Обновляем дату последнего сообщения в чате
     * */
    this.lastMessageDateModel.change(new Date(body.created_at));

    await this.threads.handleCreatedMessageWsEvent(body);
  }

  handleReadMessagesWsEvent(body: ReadMessagesWsBody): void {
    /*
     * Обновляем счетчик новых сообщений
     * */
    this.newMessageCounter.change(body.chat_new_message_count);

    this.threads.handleReadMessagesWsEvent(body);
  }

  handleEditedMessageWsEvent(body: EditedMessageWsBody): void {
    this.threads.handleEditedMessageWsEvent(body);
  }

  handleDeletedMessageWsEvent(body: DeletedMessageWsBody): void {
    this.threads.handleDeletedMessageWsEvent(body);
  }

  destroy(): void {
    super.destroy();
    this.threads.destroy();
  }

  static fromJson({ server, ...params }: JsonParams): ChatModel {
    return new ChatModel({
      id: server.id,
      type: server.type,
      pinned: server.pinned,
      createdDate: new Date(server.created_at),
      newMessageCount: server.new_message_count,
      lastMessageDate: server.last_message_at ? new Date(server.last_message_at) : null,
      userType: server.user_type,
      name: server.type === ChatType.public ? (t) => t('chatPublic', { ns: 'chats' }) : server.name,
      ...params,
    });
  }
}
