import { company } from '@/entities/company/translation';
import { file } from '@/entities/file/translation';
import { stage } from '@/entities/stage/translation';
import { trade } from '@/entities/trade/translation';
import { tradeHistory } from '@/entities/tradeHistory/translation';
import { user } from '@/entities/user/translation';
import { notifications } from '@/features/notifications/translation';
import { agents } from '@/pages/Agents/translation';
import { bids } from '@/pages/Bids/translation';
import { chats } from '@/pages/Chats/translation';
import { clients } from '@/pages/Clients/translation';
import { createPassword } from '@/pages/CreatePassword/translation';
import { documents } from '@/pages/Documents/translation';
import { login } from '@/pages/Login/translation';
import { profile } from '@/pages/Profile/translation';
import { trades } from '@/pages/Trades/translation';
import { shared } from '@/shared/translation';
import { confirmationStage } from '@/widgets/confirmationStage/translation';
import { creationStage } from '@/widgets/creationStage/translation';
import { finalizationStage } from '@/widgets/finalizationStage/translation';
import { menu } from '@/widgets/menu/translation';
import { paymentStage } from '@/widgets/paymentStage/translation';
import { shipmentStage } from '@/widgets/shipmentStage/translation';
import { transportationStage } from '@/widgets/transportationStage/translation';

import { NameSpace } from './types';

export const nameSpaces = {
  agents,
  chats,
  clients,
  company,
  confirmationStage,
  createPassword,
  creationStage,
  documents,
  file,
  finalizationStage,
  bids,
  login,
  menu,
  notifications,
  paymentStage,
  profile,
  shared,
  shipmentStage,
  stage,
  trade,
  tradeHistory,
  trades,
  transportationStage,
  user,
};

export const nameSpaceNames = Object.keys(nameSpaces) as NameSpace[];
