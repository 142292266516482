import { CustomerTransportationStageDataServer, CustomerTransportationStep, StageType } from '@/entities/stage/types';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { WaitingAwbOrFlightNumberFieldsModel } from '../../fieldsModel';
import { WaitingFieldsWithFilesModel } from '../../fieldsModel/WaitingFieldsWithFilesModel';

import { DestinationCountryDocs, destinationCountryDocsOptions, mapStepToNumber } from './config';

type Params = BaseStageModelParams<CustomerTransportationStep> & {
  isCargoArrived: boolean;
  destinationCountryDocsFields: WaitingFieldsWithFilesModel<CustomerTransportationStep, DestinationCountryDocs>;
  destinationCountryAwbFields: WaitingAwbOrFlightNumberFieldsModel<CustomerTransportationStep>;
};

export class CustomerTransportationStageModel extends BaseStageModel<
  StageType.transportation,
  CustomerTransportationStep
> {
  readonly isCargoArrived: boolean;
  readonly destinationCountryDocsFields: WaitingFieldsWithFilesModel<
    CustomerTransportationStep,
    DestinationCountryDocs
  >;
  readonly destinationCountryAwbFields: WaitingAwbOrFlightNumberFieldsModel<CustomerTransportationStep>;

  constructor({ isCargoArrived, destinationCountryDocsFields, destinationCountryAwbFields, ...params }: Params) {
    super({
      type: StageType.transportation,
      ...params,
    });

    this.isCargoArrived = isCargoArrived;
    this.destinationCountryDocsFields = destinationCountryDocsFields;
    this.destinationCountryAwbFields = destinationCountryAwbFields;
  }

  get isDestinationCountryDocsUploaded(): boolean {
    return Boolean(this.destinationCountryDocsFields.files?.every(({ file }) => file.isUploaded));
  }

  get isDestinationCountryAwbUploaded(): boolean {
    return this.destinationCountryAwbFields.file.isUploaded;
  }

  get currentStep(): number {
    return mapStepToNumber(this.step, this.isShipmentTypeTransit);
  }

  get steps(): CustomerTransportationStep[] {
    const steps: CustomerTransportationStep[] = [CustomerTransportationStep.cargoArrival];

    if (this.isShipmentTypeTransit) {
      steps.push(CustomerTransportationStep.destinationCountryDocs, CustomerTransportationStep.destinationCountryAwb);
    }

    steps.push(CustomerTransportationStep.transfer);

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: CustomerTransportationStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): CustomerTransportationStageModel {
    const { current_step, steps } = dataServer;

    return new CustomerTransportationStageModel({
      isCargoArrived: steps[CustomerTransportationStep.cargoArrival]?.arrived ?? false,
      destinationCountryDocsFields: WaitingFieldsWithFilesModel.fromJson({
        data: steps[CustomerTransportationStep.destinationCountryDocs],
        options: destinationCountryDocsOptions,
        step: CustomerTransportationStep.destinationCountryDocs,
        tradeWorkflowStore,
      }),
      destinationCountryAwbFields: WaitingAwbOrFlightNumberFieldsModel.fromJson({
        data: steps[CustomerTransportationStep.destinationCountryAwb],
        step: CustomerTransportationStep.destinationCountryAwb,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
      step: current_step,
    });
  }
}
