import { action, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { DocumentFileType, FileModel } from '@/entities/file';
import { MessageType } from '@/entities/message';
import {
  ICreationStage,
  ManagerCreationStageDataServer,
  ManagerCreationStep,
  OptionFieldsAction,
} from '@/entities/stage';
import { CreateTradeModel, TradeWorkflowResponse } from '@/entities/trade';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, ToggleModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseManagerStageModel } from '../../../BaseManagerStageModel';

type Params = {
  data: ICreationStage;
  rootStore: IRootStore;
  tradeWorkflowStore?: Nullable<ITradeWorkflowStore>;
};

const T_OPTIONS = { ns: 'trade' } as const;

export class CreateTradeFieldsModel extends CreateTradeModel {
  readonly deleteSupplierSpecStage = new LoadingStageModel();
  readonly deleteSupplierSpecModalState = new ToggleModel();

  private readonly _tradeRequest = apiStore.createRequest<TradeWorkflowResponse>({
    method: 'POST',
  });

  constructor(params: Params) {
    super(params);

    makeObservable(this, {
      editTrade: action.bound,
      deleteSupplierSpec: action.bound,
    });
  }

  async deleteSupplierSpec(): Promise<void> {
    if (this.deleteSupplierSpecStage.isLoading || !this.tradeWorkflowStore) {
      return;
    }

    this.deleteSupplierSpecStage.loading();

    const response = await this._tradeRequest.call({
      url: apiUrls.trade.action(this.tradeWorkflowStore.tradeId),
      multipartFormData: true,
      data: {
        action: OptionFieldsAction.deleteSupplierSpec,
      },
    });

    if (response.isError) {
      this.deleteSupplierSpecStage.error();

      return;
    }

    this.deleteSupplierSpecStage.success();
    this.deleteSupplierSpecModalState.close();

    this.tradeWorkflowStore.updateTradeWorkflow(response.data);
  }

  async editTrade(): Promise<void> {
    if (this.requestStage.isLoading || !this.tradeWorkflowStore) {
      return;
    }

    this.formFields.forEach((field) => {
      if ((field == 'product' || field == 'ligatureWeight') && !this.productTypeHasProducts) {
        return;
      }

      this[field].validate();
    });

    if (this.isError) {
      this.scrollToErrorField();

      return;
    }

    const transferFields =
      this.tradeWorkflowStore.stageModel instanceof BaseManagerStageModel
        ? this.tradeWorkflowStore.stageModel.transferFields
        : null;

    this.requestStage.loading();
    transferFields?.loadingStage.loading();

    const response = await this._tradeRequest.call({
      url: apiUrls.trade.edit(this.tradeWorkflowStore.tradeId),
      multipartFormData: true,
      data: this.toJson(),
    });

    if (response.isError) {
      this.requestStage.error();
      transferFields?.loadingStage.error();
      this.rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.createTradeError', T_OPTIONS),
      });

      return;
    }

    this.requestStage.success();
    transferFields?.loadingStage.success();
    this.rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.createTradeSuccess', T_OPTIONS),
    });

    this.tradeWorkflowStore.updateTradeWorkflow(response.data);
  }

  static fromJson({
    server,
    tradeWorkflowStore,
  }: {
    server: ManagerCreationStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): CreateTradeFieldsModel {
    const info = server.steps[ManagerCreationStep.createTrade];
    const supplierSpecification = server[DocumentFileType.supplierSpecification];
    const productType = tradeWorkflowStore.dictionaries.productTypes.list.getEntity(info.product_type_id);
    const product =
      info.product_id && productType
        ? {
            id: info.product_id,
            label: productType.name,
          }
        : null;

    return new CreateTradeFieldsModel({
      tradeWorkflowStore,
      rootStore: tradeWorkflowStore.rootStore,
      data: {
        supplierSpec: FileModel.fileListFromJson(supplierSpecification),
        customer: info.customer?.id ?? null,
        supplier: info.supplier?.id ?? null,
        legalCompanyFrom: info.legal_company_from?.id ?? null,
        legalCompanyTo: info.legal_company_to?.id ?? null,
        productType: info.product_type_id,
        product,
        weight: String(info.weight),
        weightUnit: info.weight_unit_id,
        fixingDate: new Date(info.fixing_date),
        sellPrice: info.sell_price ? String(info.sell_price) : '',
        sellPriceUnit: info.sell_price_unit?.id ?? null,
        buyPrice: info.buy_price ? String(info.buy_price) : '',
        buyPriceUnit: info.buy_price_unit?.id ?? null,
        sellPriceTotal: info.sell_price_total ? String(info.sell_price_total) : '',
        buyPriceTotal: info.buy_price_total ? String(info.buy_price_total) : '',
        ligatureWeight: info.ligature_weight ? String(info.ligature_weight) : '',
      },
    });
  }
}
