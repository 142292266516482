import { DownOutlined, EditOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ID } from '@/shared/types/meta';
import { FormSelect } from '@/shared/ui/form/FormSelect';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { SelectOptionalOptionModel } from '../../model/SelectOptionalOptionModel';

import s from './SelectOptionalOption.module.scss';

type SelectOptionalOptionProps<Value> = {
  model: SelectOptionalOptionModel<Value>;
};

const SelectOptionalOption = <Value extends ID>({ model }: SelectOptionalOptionProps<Value>) => {
  const {
    isInitializedByValue,
    isSelected,
    isError,
    disabled,
    loadingStage,
    options,
    changeInitializedByValue,
    selectOption,
  } = model;

  const handleChangeInitialized = React.useCallback(() => {
    changeInitializedByValue(false);
  }, [changeInitializedByValue]);

  return (
    <Flex vertical gap={8}>
      <FormSelect
        className={cn(s['form-select'], !disabled && isInitializedByValue && s['form-select_disabled'])}
        model={model}
        options={options}
        suffixIcon={
          !disabled && isInitializedByValue ? (
            <EditOutlined onClick={handleChangeInitialized} />
          ) : (
            !disabled && <DownOutlined />
          )
        }
      />
      <ApproveButton
        loading={loadingStage.isLoading}
        disabled={disabled || !isSelected || isError || isInitializedByValue}
        onClick={selectOption}
      />
    </Flex>
  );
};

export default observer(SelectOptionalOption);
