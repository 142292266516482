import { ChatServer, MessageServer, ThreadServer } from '@/entities/chat';

export enum WSLocation {
  main = 'main',
  chat = 'chat',
  bid = 'bid',
  stockMarket = 'stock_market',
}

export enum WsClientEvent {
  state = 'state',
  connected = 'connected',
  disconnected = 'disconnected',
  subscribed = 'subscribed',
  subscribing = 'subscribing',
  unsubscribed = 'unsubscribed',
  publication = 'publication',
}

export enum ChatWsEventType {
  threadCreated = 'thread_created',
  messageCreated = 'chat_message_created',
  messageEdited = 'chat_message_edited',
  messageRead = 'chat_message_read',
  messageDeleted = 'chat_message_deleted',
}

export type CreatedThreadWsBody = {
  chat_id: number;
  thread_id: number;
};

export type CreatedThreadWsEvent = {
  type: ChatWsEventType.threadCreated;
  body: CreatedThreadWsBody;
};

export type CreatedMessageWsBody = Omit<MessageServer, 'chat' | 'thread'> & {
  chat: ChatServer;
  thread: ThreadServer;
  target_thread_id: number;
  target_thread_new_message_count: number;
};

export type CreatedMessageWsEvent = {
  type: ChatWsEventType.messageCreated;
  body: CreatedMessageWsBody;
};

export type EditedMessageWsBody = Omit<CreatedMessageWsBody, 'edited_at'> & { edited_at: string };

export type EditedMessageWsEvent = {
  type: ChatWsEventType.messageEdited;
  body: EditedMessageWsBody;
};

export type ReadMessagesWsBody = {
  chat_id: number;
  chat_new_message_count: number;
  thread_id: number;
  thread_new_message_count: number;
  messages: [
    {
      id: string;
      read_at: string;
    },
  ];
};

export type ReadMessagesWsEvent = {
  type: ChatWsEventType.messageRead;
  body: ReadMessagesWsBody;
};

export type DeletedMessageWsBody = {
  chat: ChatServer;
  target_thread: Omit<ThreadServer, 'chat'>;
  message_id: string;
};

export type DeletedMessageWsEvent = {
  type: ChatWsEventType.messageDeleted;
  body: DeletedMessageWsBody;
};

export type ChatWsEvents =
  | CreatedThreadWsEvent
  | CreatedMessageWsEvent
  | ReadMessagesWsEvent
  | EditedMessageWsEvent
  | DeletedMessageWsEvent;
