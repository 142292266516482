import { ListModel } from '@/shared/model';

import { IProductTypeDictionary, ProductType, ProductTypeDictionaryServer } from '../types';

import { UnitDictionaryModel } from './UnitDictionaryModel';

export class ProductTypeDictionaryModel implements IProductTypeDictionary {
  readonly value: number;
  readonly label: string;
  readonly name: string;
  readonly type: ProductType;
  readonly unitList: ListModel<UnitDictionaryModel, number>;
  readonly productList: ListModel<UnitDictionaryModel, number>;

  constructor({ value, label, name, type, unitList, productList }: IProductTypeDictionary) {
    this.value = value;
    this.label = label;
    this.name = name;
    this.type = type;
    this.unitList = unitList;
    this.productList = productList;
  }

  static fromJson(server: ProductTypeDictionaryServer): ProductTypeDictionaryModel {
    const unitList = new ListModel<UnitDictionaryModel, number>();

    unitList.fillByRawData(server.units, (raw) => ({
      entity: UnitDictionaryModel.fromJson(raw),
      key: raw.id,
    }));

    const productList = new ListModel<UnitDictionaryModel, number>();

    if (server.products && server.products.length > 0) {
      productList.fillByRawData(server.products, (raw) => ({
        entity: UnitDictionaryModel.fromJson(raw),
        key: raw.id,
      }));
    }

    return new ProductTypeDictionaryModel({
      value: server.id,
      label: server.name,
      name: server.name,
      type: server.slug,
      unitList,
      productList,
    });
  }
}
