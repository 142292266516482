import {
  ManagerAwbDestinationAction,
  ManagerTransportationAction,
  ManagerTransportationStageDataServer,
  ManagerTransportationStep,
  ShipmentType,
  StageType,
} from '@/entities/stage';
import { ITradeWorkflowStore } from '@/pages/TradeWorkflow';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import { ConfirmationFieldsModel } from '../../fieldsModel/ConfirmationFieldsModel';
import { DestinationCountryDocsFieldsModel } from '../../fieldsModel/DestinationCountryDocsFieldsModel';
import { UploadAwbOrFlightNumberModel } from '../../fieldsModel/UploadAwbOrFlightNumberModel';
import { TradeCloseSupplierDocsFieldsModel } from '../fieldsModel/TradeCloseSupplierDocsFieldsModel';

import { mapManagerStepToNumber } from './config';

type Params = BaseManagerStageModelParams<ManagerTransportationStep> & {
  transitCountryCargoArrivalFields: ConfirmationFieldsModel<ManagerTransportationStep, ManagerTransportationAction>;
  cargoArrivalFields: ConfirmationFieldsModel<ManagerTransportationStep, ManagerTransportationAction>;
  tradeCloseSupplierDocsFields: TradeCloseSupplierDocsFieldsModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  destinationCountryDocsFields: DestinationCountryDocsFieldsModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  destinationCountryAwbFields: UploadAwbOrFlightNumberModel<
    ManagerTransportationStep,
    ManagerAwbDestinationAction.uploadFile,
    ManagerAwbDestinationAction.flightNumber
  >;
  transitCountryCargoShipmentFields: ConfirmationFieldsModel<ManagerTransportationStep, ManagerTransportationAction>;
  destinationCountryCargoArrivalFields: ConfirmationFieldsModel<ManagerTransportationStep, ManagerTransportationAction>;
};

export class ManagerTransportationStageModel extends BaseManagerStageModel<
  StageType.transportation,
  ManagerTransportationStep
> {
  readonly transitCountryCargoArrivalFields: ConfirmationFieldsModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  readonly cargoArrivalFields: ConfirmationFieldsModel<ManagerTransportationStep, ManagerTransportationAction>;
  readonly tradeCloseSupplierDocsFields: TradeCloseSupplierDocsFieldsModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  readonly destinationCountryDocsFields: DestinationCountryDocsFieldsModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  readonly destinationCountryAwbFields: UploadAwbOrFlightNumberModel<
    ManagerTransportationStep,
    ManagerAwbDestinationAction.uploadFile,
    ManagerAwbDestinationAction.flightNumber
  >;
  readonly transitCountryCargoShipmentFields: ConfirmationFieldsModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  readonly destinationCountryCargoArrivalFields: ConfirmationFieldsModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;

  constructor({
    transitCountryCargoArrivalFields,
    cargoArrivalFields,
    tradeCloseSupplierDocsFields,
    destinationCountryDocsFields,
    destinationCountryAwbFields,
    transitCountryCargoShipmentFields,
    destinationCountryCargoArrivalFields,
    ...params
  }: Params) {
    super({
      type: StageType.transportation,
      ...params,
    });

    this.transitCountryCargoArrivalFields = transitCountryCargoArrivalFields;
    this.cargoArrivalFields = cargoArrivalFields;
    this.tradeCloseSupplierDocsFields = tradeCloseSupplierDocsFields;
    this.destinationCountryDocsFields = destinationCountryDocsFields;
    this.destinationCountryAwbFields = destinationCountryAwbFields;
    this.transitCountryCargoShipmentFields = transitCountryCargoShipmentFields;
    this.destinationCountryCargoArrivalFields = destinationCountryCargoArrivalFields;
  }

  get currentStep(): number {
    return mapManagerStepToNumber(this.step, this.shipmentType);
  }

  get steps(): ManagerTransportationStep[] {
    let steps: ManagerTransportationStep[] = [];

    if (this.isShipmentTypeTransit) {
      steps = [
        ManagerTransportationStep.transitCountryCargoArrival,
        ManagerTransportationStep.tradeCloseSupplierDocs,
        ManagerTransportationStep.destinationCountryDocs,
        ManagerTransportationStep.destinationCountryAwb,
        ManagerTransportationStep.transitCountryCargoShipment,
        ManagerTransportationStep.destinationCountryCargoArrival,
      ];
    }

    if (this.isShipmentTypeDirect) {
      steps = [ManagerTransportationStep.cargoArrival, ManagerTransportationStep.tradeCloseSupplierDocs];
    }

    if (this.step === ManagerTransportationStep.transfer) {
      steps.push(ManagerTransportationStep.transfer);
    }

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: ManagerTransportationStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): ManagerTransportationStageModel {
    const { steps } = dataServer;

    return new ManagerTransportationStageModel({
      transitCountryCargoArrivalFields: ConfirmationFieldsModel.fromJson({
        data: steps[ManagerTransportationStep.transitCountryCargoArrival]?.arrived,
        step: ManagerTransportationStep.transitCountryCargoArrival,
        approveAction: ManagerTransportationStep.transitCountryCargoArrival,
        tradeWorkflowStore,
      }),
      cargoArrivalFields: ConfirmationFieldsModel.fromJson({
        data: steps[ManagerTransportationStep.cargoArrival]?.arrived,
        step: ManagerTransportationStep.cargoArrival,
        approveAction: ManagerTransportationStep.cargoArrival,
        tradeWorkflowStore,
      }),
      tradeCloseSupplierDocsFields: TradeCloseSupplierDocsFieldsModel.fromJson({
        data: steps[ManagerTransportationStep.tradeCloseSupplierDocs],
        step: ManagerTransportationStep.tradeCloseSupplierDocs,
        approveAction: ManagerTransportationStep.tradeCloseSupplierDocs,
        tradeWorkflowStore,
      }),
      destinationCountryDocsFields: DestinationCountryDocsFieldsModel.fromJson({
        shipmentType: ShipmentType.transit,
        data: steps[ManagerTransportationStep.destinationCountryDocs],
        step: ManagerTransportationStep.destinationCountryDocs,
        approveAction: ManagerTransportationStep.destinationCountryDocs,
        tradeWorkflowStore,
      }),
      destinationCountryAwbFields: UploadAwbOrFlightNumberModel.fromServer({
        data: steps[ManagerTransportationStep.destinationCountryAwb],
        step: ManagerTransportationStep.destinationCountryAwb,
        approveFileAction: ManagerAwbDestinationAction.uploadFile,
        approveNumberAction: ManagerAwbDestinationAction.flightNumber,
        tradeWorkflowStore,
      }),
      transitCountryCargoShipmentFields: ConfirmationFieldsModel.fromJson({
        data: steps[ManagerTransportationStep.transitCountryCargoShipment]?.shipped,
        step: ManagerTransportationStep.transitCountryCargoShipment,
        approveAction: ManagerTransportationStep.transitCountryCargoShipment,
        tradeWorkflowStore,
      }),
      destinationCountryCargoArrivalFields: ConfirmationFieldsModel.fromJson({
        data: steps[ManagerTransportationStep.destinationCountryCargoArrival]?.arrived,
        step: ManagerTransportationStep.destinationCountryCargoArrival,
        approveAction: ManagerTransportationStep.destinationCountryCargoArrival,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
