import { DocumentServer, FileServer } from '@/entities/file';
import { UserType } from '@/entities/user';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer, DestinationCountryDocsServer, TradeCloseSupplierDocsServer } from './baseStage';

export enum ManagerTransportationStep {
  transitCountryCargoArrival = 'cargo_arrival_in_transit_country',
  cargoArrival = 'cargo_arrival_confirmation',
  tradeCloseSupplierDocs = 'upload_documents_for_trade_close_supplier',
  destinationCountryDocs = 'upload_destination_country_documents',
  destinationCountryAwb = 'upload_awb_for_destination_country',
  transitCountryCargoShipment = 'cargo_shipment_from_transit_country',
  destinationCountryCargoArrival = 'cargo_arrival_in_destination_country',
  transfer = 'transfer',
}

export enum ManagerAwbDestinationAction {
  uploadFile = 'upload_awb_for_destination_country',
  flightNumber = 'set_flight_number_for_destination_country',
}

export type ManagerTransportationAction =
  | Exclude<ManagerTransportationStep, ManagerTransportationStep.destinationCountryAwb>
  | ManagerAwbDestinationAction;

export enum CustomerTransportationStep {
  cargoArrival = 'cargo_arrival_confirmation',
  destinationCountryDocs = 'upload_destination_country_documents',
  destinationCountryAwb = 'upload_awb_for_destination_country',
  transfer = 'transfer',
}

type MapUserTypeToTransportationStageData = {
  [UserType.admin]: {
    [ManagerTransportationStep.transitCountryCargoArrival]: Nullable<{ arrived: boolean }>;
    [ManagerTransportationStep.cargoArrival]: Nullable<{ arrived: boolean }>;
    [ManagerTransportationStep.tradeCloseSupplierDocs]: Nullable<TradeCloseSupplierDocsServer>;
    [ManagerTransportationStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer>;
    [ManagerTransportationStep.destinationCountryAwb]: Nullable<DocumentServer>;
    [ManagerTransportationStep.transitCountryCargoShipment]: Nullable<{ shipped: boolean }>;
    [ManagerTransportationStep.destinationCountryCargoArrival]: Nullable<{ arrived: boolean }>;
  };
  [UserType.manager]: {
    [ManagerTransportationStep.transitCountryCargoArrival]: Nullable<{ arrived: boolean }>;
    [ManagerTransportationStep.cargoArrival]: Nullable<{ arrived: boolean }>;
    [ManagerTransportationStep.tradeCloseSupplierDocs]: Nullable<TradeCloseSupplierDocsServer>;
    [ManagerTransportationStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer>;
    [ManagerTransportationStep.destinationCountryAwb]: Nullable<DocumentServer>;
    [ManagerTransportationStep.transitCountryCargoShipment]: Nullable<{ shipped: boolean }>;
    [ManagerTransportationStep.destinationCountryCargoArrival]: Nullable<{ arrived: boolean }>;
  };
  [UserType.supplier]: null;
  [UserType.customer]: {
    [CustomerTransportationStep.cargoArrival]: Nullable<{ arrived: boolean }>;
    [CustomerTransportationStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer<FileServer>>;
    [CustomerTransportationStep.destinationCountryAwb]: Nullable<DocumentServer>;
  };
  [UserType.agent]: null;
};

type BaseTransportationStageDataServer<U extends UserType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToTransportationStageData[U]
>;

export type ManagerTransportationStageDataServer = BaseTransportationStageDataServer<
  UserType.manager | UserType.admin,
  ManagerTransportationStep
>;

export type CustomerTransportationStageDataServer = BaseTransportationStageDataServer<
  UserType.customer,
  CustomerTransportationStep
>;

export type TransportationStageDataServer =
  | ManagerTransportationStageDataServer
  | CustomerTransportationStageDataServer;

export type TransportationAction = ManagerTransportationAction;
