import { ID } from '@/shared/types/meta';

import { DictionaryServer, IDictionary } from '../types';

export class DictionaryModel<Value extends ID = number> implements IDictionary<Value> {
  readonly value: Value;
  readonly label: string;
  readonly name: string;

  constructor({ value, label, name }: IDictionary<Value>) {
    this.value = value;
    this.label = label;
    this.name = name;
  }

  static mapServerToClient<Value extends ID>(server: DictionaryServer<Value>): IDictionary<Value> {
    return {
      value: server.id,
      label: server.name,
      name: server.name,
    };
  }

  static fromJson<Value extends ID>(server: DictionaryServer<Value>): DictionaryModel<Value> {
    return new DictionaryModel<Value>(this.mapServerToClient(server));
  }
}
