import { BaseResponse } from '@kts-front/types';
import { UploadChangeParam } from 'antd/es/upload';
import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { MessageType } from '@/entities/message';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, ValueModel } from '@/shared/model';
import { InputModel } from '@/shared/model/form/InputModel';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { stringIsNumber } from '@/shared/types/typesGuard';
import { ValidatorResult } from '@/shared/types/validator';
import { Nullable } from '@/shared/types/values';
import { fileSizeValidator } from '@/shared/utils/validators';

import { CreateMessagePayload, EditMessagePayload, ThreadType } from '../../types';
import { GeneralStreamModalModel } from '../GeneralStreamModalModel';
import { MessageModel } from '../MessageModel';
import { ThreadModel } from '../ThreadModel';

import { MessageInputError } from './types';

type MessageInputModelParams = {
  threadModel: ThreadModel;
  rootStore: IRootStore;
};

const T_OPTIONS = { ns: 'chats' } as const;
const TOTAL_FILES_SIZE = 100 * 1024 * 1024;

export class MessageInputModel {
  readonly text = new InputModel({
    initialValue: '',
  });

  readonly files = new UploadFileModel({
    acceptFileFormats: null,
    initialValue: [],
    maxCount: 10,
  });

  readonly sendingStage = new LoadingStageModel();

  readonly generalStreamModal: GeneralStreamModalModel;

  private readonly _messageForEdit = new ValueModel<Nullable<MessageModel>>(null);

  private readonly _messageError = new ValueModel<ValidatorResult<MessageInputError>>(null);

  private readonly _threadModel: ThreadModel;

  private readonly _rootStore: IRootStore;

  private readonly _messageRequest = apiStore.createRequest({
    multipartFormData: true,
  });

  constructor({ threadModel, rootStore }: MessageInputModelParams) {
    this.generalStreamModal = new GeneralStreamModalModel({
      sendMessage: this._sendMessage.bind(this),
      chatId: threadModel.chatInfo.id,
      rootStore,
    });
    this._threadModel = threadModel;
    this._rootStore = rootStore;

    makeObservable(this, {
      isEditMode: computed,
      isError: computed,
      isFilled: computed,
      isChanged: computed,
      isOpenTotalFilesSizeModal: computed,

      onChangeFiles: action.bound,
      addForEdit: action.bound,
      send: action.bound,
      resetError: action.bound,
      reset: action.bound,
    });
  }

  get isEditMode(): boolean {
    return this._messageForEdit.value !== null;
  }

  get isError(): boolean {
    return this.files.isError || this.text.isError;
  }

  get isFilled(): boolean {
    return this.text.value.trim().length > 0 || this.files.isUploaded;
  }

  get isChanged(): boolean {
    const messageModel = this._messageForEdit.value;

    if (!messageModel) {
      return false;
    }

    const originFiles = messageModel.files ?? [];

    return (
      this.text.value.trim() !== messageModel.text ||
      this.files.value.length !== originFiles.length ||
      this.files.value.some((file) => !originFiles.some((originFile) => originFile.uid === file.uid))
    );
  }

  get isOpenTotalFilesSizeModal(): boolean {
    return this._messageError.value === MessageInputError.exceedsTotalFilesSize;
  }

  onChangeFiles({ fileList, ...params }: UploadChangeParam): void {
    fileList = fileList.map((file) => {
      const error = fileSizeValidator([file]);

      return {
        ...file,
        error,
        status: error ? 'error' : 'done',
      };
    });

    this.files.props.onChange({ fileList, ...params });
  }

  addForEdit(message: MessageModel): void {
    this._messageForEdit.change(message);
    this.text.change(message.text ?? '');
    this.files.change(message.files ?? []);
  }

  send(): void {
    const isError = this._validate();

    if (isError) {
      return;
    }

    if (this._threadModel.type === ThreadType.stream && !this.isEditMode) {
      this.generalStreamModal.modalState.open();
    } else {
      this._sendMessage();
    }
  }

  resetError() {
    this._messageError.change(null);
  }

  reset() {
    this.text.reset();
    this.files.reset();
    this._messageForEdit.reset();
    this.resetError();
  }

  private _validate(): boolean {
    const totalFilesSize = this.files.value.reduce((acc, file) => acc + (file.originFileObj?.size ?? 0), 0);
    const exceedsTotalFilesSize = totalFilesSize > TOTAL_FILES_SIZE;

    if (exceedsTotalFilesSize) {
      this._messageError.change(MessageInputError.exceedsTotalFilesSize);
    }

    return Boolean(this._messageError.value);
  }

  private async _sendMessage(threadId?: Nullable<number>): Promise<BaseResponse> {
    let response: BaseResponse;

    if (this.isEditMode) {
      response = await this._editMessage();
    } else {
      response = await this._createMessage(threadId);
    }

    return response;
  }

  private _toCreateJson(): Nullable<CreateMessagePayload> {
    const text = this.text.value.trim();
    const files = this.files.value.reduce<Array<File>>((acc, file) => {
      if (file.originFileObj) {
        acc.push(file.originFileObj);
      }

      return acc;
    }, []);

    if (!text && !files.length) {
      return null;
    }

    return {
      ...(text ? { text } : {}),
      ...(files ? { files } : {}),
    };
  }

  private async _createMessage(threadId?: Nullable<number>): Promise<BaseResponse> {
    if (this.sendingStage.isLoading) {
      return { isError: true };
    }

    const payload = this._toCreateJson();

    if (!payload) {
      return { isError: true };
    }

    this.sendingStage.loading();

    const response = await this._messageRequest.call({
      url: apiUrls.chats.messageCreate(threadId ?? this._threadModel.id),
      method: 'POST',
      data: payload,
    });

    if (response.isError) {
      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.sendMessageError', T_OPTIONS),
      });

      this.sendingStage.error();

      return { isError: true };
    }

    this.reset();
    this.sendingStage.success();

    return {
      isError: false,
    };
  }

  private _toEditJson(): EditMessagePayload {
    const text = this.text.value.trim();
    const oldFiles: Array<number> = [];
    const files = this.files.value.reduce<Array<File>>((acc, file) => {
      if (file.originFileObj) {
        acc.push(file.originFileObj);
      } else {
        stringIsNumber(file.uid) && oldFiles.push(Number(file.uid));
      }

      return acc;
    }, []);

    return {
      old_files: oldFiles,
      ...(text ? { text } : {}),
      ...(files ? { files } : {}),
    };
  }

  private async _editMessage(): Promise<BaseResponse> {
    const messageModel = this._messageForEdit.value;

    if (!messageModel || this.sendingStage.isLoading) {
      return { isError: true };
    }

    const payload = this._toEditJson();

    this.sendingStage.loading();

    const response = await this._messageRequest.call({
      url: apiUrls.chats.message({ threadId: messageModel.threadInfo.id, messageId: messageModel.id }),
      method: 'PUT',
      data: payload,
    });

    if (response.isError) {
      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.editMessageError', T_OPTIONS),
      });

      this.sendingStage.error();

      return { isError: true };
    }

    this._rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.editMessageSuccess', T_OPTIONS),
    });

    this.reset();
    this.sendingStage.success();

    return {
      isError: false,
    };
  }
}
