import { ListModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { IProductDictionary, IProductPrice, ProductDictionaryServer, ProductWeightType } from '../types';

import { UnitDictionaryModel } from './UnitDictionaryModel';

export class ProductDictionaryModel implements IProductDictionary {
  readonly value: number;
  readonly label: string;
  readonly name: string;
  readonly type: string;
  readonly spotPrice: Nullable<IProductPrice>;
  readonly londonPrice: Nullable<IProductPrice>;
  readonly unitList: IProductDictionary['unitList'];
  readonly shapeList: IProductDictionary['shapeList'];

  constructor({ value, label, name, type, spotPrice, londonPrice, unitList, shapeList }: IProductDictionary) {
    this.value = value;
    this.label = label;
    this.name = name;
    this.type = type;
    this.spotPrice = spotPrice;
    this.londonPrice = londonPrice;
    this.unitList = unitList;
    this.shapeList = shapeList;
  }

  static fromJson({ price, shapes, units, ...server }: ProductDictionaryServer): ProductDictionaryModel {
    const unitList = new ListModel<UnitDictionaryModel<number, ProductWeightType>, number>();

    unitList.fillByRawData(units, (raw) => ({
      entity: UnitDictionaryModel.fromJson(raw),
      key: raw.id,
    }));

    const shapeList = new ListModel<UnitDictionaryModel, number>();

    shapeList.fillByRawData(shapes, (raw) => ({
      entity: UnitDictionaryModel.fromJson(raw),
      key: raw.id,
    }));

    const { spot, london } = price;

    const spotPrice = spot && {
      date: new Date(spot.date),
      current: spot.current,
      previous: spot.previous,
      currency: UnitDictionaryModel.fromJson(spot.currency),
    };

    const londonPrice = london && {
      date: new Date(london.date),
      current: london.current,
      previous: london.previous,
      currency: UnitDictionaryModel.fromJson(london.currency),
    };

    return new ProductDictionaryModel({
      value: server.id,
      label: server.name,
      name: server.name,
      type: server.slug,
      spotPrice,
      londonPrice,
      unitList,
      shapeList,
    });
  }
}
