import * as React from 'react';

import { AwbStatus, StageModel, StepComponentProps } from '@/entities/stage';
import { StepDescription } from '@/shared/ui';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormUpload } from '@/shared/ui/form/FormUpload';

import { WaitingAwbOrFlightNumberFieldsModel } from '../../model/fieldsModel/WaitingAwbOrFlightNumberFieldsModel';

import s from './WaitingAwbOrFlightNumberStep.module.scss';

type Props<StageModel> = StepComponentProps<StageModel>;

const WaitingAwbOrFlightNumberStep = <Model extends StageModel>({ model, fieldKey, description }: Props<Model>) => {
  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof WaitingAwbOrFlightNumberFieldsModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {fields.awbStatus === AwbStatus.approveFile && <FormUpload model={fields.file} disabled={true} />}
      {fields.awbStatus === AwbStatus.approveNumber && (
        <FormInput model={fields.flightNumber} disabled={true} className={s.inputText} />
      )}
    </StepDescription>
  );
};

export default WaitingAwbOrFlightNumberStep;
