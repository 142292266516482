import { BaseResponse } from '@kts-front/types';
import { action, makeObservable, observable, reaction } from 'mobx';

import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore } from '@/shared/model';

export type MessageToReadItem = {
  id: string;
  threadId: number;
  createdDate: Date;
};

/*
 * Данный сервис собирает все сообщения, которые необходимо прочитать и читает только последнее сообщение из списка для оптимизации кол-ва запросов
 * */
export class ReadingService extends LocalStore {
  /*
   * Структура представляем собой Map-у, где ключом является id thread-а
   * */
  private readonly _messagesListToRead: Map<number, MessageToReadItem[]> = new Map();
  private readonly _readingStage: LoadingStageModel = new LoadingStageModel();

  constructor() {
    super();

    makeObservable<ReadingService, '_messagesListToRead' | '_handleMessagesToRead'>(this, {
      _messagesListToRead: observable,

      _handleMessagesToRead: action,
    });

    this.addReactions([
      reaction(() => this._messagesListToRead.entries(), this._handleMessagesToRead),
      reaction(() => this._readingStage.value, this._handleMessagesToRead),
    ]);
  }

  /**
   * Метод, который добавляет в массив на чтение сообщение thread-а
   * @param data
   */
  readMessage(data: MessageToReadItem): void {
    const arr = this._messagesListToRead.get(data.threadId);

    if (arr) {
      arr.push(data);
    } else {
      this._messagesListToRead.set(data.threadId, [data]);
    }
  }

  /**
   * Обрабатываем сообщения на чтение
   * @returns {Promise<{isError: true} | {isError: false}>}
   * @private
   */
  private readonly _handleMessagesToRead = async (): Promise<BaseResponse> => {
    if (this._messagesListToRead.size === 0) {
      return {
        isError: false,
      };
    }

    if (this._readingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this._readingStage.loading();

    const arrayFromMap = Array.from(this._messagesListToRead, ([threadId, messages]) => ({ threadId, messages }));

    /*
     * Параллельно отправляем по одному запросу на thread на чтение самого последнего сообщения этого thread-а
     * */
    const responses = await Promise.all<BaseResponse>(
      arrayFromMap.map(({ threadId, messages }) => {
        if (!messages.length) {
          return Promise.resolve({
            isError: false,
          });
        }

        /*
         * Ищем самое последнее сообщение thread-а, прочитав его, прочитаются все остальные
         * */
        messages.sort((message1, message2) => {
          return message1.createdDate.getTime() - message2.createdDate.getTime();
        });

        const lastMessage = messages[messages.length - 1];

        const request = apiStore.createRequest({
          method: 'POST',
          url: apiUrls.chats.messageRead({
            threadId,
            messageId: lastMessage.id,
          }),
        });

        this._messagesListToRead.delete(threadId);

        return request.call();
      }),
    );

    if (responses.some((response) => response.isError)) {
      this._readingStage.error();

      return {
        isError: true,
      };
    }

    this._readingStage.success();

    return {
      isError: false,
    };
  };
}
