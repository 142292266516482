import { computed, makeObservable } from 'mobx';

import {
  DocumentCheckStatus,
  DocumentServer,
  DocumentsServer,
  DocumentsWithStatusServer,
  DocumentWithStatusServer,
  FileModel,
} from '@/entities/file';
import { AwbStatus } from '@/entities/stage';
import { InputModel } from '@/shared/model/form/InputModel';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';

import { WaitingFieldsModelParams } from '../WaitingFieldsModel/WaitingFieldsModel';
import { WaitingFieldsWithFileModel } from '../WaitingFieldsWithFileModel';
import { WaitingFieldsWithFileModelParams } from '../WaitingFieldsWithFileModel/WaitingFieldsWithFileModel';

export type WaitingAwbOrFlightNumberFieldsModelParams<Step> = WaitingFieldsWithFileModelParams<Step> & {
  flightNumber: InputModel<string>;
};

export class WaitingAwbOrFlightNumberFieldsModel<Step> extends WaitingFieldsWithFileModel<Step> {
  readonly flightNumber: InputModel<string>;

  constructor({ flightNumber, ...params }: WaitingAwbOrFlightNumberFieldsModelParams<Step>) {
    super(params);

    this.flightNumber = flightNumber;

    makeObservable(this, {
      awbStatus: computed,
    });
  }

  get awbStatus(): AwbStatus {
    if (this.file.value.length > 0) {
      return AwbStatus.approveFile;
    }

    if (this.flightNumber.value.trim().length > 0) {
      return AwbStatus.approveNumber;
    }

    return AwbStatus.wait;
  }

  static fromJson<Step>({
    data,
    ...params
  }: WaitingFieldsModelParams<Step> & {
    data: Nullable<
      | DocumentServer
      | DocumentWithStatusServer
      | DocumentsServer
      | DocumentsWithStatusServer
      | { flight_number: string; status: DocumentCheckStatus; error_message: Nullable<string> }
    >;
  }) {
    const isDataDocument = !!data && 'document' in data;
    const isDataFlightNumber = !!data && 'flight_number' in data;

    return new WaitingAwbOrFlightNumberFieldsModel({
      file: new UploadFileModel({
        initialValue: FileModel.fileListFromJson(isDataDocument ? data?.document : null),
        maxCount: 1,
      }),
      flightNumber: new InputModel({
        initialValue: isDataFlightNumber ? data.flight_number : '',
        required: true,
      }),
      ...params,
    });
  }
}
