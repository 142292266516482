import { DocumentFileType, FileGenerationServer, FileServer } from '@/entities/file';
import { Nullable } from '@/shared/types/values';

/**
 * Этап сделки
 * @field creation - Создание сделки
 * @field confirmation - Подтверждение поставки
 * @field payment - Оплата
 * @field shipment - Отгрузка
 * @field transportation - Перевозка
 * @field finalization - Завершение сделки
 * @field finished - Завершен
 */
export enum StageType {
  creation = 'creation',
  confirmation = 'confirmation',
  payment = 'payment',
  shipment = 'shipment',
  transportation = 'transportation',
  finalization = 'finalization',
  finished = 'finished',
}

export enum ShipmentType {
  direct = 'direct',
  transit = 'transit',
}

export enum AwbType {
  file = 'file',
  number = 'number',
}

export enum AwbStatus {
  wait = 'wait',
  approveFile = 'approve_file',
  approveNumber = 'approve_number',
}

type BaseStageDocsServer = Partial<{
  [DocumentFileType.supplierSpecification]: FileServer;
  [DocumentFileType.customerSpecification]: FileServer;
  [DocumentFileType.indirectTaxesPaymentCertificate]: FileServer;
  [DocumentFileType.authorizationLetter]: FileServer;
  [DocumentFileType.finalizationPhoto]: FileServer;
  [DocumentFileType.warrantyLoa]: FileServer;
  [DocumentFileType.arbitraryDocuments]: FileServer[];
}>;

export type BaseStageDataServer<Step, Steps> = BaseStageDocsServer & {
  current_stage: StageType;
  current_step: Nullable<Step>;
  steps: Steps;
  shipment_type: Nullable<ShipmentType>;
};

export type TradeCloseCustomerDocsServer = {
  [DocumentFileType.signedCustomerAcceptanceCertificate]: FileServer;
  [DocumentFileType.signedCustomerAdditionalAgreement]: FileServer;
};

export type TradeCloseSupplierDocsServer = {
  [DocumentFileType.supplierAcceptanceCertificate]: FileServer;
  [DocumentFileType.supplierAdditionalAgreement]: FileServer;
};

export type DestinationCountryDocsServer<File = FileGenerationServer> = {
  [DocumentFileType.customerAcceptanceCertificate]: File;
  [DocumentFileType.customerAdditionalAgreement]: File;
  [DocumentFileType.customerCommercialInvoice]: File;
  [DocumentFileType.customerSpecification]: File;
  [DocumentFileType.exportInvoice]: File;
};

export enum OptionFieldsAction {
  deleteSupplierSpec = 'delete_supplier_specification',
  setTransitLogisticCompany = 'set_transit_logistic_company',
  setDestinationLogisticCompany = 'set_destination_logistic_company',
  setTrackingLink = 'set_tracking_link',
  uploadFinalizationPhoto = 'upload_finalization_photo',
  uploadAuthorizationLetter = 'upload_authorization_letter',
  uploadIndirectTaxesPaymentCertificate = 'upload_indirect_taxes_payment_certificate',
  uploadWarrantyLoa = 'upload_warranty_loa',
}
