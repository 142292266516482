import { BidDifference, BidDifferenceType, BidFixingType, BidStatus, BidType } from '@/entities/bid';

export const bids = {
  en: {
    title: 'Bids',
    searchPlaceholder: 'Find bid',
    emptyList: {
      buy: 'There are currently no bids available for purchase',
      sell: 'There are currently no bids available for sale',
      my: "You don't have any created bids yet. When they appear, they will be displayed in this section",
      reply: 'You have not left any replies yet. All your responses will be displayed here.',
    },
    actions: {
      createBid: 'Create bid',
      editBid: 'Edit bid',
      openBid: 'Open bid',
      replyBid: 'Reply',
      approveReply: 'Approve',
      publishBid: 'Publish',
      deleteBid: 'Delete',
      goToTrade: 'Go to trade',
    },
    fixingType: {
      [BidFixingType.am]: 'Morning',
      [BidFixingType.pm]: 'Evening',
      [BidFixingType.spot]: 'Spot',
    },
    difference: {
      [`with_${BidDifference.premium}`]: 'with a premium',
      [`with_${BidDifference.discount}`]: 'with a discount',
    },
    differenceType: {
      [BidDifferenceType.price]: 'Sum',
      [BidDifferenceType.percent]: 'Percent',
    },
    bidType: {
      [BidType.buy]: 'Buying',
      [BidType.sell]: 'Selling',
    },
    status: {
      [BidStatus.new]: 'New',
      [BidStatus.review]: 'Under consideration',
      [BidStatus.published]: 'Published',
      [BidStatus.inProgress]: 'Progress',
      [BidStatus.approved]: 'Approved',
      [BidStatus.rejected]: 'Rejected',
      [BidStatus.cancelled]: 'Cancelled',
      [BidStatus.tradeCreated]: 'Trade created',
    },
    BidHandlingModal: {
      createTitle: 'Creating a bid',
      editTitle: 'Editing a bid',
      bidType: 'Trade type',
      buyer: 'Buyer',
      seller: 'Seller',
      metal: 'Metal',
      shape: 'Product shape',
      fixingType: 'Fixing type',
      weight: 'Chemical weight',
      ligatureWeight: 'Ligature weight',
      weightPlaceholder: 'Weight',
      deliveryBasis: 'Delivery basis',
      currency: 'Trade currency',
      differenceType: 'Type of discount/premium',
      differenceSum: 'Sum of discount/premium',
      differencePercent: 'Percent of discount/premium',
      differenceDescription: 'You can enter either a positive value (premium) or a negative value (discount)',
      fixingDate: 'Fixing date',
      currentPrice: 'Exchange price',
      resultMessage: 'You create a bid with the "{{fixingType}}" fixing type {{difference}} of {{differenceValue}}',
    },
    BidReplyModal: {
      title: 'Reply to the bid',
      reply: 'Reply',
      alertText: 'Once the manager approves your reply, we will get back to you!',
    },
    BidInfo: {
      title: 'Bid information',
      fields: {
        type: 'Bid type',
        status: 'Bid status',
        metal: 'Metal',
        shape: 'Product shape',
        currency: 'Trade currency',
        fixingType: 'Fixing type',
        fixingDate: 'Fixing date',
        fixingPrice: 'Fixing price',
        weightWithUnit: 'Chemical weight',
        ligatureWeightWithUnit: 'Ligature weight',
        londonPrice: 'London Market Price',
        sellerName: 'Seller',
        buyerName: 'Buyer',
        buyerCountry: 'Buyer country',
        deliveryBasis: 'Delivery basis',
        difference: 'Discount/premium',
        managerDeliveryBasis: 'Recommended delivery basis',
        managerDifference: 'Recommended discount/premium',
        respondentDeliveryBasis: 'Delivery basis (on reply)',
        respondentDifference: 'Discount/premium (on reply)',
      },
    },
    BidDiscountEditing: {
      title: 'Enter discount',
      deliveryBasis: 'Recommended delivery basis',
      differenceType: 'Recommended discount/premium type',
      differenceSum: 'Recommended discount/premium sum',
      differencePercent: 'Recommended discount/premium percentage',
    },
    BidReplyList: {
      title: 'Select a customer',
      columns: {
        customer: 'Customer',
        country: 'Country',
        difference: 'Discount/premium',
        deliveryBasis: 'Delivery basis',
      },
    },
    BidDeleteModal: {
      title: 'Confirm deletion of the bid',
    },
    messages: {
      createBidSuccess: 'Bid created successfully',
      createBidError: 'Failed to create bid',
      editBidSuccess: 'Bid updated successfully',
      editBidError: 'Failed to update bid',
      replyBidSuccess: 'Reply sent successfully',
      replyBidError: 'Failed to reply to the bid',
      publishBidSuccess: 'Bid published successfully',
      publishBidError: 'Failed to publish bid',
      approveReplySuccess: 'Reply approved successfully',
      approveReplyError: 'Failed to approve reply',
      createTradeSuccess: 'Trade created successfully',
      createTradeError: 'Failed to create trade',
      deleteBidSuccess: 'Bid deleted successfully',
      deleteBidError: 'Failed to delete bid',
    },
    menu: {
      buy: 'Buy',
      sell: 'Sell',
      my: 'My bids',
      reply: 'My responses',
    },
    columns: {
      type: 'Type bids',
      metal: 'Metal',
      shape: 'Commodity form',
      status: 'Status',
      companyName: 'Client name',
      weight: 'Weight',
      chemicalWeight: 'Chemical',
      ligatureWeight: 'Ligature',
      currency: 'Trade currency',
      difference: 'Discount/premium',
      deliveryBasis: 'Delivery basis',
      managerDifference: 'Recommended discount/premium',
      managerDeliveryBasis: 'Recommended delivery basis',
      fixingType: 'Fixing type',
      fixingPrice: 'Fixing price',
      ouncePrice: 'Price per ounce',
      gramPrice: 'Price per gram',
      totalPrice: 'Total cost',
      totalOuncePrice: 'Without discount/premium',
      totalOuncePriceWithDiff: 'With discount/premium',
      londonPrice: 'London Market Price',
      reply: 'Reply',
    },
    tooltip: {
      tooltipWeight: 'Please note! Weight changed from {{ prevWeight }} to {{ weight }}.',
    },
    replyCount: {
      reply_one: '{{ count }} reply',
      reply_other: '{{ count }} replies',
    },
  },
  ru: {
    title: 'Заявки',
    searchPlaceholder: 'Найти заявку',
    emptyList: {
      buy: 'На данный момент нет доступных заявок на покупку',
      sell: 'На данный момент нет доступных заявок на продажу',
      my: 'У вас пока нет созданных заявок. Когда они появятся, они будут отображены в этом разделе',
      reply: 'Вы еще не оставили откликов. Здесь будут отображаться все ваши отклики',
    },
    actions: {
      createBid: 'Создать заявку',
      editBid: 'Редактировать заявку',
      openBid: 'Открыть заявку',
      replyBid: 'Откликнуться',
      approveReply: 'Утвердить',
      publishBid: 'Опубликовать',
      deleteBid: 'Удалить',
      goToTrade: 'Перейти к сделке',
    },
    fixingType: {
      [BidFixingType.am]: 'Утренняя',
      [BidFixingType.pm]: 'Вечерняя',
      [BidFixingType.spot]: 'Спот',
    },
    difference: {
      [`with_${BidDifference.premium}`]: 'с премией',
      [`with_${BidDifference.discount}`]: 'с дисконтом',
    },
    differenceType: {
      [BidDifferenceType.price]: 'Сумма',
      [BidDifferenceType.percent]: 'Процент',
    },
    bidType: {
      [BidType.buy]: 'Покупка',
      [BidType.sell]: 'Продажа',
    },
    status: {
      [BidStatus.new]: 'Новое',
      [BidStatus.review]: 'На рассмотрении',
      [BidStatus.published]: 'Опубликован',
      [BidStatus.inProgress]: 'В работе',
      [BidStatus.approved]: 'Утвержден',
      [BidStatus.rejected]: 'Отклонен',
      [BidStatus.cancelled]: 'Отменен',
      [BidStatus.tradeCreated]: 'Сделка создана',
    },
    BidHandlingModal: {
      createTitle: 'Создание заявки',
      editTitle: 'Редактирование заявки',
      bidType: 'Тип заявки',
      buyer: 'Покупатель',
      seller: 'Продавец',
      metal: 'Металл',
      shape: 'Товарная форма',
      fixingType: 'Тип фиксации',
      weight: 'Вес химический',
      ligatureWeight: 'Вес лигатурный',
      deliveryBasis: 'Базис поставки',
      currency: 'Валюта сделки',
      differenceType: 'Вид дисконта/премии',
      differenceSum: 'Сумма дисконта/премии',
      differencePercent: 'Процент дисконта/премии',
      differenceDescription:
        'Вы можете ввести как положительное значение (премия), так и отрицательное значение (дисконт)',
      fixingDate: 'Дата фиксинга',
      currentPrice: 'Биржевая цена',
      resultMessage: 'Вы создаете сделку с типом фиксации "{{fixingType}}" {{difference}} в {{differenceValue}}',
    },
    BidReplyModal: {
      title: 'Отклик на заявку',
      alertText: 'После того как менеджер одобрит ваш отклик, мы вернемся к вам!',
    },
    BidInfo: {
      title: 'Информация о заявке',
      fields: {
        type: 'Тип заявки',
        status: 'Статус заявки',
        metal: 'Металл',
        shape: 'Товарная форма',
        currency: 'Валюта сделки',
        fixingType: 'Тип фиксации',
        fixingDate: 'Дата фиксинга',
        fixingPrice: 'Цена фиксации',
        weightWithUnit: 'Вес химический',
        ligatureWeightWithUnit: 'Вес лигатурный',
        londonPrice: 'Цена лондонского рынка',
        sellerName: 'Продавец',
        buyerName: 'Покупатель',
        buyerCountry: 'Страна покупателя',
        deliveryBasis: 'Базис поставки',
        difference: 'Дисконт/премия',
        managerDeliveryBasis: 'Рекомендуемый базис поставки',
        managerDifference: 'Рекомендуемый дисконт/премия',
        respondentDeliveryBasis: 'Базис поставки (при отклике)',
        respondentDifference: 'Дисконт/премия (при отклике)',
      },
    },
    BidDiscountEditing: {
      title: 'Введите дисконт',
      deliveryBasis: 'Рекомендуемый базис поставки',
      differenceType: 'Вид рекомендуемого дисконта/премии',
      differenceSum: 'Сумма рекомендуемого дисконта/премии',
      differencePercent: 'Процент рекомендуемого дисконта/премии',
    },
    BidReplyList: {
      title: 'Выберите покупателя',
      columns: {
        customer: 'Покупатель',
        country: 'Страна',
        difference: 'Дисконт/премия',
        deliveryBasis: 'Базис поставки',
      },
    },
    BidDeleteModal: {
      title: 'Подтвердите удаление заявки',
    },
    messages: {
      createBidSuccess: 'Заявка успешно создана',
      createBidError: 'Не удалось создать заявку',
      editBidSuccess: 'Заявка успешно отредактирована',
      editBidError: 'Не удалось отредактировать заявку',
      replyBidSuccess: 'Отклик успешно отправлен',
      replyBidError: 'Не удалось откликнуться на заявку',
      publishBidSuccess: 'Заявка успешно опубликована',
      publishBidError: 'Не удалось опубликовать заявку',
      approveReplySuccess: 'Отклик успешно одобрен',
      approveReplyError: 'Не удалось одобрить отклик',
      createTradeSuccess: 'Сделка успешно создана',
      createTradeError: 'Не удалось создать сделку',
      deleteBidSuccess: 'Заявка успешно удалена',
      deleteBidError: 'Не удалось удалить заявку',
    },
    menu: {
      buy: 'Купить',
      sell: 'Продать',
      my: 'Мои заявки',
      reply: 'Мои отклики',
    },
    columns: {
      type: 'Тип заявки',
      metal: 'Металл',
      shape: 'Товарная форма',
      status: 'Статус',
      companyName: 'Имя клиента',
      weight: 'Вес',
      chemicalWeight: 'Химический',
      ligatureWeight: 'Лигатурный',
      currency: 'Валюта сделки',
      difference: 'Дисконт/премия',
      deliveryBasis: 'Базис поставки',
      managerDifference: 'Рекомендуемый дисконт/премия',
      managerDeliveryBasis: 'Рекомендуемый базис поставки',
      fixingType: 'Тип фиксации',
      fixingPrice: 'Цена фиксации',
      ouncePrice: 'Цена за унцию',
      gramPrice: 'Цена за грамм',
      totalPrice: 'Общая стоимость',
      totalOuncePrice: 'Без дисконта/премии',
      totalOuncePriceWithDiff: 'С дисконтом/премией',
      londonPrice: 'Цена лондонского рынка',
      reply: 'Отклик',
    },
    tooltip: {
      tooltipWeight: 'Обратите внимание! Вес изменился с {{ prevWeight }} на {{ weight }}',
    },
    replyCount: {
      reply_one: '{{ count }} отклик',
      reply_few: '{{ count }} отклика',
      reply_many: '{{ count }} откликов',
    },
  },
};
