import { DocumentFileType } from '@/entities/file';
import { ShipmentType } from '@/entities/stage';

export const shipmentStage = {
  en: {
    manager: {
      uploadSpec: {
        title: 'Upload supplier specification',
        description: 'Upload specification and click “Approve“',
      },
      specVerifying: {
        title: 'Verify specification',
        description:
          'Download the specification document from the previous step. Check that all information is correct and click the “All correct” button to proceed further.',
      },
      shipmentType: {
        title: 'Select type of transportation',
        [ShipmentType.direct]: 'Direct',
        [ShipmentType.transit]: 'Transit',
      },
      transitCountry: {
        title: 'Select transit country',
        placeholder: 'Select country',
      },
      transitCountryAwb: {
        title: 'Upload the AWB of the transit country or enter the flight number',
      },
      transitCountryAwbVerifying: {
        title: 'Verify AWB for transit country',
        description:
          'Download the transit country AWB document from the previous step. Check that all information is correct and click the “All correct” button to proceed further.',
      },
      destinationCountryDocs: {
        title: 'Upload documents for destination country',
      },
      destinationCountryAwb: {
        title: "Upload the destination country's AWB or enter the flight number",
      },
      cargoShipment: {
        title: 'Shipping cargo',
        description: 'When the shipment is shipped, confirm shipmen',
      },
    },
    supplier: {
      uploadSpec: {
        title: 'Upload supplier specification',
        description: 'Upload specification and click “Approve”',
      },
      specVerifying: {
        title: 'Waiting for specification confirmation',
        waitDescription: 'Wait for the manager to confirm the specification.',
        approveDescription: 'The manager marked the specification document as correct.',
      },
      transitCountry: {
        title: 'Wait for the manager to complete the deal',
        description: 'Wait for the manager to complete the deal',
      },
      transitCountryAwb: {
        title: 'Upload the AWB of the transit country or enter the flight number',
      },
      transitCountryAwbVerifying: {
        title: 'Waiting for AWB check',
        waitDescription: 'Wait for the manager to check the AWB',
        approveDescription: 'The manager marked the AWB document as correct.',
      },
      cargoShipped: 'The manager noted that the cargo was shipped',
    },
    customer: {
      shipmentType: {
        title: 'Wait for shipment',
      },
      destinationCountryDocs: {
        title: 'Wait for the deal documents to load',
        waitDescription: 'Once the documents are uploaded they will appear here',
        approveDescription: 'Documents uploaded',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Invoice',
          [DocumentFileType.customerSpecification]: 'Specification',
          [DocumentFileType.customerAcceptanceCertificate]: 'Transfer supplementary agreement signed on one side',
          [DocumentFileType.customerAdditionalAgreement]: 'Supplementary agreement',
        },
      },
      destinationCountryAwb: {
        title: 'Wait for AWB or flight number to load',
      },
      transfer: {
        title: 'Cargo shipped',
      },
    },
  },
  ru: {
    manager: {
      uploadSpec: {
        title: 'Загрузите спецификацию поставщика',
        description: 'Загрузите спецификацию и нажмите “Подтвердить”',
      },
      specVerifying: {
        title: 'Проверьте спецификацию',
        description:
          'Cкачайте документ спецификации из предыдущего шага. Проверьте, что вся информация верна и нажмите кнопку “Все правильно”, чтобы пройти дальше.',
      },
      shipmentType: {
        title: 'Выберите тип перелета',
        [ShipmentType.direct]: 'Прямой',
        [ShipmentType.transit]: 'Транзит',
      },
      transitCountry: {
        title: 'Выберите страну транзита',
        placeholder: 'Выберите страну',
      },
      transitCountryAwb: {
        title: 'Загрузите AWB страны транзита или введите номер рейса',
      },
      transitCountryAwbVerifying: {
        title: 'Проверьте AWB страны транзита',
        description:
          'Cкачайте документ AWB страны транзита из предыдущего шага. Проверьте, что вся информация верна и нажмите кнопку “Все правильно”, чтобы пройти дальше.',
      },
      destinationCountryDocs: {
        title: 'Загрузите документы для страны назначения',
      },
      destinationCountryAwb: {
        title: 'Загрузите AWB страны назначения или введите номер рейса',
      },
      cargoShipment: {
        title: 'Отгрузка груза',
        description: 'Когда груз будет отправлен, подтвердите отгрузку',
      },
    },
    supplier: {
      uploadSpec: {
        title: 'Загрузите спецификацию поставщика',
        description: 'Загрузите спецификацию и нажмите “Подтвердить”',
      },
      specVerifying: {
        title: 'Ожидание подтверждения спецификации',
        waitDescription: 'Дождитесь, пока менеджер подтвердит спецификацию.',
        approveDescription: 'Менеджер отметил документ спецификации правильным.',
      },
      transitCountry: {
        title: 'Ожидайте заполнение сделки менеджером',
        description: 'Дождитесь, пока менеджер дозаполнит сделку',
      },
      transitCountryAwb: {
        title: 'Загрузите AWB страны транзита или введите номер рейса',
      },
      transitCountryAwbVerifying: {
        title: 'Ожидание проверки AWB',
        waitDescription: 'Дождитесь, пока менеджер проверит AWB',
        approveDescription: 'Менеджер отметил документ AWB правильным.',
      },
      cargoShipped: 'Менеджер отметил, что груз отгружен',
    },
    customer: {
      shipmentType: {
        title: 'Ожидайте отгрузку',
      },
      destinationCountryDocs: {
        title: 'Ожидайте загрузку документов по сделке',
        waitDescription: 'Когда документы будут загружены, они появятся здесь',
        approveDescription: 'Документы загружены',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Инвойс',
          [DocumentFileType.customerSpecification]: 'Спецификация',
          [DocumentFileType.customerAcceptanceCertificate]: 'Акт-приема передачи, подписанный с одной стороны',
          [DocumentFileType.customerAdditionalAgreement]: 'Дополнительное соглашение',
        },
      },
      destinationCountryAwb: {
        title: 'Ожидайте загрузку AWB или номера рейса',
      },
      transfer: {
        title: 'Груз отгружен',
      },
    },
  },
  zh: {
    manager: {
      uploadSpec: {
        title: '上传供应商的规格',
        description: '上传供应商的规格并点击‘确认',
      },
      specVerifying: {
        title: '请检查规格书',
        description: '下载上一步的规格书。请检查所有信息是否正确，然后单击“一切正确”按钮以继续。',
      },
      shipmentType: {
        title: '选择飞行类型',
        [ShipmentType.direct]: '直飞',
        [ShipmentType.transit]: '转机',
      },
      transitCountry: {
        title: '请选择过境国',
        placeholder: '请选择国家',
      },
      transitCountryAwb: {
        title: '下载过境国家的AWB或输入航班号',
      },
      transitCountryAwbVerifying: {
        title: '检查过境国的AWB',
        description: '下载上一步中的过境国AWB文件。请检查所有信息是否正确，然后单击“一切正确”按钮以继续。',
      },
      destinationCountryDocs: {
        title: '上传目的国文件',
      },
      destinationCountryAwb: {
        title: '下载目的地国家的AWB或输入航班号',
      },
      cargoShipment: {
        title: '装运',
        description: '货物发运后，确认发货',
      },
    },
    supplier: {
      uploadSpec: {
        title: '上传供应商的规格',
        description: '上传供应商的规格并点击‘确认',
      },
      specVerifying: {
        title: '等待规格书确认',
        waitDescription: '请等待经理确认规格',
        approveDescription: '经理将规格书标记为正确',
      },
      transitCountry: {
        title: '等待管理完成交易',
        description: '等待经理填完交易',
      },
      transitCountryAwb: {
        title: '下载过境国家的AWB或输入航班号',
      },
      transitCountryAwbVerifying: {
        title: '等待确认AWB',
        waitDescription: '等待经理检查AWB',
        approveDescription: '经理将AWB标记为正确',
      },
      cargoShipped: '经理将货物标记为已装运 ',
    },
    customer: {
      shipmentType: {
        title: '请等待装运',
      },
      destinationCountryDocs: {
        title: '等待交易文件加载',
        waitDescription: '文件上传后，它们将出现在此处',
        approveDescription: '文件已上传',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: '发票',
          [DocumentFileType.customerSpecification]: '规格',
          [DocumentFileType.customerAcceptanceCertificate]: '一方签署的验收证明书 ',
          [DocumentFileType.customerAdditionalAgreement]: '附加协议',
        },
      },
      destinationCountryAwb: {
        title: '期望下载AWB或航班号',
      },
      transfer: {
        title: '货物已发运',
      },
    },
  },
};
