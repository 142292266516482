import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { DictionaryField } from '@/entities/dictionary';
import { useKeyDown } from '@/shared/hooks/useKeyDown';
import { useTypedTranslation } from '@/shared/hooks/useTranslation';
import { CustomModal, Loader } from '@/shared/ui';

import { CreateTradeStore } from '../../model';
import { CreateTradeForm } from '../CreateTradeForm';

import s from './CreateTradeModal.module.scss';

type Props = {
  className?: string;
  store: CreateTradeStore;
};

const CreateTradeModal: React.FC<Props> = ({ className, store }) => {
  const { dictionaries, modalState, closeModal } = store;

  const { t } = useTypedTranslation('trade');

  useKeyDown({
    func: store.submitModal,
    checks: [modalState.isOpen],
  });

  React.useEffect(() => {
    if (modalState.isOpen) {
      dictionaries.loadDictionaries([
        DictionaryField.legalCompanies,
        DictionaryField.productTypes,
        DictionaryField.currencies,
        DictionaryField.suppliers,
        DictionaryField.customers,
      ]);
    }
  }, [modalState.isOpen]);

  return (
    <CustomModal
      className={cn(s.modal, className)}
      title={t('createTrade.title')}
      open={modalState.isOpen}
      onCancel={closeModal}
      cancelButtonProps={{ disabled: store.requestStage.isLoading }}
      okText={t('buttons.create', { ns: 'shared' })}
      onOk={store.submitModal}
      okButtonProps={{ disabled: store.isError, loading: store.requestStage.isLoading }}
      width={740}
      destroyOnClose
    >
      {dictionaries.loadingStage.isLoading ? (
        <Loader className={s.modal_loader} />
      ) : (
        <CreateTradeForm className={s.modal_form} model={store} />
      )}
    </CustomModal>
  );
};

export default observer(CreateTradeModal);
